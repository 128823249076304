import React, { useEffect, useMemo, useState } from 'react';
import { BootstrappedForm } from 'idd-react-telefonie';
import { THEME } from '@eg/elements/Provider';
import { CONFIG_BACKEND_URL } from '../../config';
import { ConsultancyChannelDirection } from '../../store/entities';
import { ConsultancyProtocolProps } from './ConsultancyProtocol.types';
import { Loading } from '../../components';

export const ConsultancyProtocol: React.FC<ConsultancyProtocolProps> = ({
  agentName,
  channelDirection,
  hasUpdateBaseRequestFailed,
  hasUpdateBaseRequestSucceeded,
  setConsultancyProtocolId,
}) => {
  const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);

  const contextOptions = useMemo(() => {
    const options = new Map();
    options.set('eingangskanal', channelDirection || ConsultancyChannelDirection.INBOUND);

    if (!!agentName) {
      options.set('gespraechspartner', agentName);
    }

    return options;
  }, [agentName, channelDirection]);

  useEffect(() => {
    if (isRequestInProgress) {
      if (hasUpdateBaseRequestFailed) {
        setIsRequestInProgress(false);
        setResetForm(true);
      }

      if (hasUpdateBaseRequestSucceeded) {
        setIsRequestInProgress(false);
      }
    }
  }, [hasUpdateBaseRequestFailed, hasUpdateBaseRequestSucceeded, isRequestInProgress]);

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [agentName, channelDirection, resetForm]);

  const onSubmitFormHandler = (consultancyId: string | null) => {
    if (!!consultancyId) {
      setConsultancyProtocolId(consultancyId);
      setIsRequestInProgress(true);
    }
  };

  return (
    <Loading loading={isRequestInProgress}>
      {!resetForm && (
        <BootstrappedForm
          submitForm={onSubmitFormHandler}
          configuration={{
            debugLog: false,
            debugFetch: false,
            useMock: false,
            allowDenial: false,
            theme: THEME.ergoone,
            submitButtonLabel: 'Beratungsprotokoll erstellen',
            url: `${CONFIG_BACKEND_URL}/api/consultancy-protocol`,
          }}
          context={{
            kontext: 'IDD_PHV2021_TOF',
            values: contextOptions,
          }}
        />
      )}
    </Loading>
  );
};
