export const DurationOfContractTooltip = (
  <>
    <p>
      <strong>1 Jahr:</strong> Der <strong>Vertrag verlängert</strong> sich nach Ablauf der
      Vertragsdauer <strong>stillschweigend</strong> von Jahr zu Jahr. Ausnahme: Dem anderen
      Vertragspartner ist spätestens 3 Monate vor dem jeweiligen Ablauf eine Kündigung zugegangen.
    </p>
    <p>
      <strong>3 Jahre:</strong> Entscheiden Sie sich für eine 3-jährige Vertragsdauer, erhalten Sie{' '}
      <strong>10 % Nachlass</strong> auf Ihren Nettobeitrag.
    </p>
  </>
);
