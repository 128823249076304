import { UnauthorizedModalState } from './UnauthorizedModal.types';
import {
  UnauthorizedModalActionTypes,
  UnauthorizedModalActions,
} from './UnauthorizedModal.actions';

const defaultState: Readonly<UnauthorizedModalState> = {
  isOpen: false,
};

export const unauthorizedModalReducer = (
  state: UnauthorizedModalState = defaultState,
  action: UnauthorizedModalActions
) => {
  switch (action.type) {
    case UnauthorizedModalActionTypes.OPEN_UNAUTHORIZED_MODAL:
      return {
        ...state,
        isOpen: true,
      };
    case UnauthorizedModalActionTypes.CLOSE_UNAUTHORIZED_MODAL:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};
