import React from 'react';
import { BundleLoader } from '@disorder/microfrontend-bundle-loader';
import { AddressFieldName, PersonFieldName } from '@disorder/personal-data-models';
import { CONFIG_PERSONAL_DATA_BACKEND_URL, CONFIG_PERSONAL_DATA_BUNDLE_URL } from '../../config';
import { PersonBlock } from './components/PersonBlock';
import { PersonManagementProps } from './PersonManagement.types';
import { usePersonalDataEvents } from './usePersonalDataEvents';
import { ValidatePersonButton } from '../../components';
import styles from './PersonManagement.module.scss';

export const PersonManagement: React.FC<PersonManagementProps> = ({
  businessId,
  insuranceStartDate,
  insuranceOwnerId,
  insuranceOwner,
  readOnly,
}) => {
  const customProps = {
    appId: String(new Date().getTime()),
    businessId,
    insuranceStartDate,
    personId: insuranceOwnerId,
    backendUrl: CONFIG_PERSONAL_DATA_BACKEND_URL,
    visibleFields: [
      PersonFieldName.SALUTATION,
      PersonFieldName.FIRST_NAME,
      PersonFieldName.LAST_NAME,
      PersonFieldName.BIRTH_DATE,
      PersonFieldName.PHONE_NUMBER_WITH_PREFIX,
      PersonFieldName.PHONE_INTERNATIONAL_PREFIX,
      PersonFieldName.PHONE_AREA_PREFIX,
      PersonFieldName.PHONE_NUMBER,
      PersonFieldName.PERSONAL_EMAIL,
      PersonFieldName.PERSON_ID,
      PersonFieldName.TITLE,
      AddressFieldName.ADDRESS,
      AddressFieldName.STREET_NAME,
      AddressFieldName.BUILDING_NUMBER,
      AddressFieldName.POSTCODE,
      AddressFieldName.CITY,
    ],
  };

  const { setMicrofrontRef, validatePersonEvent } = usePersonalDataEvents(customProps.appId);

  return (
    <>
      {insuranceOwnerId && (
        <div className={styles.root}>
          <div className={styles.container}>
            <div className={styles.list}>
              <PersonBlock person={insuranceOwner} />
            </div>
            <div className={styles.detailsForm}>
              <BundleLoader
                bundleUrl={CONFIG_PERSONAL_DATA_BUNDLE_URL}
                customProps={customProps}
                parcelDidMount={setMicrofrontRef}
              />
              <div className={styles.ControlsSection}>
                <ValidatePersonButton
                  onClick={validatePersonEvent}
                  validationStatus={insuranceOwner.pdsValidationStatus}
                  disabled={readOnly}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
