import Modal from '@eg/elements/Modal';
import React from 'react';
import styles from './ThankYouModal.module.scss';
import { ThankYouModalProps } from './ThankYouModal.types';

export const ThankYouModal: React.FC<ThankYouModalProps> = ({ isOpened }) => (
  <Modal className={styles.modalRoot} open={isOpened} viewport='fixed'>
    <div className={styles.wrapper}>
      <h1>Herzlichen Glückwunsch!</h1>
      <p>
        Der Antrag wurde erfolgreich übermittelt es sind keine Änderungen mehr möglich. Sie können
        diese Seite jetzt schließen.
      </p>

      <p>Vielen Dank</p>
    </div>
  </Modal>
);
