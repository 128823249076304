import { get } from 'lodash';
import { OfferBFF, OfferFeeBFF } from '../offer.types';
import { OfferFee, OfferFeesState } from './fees.types';

const mapOfferBFFToOfferFeesSet = (offer: OfferBFF): OfferFeesState['fees'] => {
  if (!offer.hasOwnProperty('fees') || offer.fees === null) {
    return {};
  }

  const fees = get<OfferBFF, 'fees', OfferFeeBFF[]>(offer, 'fees', []) as OfferFeeBFF[];
  return fees.reduce((acc: Record<OfferFee['variantId'], OfferFee>, fee) => {
    acc[fee.variantId] = fee as OfferFee;
    return acc;
  }, {});
};

export const offerFeesHelper = {
  mapOfferBFFToOfferFeesSet,
};
