import React, { useEffect } from 'react';
import CogwheelIcon from '@eg/elements/Icons/CogwheelIcon';
import logo from '../../assets/svgs/ergo_logo.svg';
import styles from './AppLoading.module.scss';

export const AppLoading: React.FC = () => {
  useEffect(() => {
    document.querySelector('body')?.classList.add(styles.noScroll);
    return () => {
      document.querySelector('body')?.classList.remove(styles.noScroll);
    };
  }, []);

  return (
    <div className={styles.root}>
      <img src={logo} className={styles.logo} alt='ergo-logo' />
      <div className={styles.content}>
        <CogwheelIcon className={styles.icon} />
        <div>Seite wird geladen</div>
      </div>
    </div>
  );
};
