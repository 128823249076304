import React from 'react';
import Button from '@eg/elements/Button';
import Modal from '@eg/elements/Modal';
import { RetryLastActionModalProps } from './RetryLastActionModal.types';
import styles from './RetryLastActionModal.module.scss';

export const RetryLastActionModal: React.FC<RetryLastActionModalProps> = ({
  close,
  isOpened,
  viewport = 'fixed',
  dismissable = true,
}) => {
  return (
    <Modal
      className={styles.root}
      open={isOpened}
      viewport={viewport}
      dismissible={dismissable}
      onDismiss={close}
      useEscKeyToDismiss={true}
    >
      <div className={styles.wrapper}>
        <h1>Etwas ist schief gelaufen</h1>
        <p>Ihre letzte Aktion konnte nicht ausgeführt werden. Bitte wiederholen Sie Ihre Aktion.</p>
        <Button className={styles.button} variant='primary' onClick={close}>
          Ok
        </Button>
      </div>
    </Modal>
  );
};
