import React, { useEffect } from 'react';
import endpointBuilderFactory from '../../services/endpoint.builder.service';
import routeBuilderFactory from '../../services/route.builder.service';
import headerBuilderFactory from '../../services/header.builder.service';
import { AppLoading } from '../../components';
import { CONFIG_BACKEND_API_VERSION, CONFIG_BACKEND_URL } from '../../config';
import { InitAppProps } from './InitApp.types';
import { RefreshUserToken } from '../RefreshUserToken';

export const InitApp: React.FC<InitAppProps> = ({
  getFullOffer,
  isAppInitialized,
  isInitializedWithSuccess,
  match,
}) => {
  const businessId = match.params.businessId;

  useEffect(() => {
    endpointBuilderFactory.setBackendUrl(CONFIG_BACKEND_URL);
    endpointBuilderFactory.setApiVersion(CONFIG_BACKEND_API_VERSION);
  }, []);

  useEffect(() => {
    endpointBuilderFactory.setBusinessId(businessId);
    routeBuilderFactory.setBusinessId(businessId);
    headerBuilderFactory.setBusinessId(businessId);
  }, [businessId]);

  useEffect(() => {
    if (!isAppInitialized) {
      getFullOffer();
    }
  }, [businessId, getFullOffer, isAppInitialized]);

  if (!isAppInitialized) {
    return <AppLoading />;
  } else if (isInitializedWithSuccess) {
    return <RefreshUserToken />;
  }

  return null;
};
