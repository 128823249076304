import { get } from 'lodash';
import { OfferBFF, OfferVariantBFF, OfferVariantFieldBFF } from '../offer.types';
import { OfferField, OfferFieldsIds, OfferFieldsState, OfferVariantFields } from './fields.types';

const mapVariantsFields = (offer: OfferBFF): OfferFieldsState['fieldsByVariantId'] => {
  return get<OfferBFF, 'variants', OfferVariantBFF[]>(offer, 'variants', []).reduce(
    (acc: OfferFieldsState['fieldsByVariantId'], variant: OfferVariantBFF) => {
      acc[variant.id] = {
        ids: get<OfferVariantBFF, 'fields', OfferVariantFieldBFF[]>(variant, 'fields', []).map(
          (field: OfferVariantFieldBFF) => field.id as OfferFieldsIds
        ),
        fieldsById: get<OfferVariantBFF, 'fields', OfferVariantFieldBFF[]>(
          variant,
          'fields',
          []
        ).reduce((acc: OfferVariantFields['fieldsById'], field) => {
          const { min, max, ...restFieldProps } = field;
          acc[field.id as OfferFieldsIds] = {
            ...restFieldProps,
            ...(min && { min: new Date(min) }),
            ...(max && { max: new Date(max) }),
          } as OfferField;
          return acc;
        }, {}),
      };

      return acc;
    },
    {}
  );
};

export const offerFieldsHelper = {
  mapVariantsFields,
};
