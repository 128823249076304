import { WithPackageInsuredPersonsIdsProps } from '../WithPackageInsuredPersonsIds';

export enum FamilyMembers {
  One = 1,
  Two = 2,
  Three = 3,
}

export const isFamilyMembersOption = (value: number): value is FamilyMembers => {
  return Object.values(FamilyMembers).includes(value);
};

export interface MonoparentalFamilyPersonStateProps {
  familyMembersIds: string[];
  hasUpdatePackagesRequestFailed: boolean;
}

export interface MonoparentalFamilyPersonProps
  extends MonoparentalFamilyPersonStateProps,
    WithPackageInsuredPersonsIdsProps {}
