import React from 'react';
import { StaticItemProps } from './StaticItem.types';
import styles from './StaticItem.module.scss';

export const StaticItem: React.FC<StaticItemProps> = ({ label, value }) => {
  return (
    <div className={styles.root}>
      {`${label}: `}
      <span className={styles.content}>{value ? <strong>{value}</strong> : '-'}</span>
    </div>
  );
};
