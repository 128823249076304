import { ActionsUnion, createAction } from '../../services';
import { ClosableAccordionTypes } from './Accordion.types';

export enum AccordionsActionsTypes {
  REGISTER_ACCORDION = '[entities][accordions] REGISTER_ACCORDION',
  UNREGISTER_ACCORDION = '[entities][accordions] UNREGISTER_ACCORDION',
  TOGGLE_ACCORDION = '[entities][accordions] TOGGLE_ACCORDION',
  TOGGLE_ALL_ACCORDIONS = '[entities][accordions] TOGGLE_ALL_ACCORDIONS',
  OPEN_ALL_ACCORDIONS = '[entities][accordions] OPEN_ALL_ACCORDIONS',
  CLOSE_ALL_ACCORDIONS = '[entities][accordions] CLOSE_ALL_ACCORDIONS',
}

const registerAccordion = (sectionKey: ClosableAccordionTypes) =>
  createAction(AccordionsActionsTypes.REGISTER_ACCORDION, { sectionKey });
const unregisterAccordion = (sectionKey: ClosableAccordionTypes) =>
  createAction(AccordionsActionsTypes.UNREGISTER_ACCORDION, { sectionKey });
const toggleAccordion = (sectionKey: ClosableAccordionTypes) =>
  createAction(AccordionsActionsTypes.TOGGLE_ACCORDION, { sectionKey });
const toggleAllAccordions = () => createAction(AccordionsActionsTypes.TOGGLE_ALL_ACCORDIONS);
const openAllAccordions = () => createAction(AccordionsActionsTypes.OPEN_ALL_ACCORDIONS);
const closeAllAccordions = () => createAction(AccordionsActionsTypes.CLOSE_ALL_ACCORDIONS);

const actions = {
  registerAccordion,
  unregisterAccordion,
  toggleAccordion,
  toggleAllAccordions,
  openAllAccordions,
  closeAllAccordions,
};

const asyncActions = {};

export const accordionActions = {
  ...actions,
  ...asyncActions,
};

export type AccordionActions = ActionsUnion<typeof actions, typeof asyncActions>;
