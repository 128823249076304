import React from 'react';
import ComparisonTable from '@eg/elements/ComparisonTable';
import { Row } from './components';
import styles from './OfferTable.module.scss';

export class OfferTable extends React.Component {
  static Row = Row;

  render() {
    return <ComparisonTable className={styles.root}>{this.props.children}</ComparisonTable>;
  }
}
