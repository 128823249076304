import { combineEpics } from 'redux-observable';
import {
  appEpics,
  offerAgreementsEpics,
  offerFieldsEpics,
  offerGeneralEpics,
  offerPackagesEpics,
  offerVariantsEpics,
  personsEpics,
} from './entities';
import {
  fatalErrorModalEpics,
  retryLastActionModalEpics,
  updateBirthdateErrorModalEpics,
  unauthorizedModalEpics,
} from '../features';
import { offerPaymentEpics } from './entities/offer/payment/payment.epics';
import { accordionsEpics } from '../features/Accordions';

export const rootEpic = combineEpics(
  appEpics,
  fatalErrorModalEpics,
  offerAgreementsEpics,
  offerFieldsEpics,
  offerGeneralEpics,
  offerPackagesEpics,
  offerPaymentEpics,
  offerVariantsEpics,
  personsEpics,
  retryLastActionModalEpics,
  updateBirthdateErrorModalEpics,
  accordionsEpics,
  unauthorizedModalEpics
);
