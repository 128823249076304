import { connect } from 'react-redux';
import { GeneralContract as GeneralContractComponent } from './GeneralContract';
import { GeneralContractStateProps } from './GeneralContract.types';
import { offerVariantsSelectors } from '../../store/entities';
import { RootState } from '../../store/store.types';

const mapStateToProps = (state: RootState): GeneralContractStateProps => ({
  selectedVariantId: offerVariantsSelectors.getSelectedId(state) ?? '',
});

export const GeneralContract = connect(mapStateToProps)(GeneralContractComponent);
