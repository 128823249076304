import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax, AjaxError } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { buildDefaultHeaders, buildEndpoint, ENDPOINTS } from '../../../../services';
import { RootActions, RootState } from '../../../store.types';
import { PersonsActionsTypes } from '../../persons';
import { offerGeneralActions, OfferGeneralActionsTypes } from './general.actions';

export const onUpdateBirthdateRequest: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferGeneralActionsTypes.UPDATE_BIRTHDATE_TRIGGER),
    mergeMap((action) => {
      return ajax({
        url: buildEndpoint(ENDPOINTS.updateBirthdate),
        method: 'PUT',
        body: action.payload,
        headers: buildDefaultHeaders(),
        withCredentials: true,
      }).pipe(
        map((data) => offerGeneralActions.updateBirthdate.success(data.response)),
        catchError((error: AjaxError) => of(offerGeneralActions.updateBirthdate.failure(error)))
      );
    })
  );

export const onUpdatePersonBirthdate: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(PersonsActionsTypes.UPDATE_INSURANCE_OWNER_BIRTHDATE),
    map((action) => offerGeneralActions.updateBirthdate.trigger({ birthdate: action.payload }))
  );

export const onUpdateBaseRequest: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferGeneralActionsTypes.UPDATE_BASE_TRIGGER),
    mergeMap((action) => {
      return ajax({
        url: buildEndpoint(ENDPOINTS.updateBaseOfferData),
        method: 'PUT',
        body: action.payload,
        headers: buildDefaultHeaders(),
        withCredentials: true,
      }).pipe(
        map((data) => offerGeneralActions.updateBaseField.success(data.response)),
        catchError((error: AjaxError) =>
          of(offerGeneralActions.updateBaseField.failure(error, action.payload))
        )
      );
    })
  );

export const onFinaliseOfferEpic: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferGeneralActionsTypes.FINALISE_OFFER_TRIGGER),
    mergeMap(() =>
      ajax({
        url: buildEndpoint(ENDPOINTS.finaliseOffer),
        method: 'GET',
        headers: buildDefaultHeaders(),
        withCredentials: true,
      }).pipe(
        map((data) => offerGeneralActions.finaliseOffer.success(data.response)),
        catchError((error: AjaxError) => of(offerGeneralActions.finaliseOffer.failure(error)))
      )
    )
  );

export const offerGeneralEpics = combineEpics(
  onUpdateBirthdateRequest,
  onUpdatePersonBirthdate,
  onUpdateBaseRequest,
  onFinaliseOfferEpic
);
