import { connect } from 'react-redux';
import { PersonManagement as PersonManagementComponent } from './PersonManagement';
import { isString, PersonManagementStateProps } from './PersonManagement.types';
import {
  OfferFieldsIds,
  offerFieldsSelectors,
  offerGeneralSelectors,
  offerVariantsSelectors,
  personsSelectors,
} from '../../store/entities';
import { RootState } from '../../store/store.types';

const mapStateToProps = (state: RootState): PersonManagementStateProps => {
  const variantId = offerVariantsSelectors.getSelectedId(state) || '';
  const insuranceStartDateValue =
    offerFieldsSelectors.getField(state, variantId, OfferFieldsIds.INSURANCE_START_DATE)?.value ||
    '';
  const insuranceStartDate = isString(insuranceStartDateValue) ? insuranceStartDateValue : '';
  return {
    businessId: offerGeneralSelectors.getBusinessId(state),
    insuranceStartDate: insuranceStartDate,
    insuranceOwner: personsSelectors.getInsuranceOwner(state),
    insuranceOwnerId: personsSelectors.getInsuranceOwnerId(state),
    // TODO v-lung status etc.
    readOnly: false,
  };
};

export const PersonManagement = connect(mapStateToProps)(PersonManagementComponent);
