import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { offerFieldsSelectors } from '../../../../store/entities';
import { RootState } from '../../../../store/store.types';
import { WithFieldBaseOwnProps } from '../WithFieldBase';
import { WithFieldMaxStateProps } from './WithFieldMax.types';

const makeMapStateToProps = () => {
  const getFieldMax = offerFieldsSelectors.makeGetFieldMax();

  const mapStateToProps = (
    state: RootState,
    ownProps: WithFieldBaseOwnProps
  ): WithFieldMaxStateProps => {
    return {
      max: getFieldMax(state, ownProps.variantId, ownProps.fieldId),
    };
  };

  return mapStateToProps;
};

const connector = connect(makeMapStateToProps);

export const WithFieldMax = (Component: React.ComponentType<ConnectedProps<typeof connector>>) =>
  connector(Component);
