import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  agencySelectors,
  OfferBaseFieldsIds,
  offerGeneralActions,
  offerGeneralSelectors,
} from '../../store/entities';
import { ConsultancyProtocol as ConsultancyProtocolComponent } from './ConsultancyProtocol';
import { RootState } from '../../store/store.types';

const mapStateToProps = (state: RootState) => ({
  agentName: agencySelectors.getAgentName(state),
  channelDirection: offerGeneralSelectors.getConsultationProtocolChannelDirection(state),
  hasUpdateBaseRequestFailed: offerGeneralSelectors.hasUpdateBaseRequestFailed(state),
  hasUpdateBaseRequestSucceeded: offerGeneralSelectors.hasUpdateBaseRequestSucceeded(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setConsultancyProtocolId: (newIdd: string) => {
    dispatch(
      offerGeneralActions.updateBaseField.trigger([
        {
          fieldId: OfferBaseFieldsIds.CONSULTATION_PROTOCOL_ID,
          value: newIdd,
        },
      ])
    );
  },
});

export const ConsultancyProtocol = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultancyProtocolComponent);
