import { createSelector } from 'reselect';
import { RootState } from '../../store.types';
import { PersonsState, uPdsPersonWithValidation } from './persons.types';

const getInsuranceOwnerId = (state: RootState): string => state.entities.persons.insuranceOwnerId;
const getInsuranceOwner = (state: RootState): uPdsPersonWithValidation =>
  state.entities.persons.insuranceOwner;
const getPersons = (state: RootState): PersonsState => state.entities.persons;
const getInsuranceOwnerBirthate = (state: RootState): string =>
  state.entities.persons.insuranceOwner.birthDate;
const getInsuranceOwnerPartnerId = (state: RootState): string =>
  state.entities.persons.insuranceOwnerPartnerId;
const getFamilyMembersIds = (state: RootState) => state.entities.persons.familyMembersIds;

const getInsuranceOwnerFullName = createSelector(
  getInsuranceOwner,
  (insuranceOwner) => `${insuranceOwner.firstName} ${insuranceOwner.lastName}`
);

const getInsuranceOwnerValidationErrors = (state: RootState) =>
  state.entities.persons.insuranceOwnerValidationErrors;

export const personsSelectors = {
  getInsuranceOwnerId,
  getInsuranceOwner,
  getInsuranceOwnerBirthate,
  getInsuranceOwnerPartnerId,
  getFamilyMembersIds,
  getInsuranceOwnerFullName,
  getPersons,
  getInsuranceOwnerValidationErrors,
};
