import { ApiRequestStatus } from '../../../../types';
import { AppActions, AppActionsTypes } from '../../app';
import { OfferFieldsActions, OfferFieldsActionsTypes } from '../fields';
import { OfferGeneralActions, OfferGeneralActionsTypes } from '../general';
import { OfferPackagesActions, OfferPackagesActionsTypes } from '../packages';
import { OfferVariantsActions, OfferVariantsActionsTypes } from './variants.actions';
import { offerVariantsHelper } from './variants.helper';
import { OfferVariantsState } from './variants.types';

const defaultState: Readonly<OfferVariantsState> = {
  error: null,
  selectVariantStatus: ApiRequestStatus.idle,
  selectedId: null,
  ids: [],
  variantsById: {},
};

export const offerVariantsReducer = (
  state: OfferVariantsState = defaultState,
  action:
    | AppActions
    | OfferFieldsActions
    | OfferGeneralActions
    | OfferPackagesActions
    | OfferVariantsActions
): OfferVariantsState => {
  switch (action.type) {
    case AppActionsTypes.GET_FULL_OFFER_SUCCESS:
    case OfferFieldsActionsTypes.UPDATE_FIELDS_SUCCESS:
    case OfferGeneralActionsTypes.UPDATE_BIRTHDATE_SUCCESS:
    case OfferPackagesActionsTypes.UPDATE_PACKAGES_SUCCESS:
      return {
        ...state,
        ids: offerVariantsHelper.mapIds(action.payload.offer),
        variantsById: offerVariantsHelper.mapVariantsById(action.payload.offer),
        selectedId: offerVariantsHelper.getSelectedId(action.payload.offer),
      };

    case OfferVariantsActionsTypes.SELECT_VARIANT_TRIGGER:
      return {
        ...state,
        selectVariantStatus: ApiRequestStatus.pending,
      };

    case OfferVariantsActionsTypes.SELECT_VARIANT_SUCCESS:
      return {
        ...state,
        error: null,
        selectVariantStatus: ApiRequestStatus.succeeded,
        ids: offerVariantsHelper.mapIds(action.payload.offer),
        variantsById: offerVariantsHelper.mapVariantsById(action.payload.offer),
        selectedId: offerVariantsHelper.getSelectedId(action.payload.offer),
      };

    case OfferVariantsActionsTypes.SELECT_VARIANT_FAILURE:
      return {
        ...state,
        error: action.payload,
        selectVariantStatus: ApiRequestStatus.failed,
      };

    default:
      return state;
  }
};
