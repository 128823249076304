import { AjaxError } from 'rxjs/ajax';
import { ActionsUnion, createAsyncAction } from '../../../../services';
import { UpdateAgreementsApiRequest, UpdateAgreementsApiResponse } from './agreements.types';

export enum OfferAgreementsActionsTypes {
  UPDATE_AGREEMENTS_TRIGGER = '[entities][offer][agreements] UPDATE_AGREEMENTS_TRIGGER',
  UPDATE_AGREEMENTS_SUCCESS = '[entities][offer][agreements] UPDATE_AGREEMENTS_SUCCESS',
  UPDATE_AGREEMENTS_FAILURE = '[entities][offer][agreements] UPDATE_AGREEMENTS_FAILURE',
}

const updateAgreements = createAsyncAction(
  OfferAgreementsActionsTypes.UPDATE_AGREEMENTS_TRIGGER,
  OfferAgreementsActionsTypes.UPDATE_AGREEMENTS_SUCCESS,
  OfferAgreementsActionsTypes.UPDATE_AGREEMENTS_FAILURE
)<UpdateAgreementsApiRequest, UpdateAgreementsApiResponse, AjaxError>();

/**
 * combining and exporting of sync & async actions
 */

const actions = {};

const asyncActions = {
  updateAgreements,
};

export const offerAgreementsActions = {
  ...actions,
  ...asyncActions,
};

export type OfferAgreementsActions = ActionsUnion<typeof actions, typeof asyncActions>;
