import React from 'react';
import classNames from 'classnames';
import { ErrorsProps } from './Errors.types';
import styles from './Errors.module.scss';

export const Errors: React.FC<ErrorsProps> = ({ messages }) => {
  const hasErrors = !!messages.length;

  return (
    <div className={classNames({ [styles.root]: hasErrors })}>
      {hasErrors && (
        <ul className={styles.list}>
          {messages.map((message: string, index: number) => (
            <li className={styles.listItem} key={`sidebar.error.${index}`} title={message}>
              {message}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
