import { ApiRequestStatus } from '../../../../types';
import { AppActions, AppActionsTypes } from '../../app';
import { OfferFieldsActions, OfferFieldsActionsTypes } from './fields.actions';
import { offerFieldsHelper } from './fields.helper';
import { OfferFieldsState } from './fields.types';
import { OfferGeneralActions, OfferGeneralActionsTypes } from '../general';
import { OfferPackagesActions, OfferPackagesActionsTypes } from '../packages';
import { OfferVariantsActions, OfferVariantsActionsTypes } from '../variants';

const defaultState: Readonly<OfferFieldsState> = {
  error: null,
  updateFieldsStatus: ApiRequestStatus.idle,
  fieldsByVariantId: {},
};

export const offerFieldsReducer = (
  state: OfferFieldsState = defaultState,
  action:
    | AppActions
    | OfferFieldsActions
    | OfferGeneralActions
    | OfferPackagesActions
    | OfferVariantsActions
): OfferFieldsState => {
  switch (action.type) {
    case AppActionsTypes.GET_FULL_OFFER_SUCCESS:
    case OfferGeneralActionsTypes.UPDATE_BIRTHDATE_SUCCESS:
    case OfferPackagesActionsTypes.UPDATE_PACKAGES_SUCCESS:
    case OfferVariantsActionsTypes.SELECT_VARIANT_SUCCESS:
      return {
        ...state,
        fieldsByVariantId: offerFieldsHelper.mapVariantsFields(action.payload.offer),
      };

    case OfferFieldsActionsTypes.UPDATE_FIELDS_TRIGGER:
      return {
        ...state,
        updateFieldsStatus: ApiRequestStatus.pending,
      };

    case OfferFieldsActionsTypes.UPDATE_FIELDS_SUCCESS:
      return {
        ...state,
        error: null,
        updateFieldsStatus: ApiRequestStatus.succeeded,
        fieldsByVariantId: offerFieldsHelper.mapVariantsFields(action.payload.offer),
      };

    case OfferFieldsActionsTypes.UPDATE_FIELDS_FAILURE:
      return {
        ...state,
        error: action.payload,
        updateFieldsStatus: ApiRequestStatus.failed,
      };

    default:
      return state;
  }
};
