import {
  RetryLastActionModalActions,
  RetryLastActionModalActionsTypes,
} from './RetryLastActionModal.actions';
import { RetryLastActionModalState } from './RetryLastActionModal.types';

const defaultState: Readonly<RetryLastActionModalState> = {
  opened: false,
};

export const retryLastActionModalReducer = (
  state: RetryLastActionModalState = defaultState,
  action: RetryLastActionModalActions
): RetryLastActionModalState => {
  switch (action.type) {
    case RetryLastActionModalActionsTypes.OPEN:
      return {
        ...state,
        opened: true,
      };

    case RetryLastActionModalActionsTypes.CLOSE:
      return {
        ...state,
        opened: false,
      };

    default:
      return state;
  }
};
