import React from 'react';
import { PersonBlockProps } from './PersonBlock.types';
import styles from './PersonBlock.module.scss';
import { ValidationIcon } from '../ValidationIcon';

function formatName(firstName: string, lastName: string) {
  if (!firstName && !lastName) {
    return '-';
  }
  return `${lastName}, ${firstName}`;
}

export const PersonBlock: React.FC<PersonBlockProps> = ({ person }) => {
  return (
    <div className={styles.root}>
      <div>
        <div className={styles.label}>{formatName(person.firstName, person.lastName)}</div>
        <div className={styles.badges}>
          <div className={styles.badge}>VN</div>
          <div className={styles.badge}>VP</div>
        </div>
      </div>
      <div>
        <ValidationIcon validationStatus={person.pdsValidationStatus} />
      </div>
    </div>
  );
};
