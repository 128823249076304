import { Flow, OfferBFF } from '../offer.types';
import { OfferGeneralState } from './general.types';

type PartialOfferGeneralState = Omit<
  OfferGeneralState,
  | 'updateBirthdateStatus'
  | 'updateBirthdateError'
  | 'updateBaseStatus'
  | 'updateBaseError'
  | 'finaliseOfferStatus'
  | 'finaliseOfferError'
>;

const mapOfferBFFToPartialOfferGeneralState = (offer: OfferBFF): PartialOfferGeneralState => {
  return {
    businessId: offer.businessId ?? '',
    productName: offer.productName ?? '',
    actionNr: offer.actionNr ?? '',
    adsId: offer.adsId ?? '',
    consultationProtocolChannelDirection: offer.consultationProtocolChannelDirection ?? null,
    consultationProtocolId: offer.consultationProtocolId ?? null,
    offerStatus: offer.offerStatus as Flow,
    salesProcess: offer.salesProcess ?? null,
    salesModel: offer.salesModel ?? null,
    creatorNr: offer.creatorNr ?? '',
    birthdate: offer.birthdate,
    offerSuccessfullyClosed: false,
  };
};

export const offerGeneralHelper = {
  mapOfferBFFToPartialOfferGeneralState,
};
