import { AjaxError } from 'rxjs/ajax';
import { ActionsUnion, createAsyncAction } from '../../../../services';
import { SelectVariantApiRequest, SelectVariantApiResponse } from './variants.types';

export enum OfferVariantsActionsTypes {
  SELECT_VARIANT_TRIGGER = '[entities][offer][variants] SELECT_VARIANT_TRIGGER',
  SELECT_VARIANT_SUCCESS = '[entities][offer][variants] SELECT_VARIANT_SUCCESS',
  SELECT_VARIANT_FAILURE = '[entities][offer][variants] SELECT_VARIANT_FAILURE',
}

const selectVariant = createAsyncAction(
  OfferVariantsActionsTypes.SELECT_VARIANT_TRIGGER,
  OfferVariantsActionsTypes.SELECT_VARIANT_SUCCESS,
  OfferVariantsActionsTypes.SELECT_VARIANT_FAILURE
)<SelectVariantApiRequest, SelectVariantApiResponse, AjaxError>();

/**
 * combining and exporting of sync & async actions
 */

const actions = {};

const asyncActions = {
  selectVariant,
};

export const offerVariantsActions = {
  ...actions,
  ...asyncActions,
};

export type OfferVariantsActions = ActionsUnion<typeof actions, typeof asyncActions>;
