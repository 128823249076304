import { get } from 'lodash';
import { createSelector } from 'reselect';
import { ApiRequestStatus } from '../../../../types';
import { OfferVariant } from './variants.types';
import { RootState } from '../../../store.types';

const getAllIds = (state: RootState): string[] => state.entities.offer.variants.ids;

const getMapOfAllVariantsById = (state: RootState): Record<string, OfferVariant> =>
  state.entities.offer.variants.variantsById;

const getVariantById = (state: RootState, variantId: string): OfferVariant | null =>
  get(state.entities.offer.variants.variantsById, [variantId], null);

const getAllVariants = createSelector<
  RootState,
  Record<string, OfferVariant>,
  string[],
  OfferVariant[]
>(getMapOfAllVariantsById, getAllIds, (mapOfAllVariantsById, variantsIds) =>
  variantsIds.map((variantId) => get(mapOfAllVariantsById, [variantId])).filter(Boolean)
);

const getSelectedId = (state: RootState): string | null => state.entities.offer.variants.selectedId;

const getSelectVariantStatus = (state: RootState): ApiRequestStatus =>
  state.entities.offer.variants.selectVariantStatus;

const hasSelectVariantRequestFailed = createSelector<RootState, ApiRequestStatus, boolean>(
  getSelectVariantStatus,
  (status) => status === ApiRequestStatus.failed
);

const isSelectVariantRequestInprogress = createSelector<RootState, ApiRequestStatus, boolean>(
  getSelectVariantStatus,
  (status) => status === ApiRequestStatus.pending
);

export const offerVariantsSelectors = {
  getAllIds,
  getAllVariants,
  getMapOfAllVariantsById,
  getSelectedId,
  getSelectVariantStatus,
  getVariantById,
  hasSelectVariantRequestFailed,
  isSelectVariantRequestInprogress,
};
