import { ajax, AjaxError } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { buildEndpoint, ENDPOINTS } from '../../../../services';
import { offerAgreementsActions, OfferAgreementsActionsTypes } from './agreements.actions';
import { RootActions, RootState } from '../../../store.types';
import { buildDefaultHeaders } from '../../../../services';

export const onUpdateAgreementsRequest: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferAgreementsActionsTypes.UPDATE_AGREEMENTS_TRIGGER),
    mergeMap((action) => {
      return ajax({
        url: buildEndpoint(ENDPOINTS.updateAgreements),
        method: 'PUT',
        body: action.payload,
        headers: buildDefaultHeaders(),
        withCredentials: true,
      }).pipe(
        map((data) => offerAgreementsActions.updateAgreements.success(data.response)),
        catchError((error: AjaxError) => of(offerAgreementsActions.updateAgreements.failure(error)))
      );
    })
  );

export const offerAgreementsEpics = combineEpics(onUpdateAgreementsRequest);
