import React from 'react';
import classNames from 'classnames';
import TooltipIcon from '@eg/elements/TooltipIcon';
import { RowProps } from './Row.types';
import styles from './Row.module.scss';

export const Row: React.FC<RowProps> = ({ className, children, label, tooltip }) => {
  return (
    <tr className={classNames(styles.root, className)}>
      <th className={styles.labelsCol} scope='row'>
        <div className={styles.labelWrapper}>
          <div className={styles.label}>{label}</div>
          {!!tooltip ? (
            <TooltipIcon>{tooltip}</TooltipIcon>
          ) : (
            <div className={styles.spaceFiller} />
          )}
        </div>
      </th>
      {Array.isArray(children) ? (
        children.map((child, index: number) => (
          <td className={styles.contentCol} key={`offer.table.row.${index}`}>
            {child}
          </td>
        ))
      ) : (
        <td className={styles.contentCol}>{children}</td>
      )}
    </tr>
  );
};
