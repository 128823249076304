import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'isomorphic-fetch';

if (!('Symbol' in window)) {
    // tslint:disable-next-line:no-console
    console.info(
        // tslint:disable-next-line:max-line-length
        'Diese Komponente benötigt einen Symbol-polyfill damit sie im IE11 funktioniert. Bitte stellen sie sicher, dass diese vor react & redux importiert wird!'
    );
}
/*TODO: mf 16012020: babel-polyfill gibt es in der neuen Version nicht mehr --> braucht man die if-Abfrage dann noch?
if (!('_babelPolyfill' in global) && !('_babelPolyfill' in window)) {
    require('babel-polyfill');
}*/

export { BootstrappedForm, SectionComponent } from './components';
export { ValuesMap } from './models';
