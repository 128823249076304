import React from 'react';
import { Errors, Payment, SalesProcess, Section, Statics, Submit } from './components';

export class Sidebar extends React.Component {
  static Errors = Errors;
  static Payment = Payment;
  static SalesProcess = SalesProcess;
  static Section = Section;
  static Statics = Statics;
  static Submit = Submit;

  render() {
    return <>{this.props.children}</>;
  }
}
