import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { partial } from 'lodash';
import {
  OfferPackageFieldsIds,
  offerPackagesActions,
  OfferPackagesIds,
  offerPackagesSelectors,
} from '../../../../store/entities';
import { offerSelectors } from '../../../../store/entities/offer/offer.selectors';
import { RootState } from '../../../../store/store.types';
import {
  WithPackageBaseDispatchProps,
  WithPackageBaseMergeProps,
  WithPackageBaseOwnProps,
  WithPackageBaseStateProps,
} from './WithPackageBase.types';

const makeMapStateToProps = () => {
  const getPackageSelected = offerPackagesSelectors.makeGetPackageSelected();

  const mapStateToProps = (
    state: RootState,
    ownProps: WithPackageBaseOwnProps
  ): WithPackageBaseStateProps => {
    return {
      hasUpdatePackagesRequestFailed: offerPackagesSelectors.hasUpdatePackagesRequestFailed(state),
      isAnyOfferRequestInProgress: offerSelectors.isAnyOfferRequestInProgress(state),
      selected: getPackageSelected(state, ownProps.variantId, ownProps.packageId),
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch: Dispatch): WithPackageBaseDispatchProps => ({
  select: (
    variantId: string,
    packageId: OfferPackagesIds,
    fieldId: OfferPackageFieldsIds,
    selected: boolean
  ) =>
    dispatch(
      offerPackagesActions.updatePackages.trigger([
        {
          variantId,
          packageId,
          fieldId,
          value: selected,
        },
      ])
    ),
});

const mergeProps = (
  stateProps: WithPackageBaseStateProps,
  dispatchProps: WithPackageBaseDispatchProps,
  ownProps: WithPackageBaseOwnProps
): WithPackageBaseMergeProps => ({
  ...ownProps,
  ...stateProps,
  select: (selected: boolean) =>
    partial(
      dispatchProps.select,
      ownProps.variantId,
      ownProps.packageId,
      OfferPackageFieldsIds.selected
    )(selected),
});

const connector = connect(makeMapStateToProps, mapDispatchToProps, mergeProps);

export const WithPackageBase = (Component: React.ComponentType<ConnectedProps<typeof connector>>) =>
  connector(Component);
