import React from 'react';
import {
  DebitDay,
  Deductible,
  DurationOfContract,
  InsuranceStartDate,
  MaritalStatus,
  PaymentMethod,
} from './components';
import { GeneralContractProps } from './GeneralContract.types';
import { OfferFieldsIds, OfferPackagesIds } from '../../store/entities';
import { OfferTable } from '../../components/OfferTable/OfferTable';
import styles from './GeneralContract.module.scss';

export const GeneralContract: React.FC<GeneralContractProps> = ({ selectedVariantId }) => {
  return (
    <div className={styles.root}>
      <OfferTable>
        {/* DO NOT REMOVE THE MANDATORY THEAD */}
        <thead className={styles.emptyTableHeader}>
          <tr>
            <th />
          </tr>
        </thead>
        <tbody>
          <InsuranceStartDate
            variantId={selectedVariantId}
            fieldId={OfferFieldsIds.INSURANCE_START_DATE}
          />
          <PaymentMethod variantId={selectedVariantId} fieldId={OfferFieldsIds.PAYMENT_METHOD} />
          <DurationOfContract
            variantId={selectedVariantId}
            fieldId={OfferFieldsIds.DURATION_OF_CONTRACT}
          />
          <MaritalStatus variantId={selectedVariantId} fieldId={OfferFieldsIds.MARITAL_STATUS} />
          <Deductible variantId={selectedVariantId} packageId={OfferPackagesIds.DEDUCTIBLE} />
          <DebitDay variantId={selectedVariantId} fieldId={OfferFieldsIds.DEBIT_DAY} />
        </tbody>
      </OfferTable>
    </div>
  );
};
