import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AccordionComponent } from './Accordion.component';
import { RootState } from '../../store/store.types';
import { accordionActions } from './Accordions.actions';
import { accordionSelectors } from './Accordions.selectors';
import { AccordionOwnProps } from './Accordion.types';

const mapStateToProps = (state: RootState, ownProps: AccordionOwnProps) => {
  return {
    isOpen: accordionSelectors.isAccordionOpened(state, ownProps.sectionKey),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: AccordionOwnProps) => ({
  register: () => {
    dispatch(accordionActions.registerAccordion(ownProps.sectionKey));
  },
  toggle: () => {
    dispatch(accordionActions.toggleAccordion(ownProps.sectionKey));
  },
  unregister: () => {
    dispatch(accordionActions.unregisterAccordion(ownProps.sectionKey));
  },
});

export const Accordion = connect(mapStateToProps, mapDispatchToProps)(AccordionComponent);
