import { AjaxError } from 'rxjs/ajax';
import { ApiRequestStatus } from '../../../../types';
import { FullOfferBFF } from '../../app';
import { OfferMessagesState } from '../messages';
import { ConsultancyChannelDirection, Flow, SalesModel, SalesProcess } from '../offer.types';

export type OfferGeneral = {
  businessId: string;
  productName: string;
  actionNr: string;
  adsId: string;
  offerStatus: Flow;
  salesProcess: SalesProcess | null;
  salesModel: SalesModel | null;
  creatorNr: string;
  birthdate: string;
};

export type OfferGeneralState = {
  businessId: string;
  productName: string;
  actionNr: string;
  adsId: string;
  consultationProtocolChannelDirection: ConsultancyChannelDirection | null;
  consultationProtocolId: string | null;
  offerStatus: Flow;
  offerSuccessfullyClosed: boolean;
  salesProcess: SalesProcess | null;
  salesModel: SalesModel | null;
  creatorNr: string;
  birthdate: string;
  updateBirthdateStatus: ApiRequestStatus;
  updateBirthdateError: AjaxError | null;
  finaliseOfferStatus: ApiRequestStatus;
  finaliseOfferError: AjaxError | null;
  updateBaseStatus: ApiRequestStatus;
  updateBaseError: AjaxError | null;
};

export type UpdateBirthdate = {
  birthdate: string;
};

export type OfferBaseField = {
  fieldId: OfferBaseFieldsIds;
  value: string;
};

export enum OfferBaseFieldsIds {
  CONSULTATION_PROTOCOL_ID = 'consultationProtocolId',
}

export type UpdateBaseApiRequest = OfferBaseField[];

export type UpdateBaseApiResponse = FullOfferBFF;

export type UpdateBirthdateApiRequest = UpdateBirthdate;

export type UpdateBirthdateApiResponse = FullOfferBFF;

export type FinaliseOfferApiResponse = OfferMessagesState;

export type UpdateBaseApiFailureContext = OfferBaseField[];
