import { connect } from 'react-redux';
import { App as AppComponent } from './App';
import { AppStateProps } from './App.types';
import { appSelectors, offerGeneralSelectors } from '../../store/entities';
import { RootState } from '../../store/store.types';

const mapStateToProps = (state: RootState): AppStateProps => ({
  isInitialized: appSelectors.isInitialized(state),
  isReadOnly: offerGeneralSelectors.isReadOnly(state),
});

export const App = connect(mapStateToProps)(AppComponent);
