import { connect } from 'react-redux';
import { offerGeneralSelectors } from '../../store/entities';
import { ReadOnlyMode as ReadOnlyModeComponent } from './ReadOnlyMode';
import {
  ReadOnlyModeDispatchProps,
  ReadOnlyModeMergeProps,
  ReadOnlyModeOwnProps,
  ReadOnlyModeStateProps,
} from './ReadOnlyMode.types';
import { RootState } from '../../store/store.types';

const mapStateToProps = (state: RootState): ReadOnlyModeStateProps => ({
  isReadOnly: offerGeneralSelectors.isReadOnly(state),
});

const mergeProps = (
  stateProps: ReadOnlyModeStateProps,
  dispatchProps: ReadOnlyModeDispatchProps,
  ownProps: ReadOnlyModeOwnProps
): ReadOnlyModeMergeProps => ({
  ...ownProps,
  readOnly: stateProps.isReadOnly,
});

export const ReadOnlyMode = connect(mapStateToProps, null, mergeProps)(ReadOnlyModeComponent);
