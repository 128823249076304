import { AgreementsActions, AgreementsActionsTypes } from './Agreements.actions';
import { AgreementsState } from './Agreements.types';

const defaultState: Readonly<AgreementsState> = {
  isInsuranceOwnerInformedAboutContractConlusion: false,
};

export const agreementsReducer = (
  state: AgreementsState = defaultState,
  action: AgreementsActions
): AgreementsState => {
  switch (action.type) {
    case AgreementsActionsTypes.SET_IS_INSURANCE_OWNER_INFORMED_ABOUT_CONCLUSION:
      return {
        ...state,
        isInsuranceOwnerInformedAboutContractConlusion: action.payload,
      };

    default:
      return state;
  }
};
