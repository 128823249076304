import { Redirect, Route, Switch } from 'react-router-dom';
import { NotFoundPage, OfferPage } from '../../features';
import { buildRoute, ROUTES } from '../../services/route.builder.service';

const BUSINESS_ID_PATTERN =
  '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';

export const AppRouter = () => (
  <Switch>
    <Route
      exact
      path={buildRoute(ROUTES.offer, { businessId: `:businessId(${BUSINESS_ID_PATTERN})` })}
    >
      <OfferPage />
    </Route>

    <Redirect to={buildRoute(ROUTES.notFound)}>
      <NotFoundPage />
    </Redirect>
  </Switch>
);
