import { get } from 'lodash';
import { Agreement, OfferAgreement, OfferAgreementsState } from './agreements.types';
import { OfferAgreementsBFF, OfferBFF, OfferFeeBFF } from '../offer.types';

const mapOfferBFFToOfferAgreementsSet = (offer: OfferBFF): OfferAgreementsState['agreements'] => {
  const agreements = get<OfferBFF, 'agreements', OfferFeeBFF[]>(
    offer,
    'agreements',
    []
  ) as OfferAgreementsBFF[];
  return agreements.reduce(
    (acc: Record<OfferAgreement['variantId'], Agreement[]>, variantAgreement) => {
      acc[variantAgreement.variantId] = variantAgreement.agreements;
      return acc;
    },
    {}
  );
};

export const offerAgreementsHelper = {
  mapOfferBFFToOfferAgreementsSet,
};
