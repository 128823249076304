import { createSelector } from 'reselect';
import { ApiRequestStatus, Option } from '../../../../types';
import { OfferField, OfferFieldsIds, OfferFieldValue } from './fields.types';
import { RootState } from '../../../store.types';

const getField = (
  state: RootState,
  variantId: string,
  fieldId: OfferFieldsIds
): OfferField | null => {
  const variantFields = state.entities.offer.fields.fieldsByVariantId[variantId] || null;

  if (variantFields === null) {
    return null;
  }

  const field = variantFields.fieldsById[fieldId];

  return field || null;
};

const makeGetFieldMax = () => createSelector(getField, (field): Date | undefined => field?.max);

const makeGetFieldMin = () => createSelector(getField, (field): Date | undefined => field?.min);

const makeGetFieldOptions = () =>
  createSelector(getField, (field): Option[] => field?.options ?? []);

const makeGetFieldValue = <T extends OfferFieldValue>() =>
  createSelector(getField, (field): T => (field?.value as T) ?? '');

const getUpdateFieldsStatus = (state: RootState): ApiRequestStatus =>
  state.entities.offer.fields.updateFieldsStatus;

const hasUpdateFieldsRequestFailed = createSelector<RootState, ApiRequestStatus, boolean>(
  getUpdateFieldsStatus,
  (status) => status === ApiRequestStatus.failed
);

const isUpdateFieldsRequestInprogress = createSelector<RootState, ApiRequestStatus, boolean>(
  getUpdateFieldsStatus,
  (status) => status === ApiRequestStatus.pending
);

export const offerFieldsSelectors = {
  getField,
  makeGetFieldMax,
  makeGetFieldMin,
  makeGetFieldOptions,
  makeGetFieldValue,
  getUpdateFieldsStatus,
  hasUpdateFieldsRequestFailed,
  isUpdateFieldsRequestInprogress,
};
