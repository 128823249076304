import { Action, ActionWithContext, ActionWithPayload } from './types';

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>;
export function createAction<T extends string, P, C>(
  type: T,
  payload: P,
  context: C
): ActionWithContext<T, P, C>;
export function createAction<T extends string, P, C>(type: T, payload?: P, context?: C) {
  return {
    type,
    payload,
    context,
  };
}

// Async action builder, needs to receive explicit action types, can be refactored after TypeScript 4.1
export const createAsyncAction =
  <TP extends string, ST extends string, FT extends string>(
    triggerType: TP,
    successType: ST,
    failureType: FT
  ) =>
  <P, S, F, TC = void, SC = void, FC = void>() => {
    return {
      trigger: (payload: P, context?: TC) => createAction(triggerType, payload, context),
      success: (success: S, context?: SC) => createAction(successType, success, context),
      failure: (error: F, context?: FC) => createAction(failureType, error, context),
    };
  };
