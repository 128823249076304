import { combineEpics, Epic, ofType } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import {
  OfferAgreementsActionsTypes,
  OfferBaseField,
  OfferBaseFieldsIds,
  OfferFieldsActionsTypes,
  OfferGeneralActionsTypes,
  OfferPackagesActionsTypes,
  OfferVariantsActionsTypes,
} from '../../store/entities';
import { retryLastActionModalActions } from './RetryLastActionModal.actions';
import { RootActions, RootState } from '../../store/store.types';
import { OfferPaymentActionsTypes } from '../../store/entities/offer/payment/payment.actions';

export const onError: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType<RootActions>(
      OfferAgreementsActionsTypes.UPDATE_AGREEMENTS_FAILURE,
      OfferFieldsActionsTypes.UPDATE_FIELDS_FAILURE,
      OfferGeneralActionsTypes.UPDATE_BASE_FAILURE,
      OfferPackagesActionsTypes.UPDATE_PACKAGES_FAILURE,
      OfferVariantsActionsTypes.SELECT_VARIANT_FAILURE,
      OfferPaymentActionsTypes.UPDATE_PAYMENT_FAILURE
    ),
    filter((action) => {
      if (action.type === OfferGeneralActionsTypes.UPDATE_BASE_FAILURE) {
        const hasConsultationProtocolIdField = !!action.context.find(
          (field: OfferBaseField) => field.fieldId === OfferBaseFieldsIds.CONSULTATION_PROTOCOL_ID
        );

        return hasConsultationProtocolIdField;
      }

      return true;
    }),
    map(() => retryLastActionModalActions.open())
  );

export const retryLastActionModalEpics = combineEpics(onError);
