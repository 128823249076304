import React, { useEffect, useState } from 'react';
import Select from '@eg/elements/Select';
import { DebitDayProps } from './DebitDay.types';
import { Field, OfferTable } from '../../../../components';
import { OfferFieldValue } from '../../../../store/entities';
import styles from './DebitDay.module.scss';

export const DebitDay: React.FC<DebitDayProps> = ({
  hasUpdateFieldsRequestFailed,
  isAnyOfferRequestInProgress,
  options,
  update,
  value,
}) => {
  const hasOptions = !!options.length;
  const disabledField = isAnyOfferRequestInProgress || !hasOptions;
  const [selectedValue, setSelectedValue] = useState<OfferFieldValue>(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [hasUpdateFieldsRequestFailed, value]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value);

    setSelectedValue(value);
    update(value);
  };

  return (
    <OfferTable.Row className={styles.root} label='Abbuchung zum'>
      <Field className={styles.field} disabled={disabledField}>
        <Select value={selectedValue} onChange={onChangeHandler}>
          {!selectedValue && <option disabled value='' />}
          {options.map((option) => (
            <option value={option.value} key={`debit.day.option.${option.value}`}>
              {option.label}
            </option>
          ))}
        </Select>
      </Field>
    </OfferTable.Row>
  );
};
