import Button from '@eg/elements/Button';
import React from 'react';
import { labels } from './Submit.labels';
import styles from './Submit.module.scss';
import { SubmitProps } from './Submit.types';

export const Submit: React.FC<SubmitProps> = ({ disabled = false, finalizeOrder }) => (
  <div className={styles.root}>
    <div className={styles.finalizeBtn}>
      <Button disabled={disabled} variant='primary' size='large' onClick={finalizeOrder}>
        {labels.finalizeBtn}
      </Button>
    </div>
  </div>
);
