import React, { useEffect, useState } from 'react';
import Checkbox from '@eg/elements/Checkbox';
import { Field, OfferTable } from '../../../../components';
import { StartingBonusProps } from './StartingBonus.types';
import { StartingBonusTooltip } from './StartingBonusTooltip';
import styles from './StartingBonus.module.scss';

export const StartingBonus: React.FC<StartingBonusProps> = ({
  hasUpdatePackagesRequestFailed,
  select,
  selected,
}) => {
  const [checked, setChecked] = useState<boolean>(selected);

  useEffect(() => {
    setChecked(selected);
  }, [hasUpdatePackagesRequestFailed, selected]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    setChecked(checked);
    select(checked);
  };

  return (
    <OfferTable.Row
      className={styles.root}
      label='Startzuzahlung unter 36J 13%'
      tooltip={StartingBonusTooltip}
    >
      <Field className={styles.field} disabled={true}>
        <Checkbox label='' checked={checked} onChange={onChangeHandler} />
      </Field>
    </OfferTable.Row>
  );
};
