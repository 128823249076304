import { Agency } from './agency.types';
import { RootState } from '../../store.types';

const getAgency = (state: RootState): Agency => state.entities.agency;
const getId = (state: RootState): string => state.entities.agency.id;
const getOeNr = (state: RootState): string => state.entities.agency.oeNr;
const getAgentName = (state: RootState): string => state.entities.agency.agentName;
const getAgentNumber = (state: RootState): string => state.entities.agency.agentNumber;
const getDistributionChannel = (state: RootState): string =>
  state.entities.agency.distributionChannel;
const getDepartmentNumber = (state: RootState): string => state.entities.agency.departmentNumber;
const getCompanyNumber = (state: RootState): string => state.entities.agency.companyNumber;

export const agencySelectors = {
  getAgency,
  getId,
  getOeNr,
  getAgentName,
  getAgentNumber,
  getDistributionChannel,
  getDepartmentNumber,
  getCompanyNumber,
};
