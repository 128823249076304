import { AjaxError } from 'rxjs/ajax';
import { ActionsUnion, createAsyncAction } from '../../../../services';
import { UpdatePackagesApiRequest, UpdatePackagesApiResponse } from './packages.types';

export enum OfferPackagesActionsTypes {
  UPDATE_PACKAGES_TRIGGER = '[entities][offer][packages] UPDATE_PACKAGES_TRIGGER',
  UPDATE_PACKAGES_SUCCESS = '[entities][offer][packages] UPDATE_PACKAGES_SUCCESS',
  UPDATE_PACKAGES_FAILURE = '[entities][offer][packages] UPDATE_PACKAGES_FAILURE',
}

const updatePackages = createAsyncAction(
  OfferPackagesActionsTypes.UPDATE_PACKAGES_TRIGGER,
  OfferPackagesActionsTypes.UPDATE_PACKAGES_SUCCESS,
  OfferPackagesActionsTypes.UPDATE_PACKAGES_FAILURE
)<UpdatePackagesApiRequest, UpdatePackagesApiResponse, AjaxError>();

/**
 * combining and exporting of sync & async actions
 */

const actions = {};

const asyncActions = {
  updatePackages,
};

export const offerPackagesActions = {
  ...actions,
  ...asyncActions,
};

export type OfferPackagesActions = ActionsUnion<typeof actions, typeof asyncActions>;
