import { connect } from 'react-redux';
import { UnauthorizedModalComponent } from './UnauthorizedModal.component';
import { RootState } from '../../store/store.types';
import { UnauthorizedModalSelectors } from './UnauthorizedModal.selectors';

const mapStateToProps = (state: RootState) => ({
  isOpen: UnauthorizedModalSelectors.isOpened(state),
});

export const UnauthorizedModal = connect(mapStateToProps)(UnauthorizedModalComponent);
