import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { offerGeneralActions, personsSelectors } from '../../store/entities';
import { RootState } from '../../store/store.types';
import { UpdateBirthdateErrorModal as UpdateBirthdateErrorModalComponent } from './UpdateBirthdateErrorModal';
import { updateBirthdateErrorModalSelectors } from './UpdateBirthdateErrorModal.selectors';
import {
  UpdateBirthdateErrorModalDispatchProps,
  UpdateBirthdateErrorModalMergeProps,
  UpdateBirthdateErrorModalStateProps,
} from './UpdateBirthdateErrorModal.types';

const mapStateToProps = (state: RootState): UpdateBirthdateErrorModalStateProps => ({
  birthdate: personsSelectors.getInsuranceOwnerBirthate(state),
  isOpened: updateBirthdateErrorModalSelectors.isOpened(state),
});

const mapDispatchToProps = (dispatch: Dispatch): UpdateBirthdateErrorModalDispatchProps => ({
  updateBirthdate: (birthdate: string) =>
    dispatch(
      offerGeneralActions.updateBirthdate.trigger({
        birthdate,
      })
    ),
});

const mergeProps = (
  stateProps: UpdateBirthdateErrorModalStateProps,
  dispatchProps: UpdateBirthdateErrorModalDispatchProps
): UpdateBirthdateErrorModalMergeProps => {
  const { birthdate, ...restStateProps } = stateProps;
  const { updateBirthdate, ...restDispatchProps } = dispatchProps;

  return {
    ...restStateProps,
    ...restDispatchProps,
    updateBirthdate: () => dispatchProps.updateBirthdate(stateProps.birthdate),
  };
};

export const UpdateBirthdateErrorModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(UpdateBirthdateErrorModalComponent);
