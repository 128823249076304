import { AgencyBFF } from '../agency';
import { OfferBFF } from '../offer';

export type FullOfferBFF = {
  agency?: AgencyBFF;
  offer: OfferBFF;
  persons?: any;
  payment?: any;
};

export type GetFullOfferApiResponse = FullOfferBFF;

export type AppState = {
  isInitialized: boolean;
  initializationStatus: InitializationStatus;
};

export enum InitializationStatus {
  idle = 'idle',
  pending = 'pending',
  succeeded = 'succeeded',
  failedWithServiceError = 'failedWithServiceError',
  failedAuthentication = 'failedAuthentication',
  notFoundOffer = 'notFoundOffer',
}
