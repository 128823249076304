import 'wicg-inert';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@eg/elements/styles/ergo-one/core.css';
import '@eg/elements/styles/ergo-one/styles.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { App } from './components/App';
import store, { history } from './store/store';
import './assets/base.scss';
import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('phv-telephony-root')
);
