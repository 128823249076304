import { AppActions, AppActionsTypes } from '../../app';
import { OfferFieldsActions, OfferFieldsActionsTypes } from '../fields';
import { OfferGeneralActions, OfferGeneralActionsTypes } from '../general';
import { OfferMessagesState } from './messages.types';
import { OfferPackagesActions, OfferPackagesActionsTypes } from '../packages';
import { OfferVariantsActions, OfferVariantsActionsTypes } from '../variants';

const defaultState: Readonly<OfferMessagesState> = {
  messages: [],
};

export const offerMessagesReducer = (
  state: OfferMessagesState = defaultState,
  action:
    | AppActions
    | OfferFieldsActions
    | OfferGeneralActions
    | OfferPackagesActions
    | OfferVariantsActions
): OfferMessagesState => {
  switch (action.type) {
    case AppActionsTypes.GET_FULL_OFFER_SUCCESS:
    case OfferFieldsActionsTypes.UPDATE_FIELDS_SUCCESS:
    case OfferGeneralActionsTypes.UPDATE_BIRTHDATE_SUCCESS:
    case OfferPackagesActionsTypes.UPDATE_PACKAGES_SUCCESS:
    case OfferVariantsActionsTypes.SELECT_VARIANT_SUCCESS:
      return {
        ...state,
        messages: action.payload.offer?.messages || [],
      };

    case OfferGeneralActionsTypes.FINALISE_OFFER_SUCCESS:
      return {
        ...state,
        messages: action.payload?.messages || [],
      };

    default:
      return state;
  }
};
