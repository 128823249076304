import { createSelector } from 'reselect';
import { get } from 'lodash';
import { Agreement, OfferAgreement } from './agreements.types';
import { ApiRequestStatus } from '../../../../types';
import { RootState } from '../../../store.types';

const getAllAgreements = (state: RootState): Record<OfferAgreement['variantId'], Agreement[]> =>
  state.entities.offer.agreements.agreements;

const getAgreementsByVariantId = (state: RootState, variantId: string): Agreement[] =>
  get(state.entities.offer.agreements.agreements, [variantId], []);

const getUpdateAgreementsStatus = (state: RootState): ApiRequestStatus =>
  state.entities.offer.agreements.updateAgreementsStatus;

const hasUpdateAgreementsRequestFailed = createSelector<RootState, ApiRequestStatus, boolean>(
  getUpdateAgreementsStatus,
  (status) => status === ApiRequestStatus.failed
);

const isUpdateAgreementsRequestInprogress = createSelector<RootState, ApiRequestStatus, boolean>(
  getUpdateAgreementsStatus,
  (status) => status === ApiRequestStatus.pending
);

export const offerAgreementsSelectors = {
  getAllAgreements,
  getAgreementsByVariantId,
  hasUpdateAgreementsRequestFailed,
  isUpdateAgreementsRequestInprogress,
};
