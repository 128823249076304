import React from 'react';
import classNames from 'classnames';
import Button from '@eg/elements/Button';
import CheckIcon from '@eg/elements/Icons/CheckIcon';
import RefreshIcon from '@eg/elements/Icons/RefreshIcon';
import CloseBorderIcon from '@eg/elements/Icons/CloseBorderIcon';
import { ValidationStatus } from '@disorder/personal-data-models';

import { ValidatePersonButtonProps } from './ValidatePersonButton.types';
import { labels } from './ValidatePersonButton.labels';
import styles from './ValidatePersonButton.module.scss';

function getClassNames(validationStatus: ValidationStatus) {
  return classNames(styles.button, {
    [styles.valid]: validationStatus === ValidationStatus.VALID,
    [styles.invalid]: validationStatus === ValidationStatus.INVALID,
    [styles.unknown]: validationStatus === ValidationStatus.UNKNOWN,
  });
}

function getValidationIcon(validationStatus: ValidationStatus) {
  switch (validationStatus) {
    case ValidationStatus.VALID:
      return CheckIcon;
    case ValidationStatus.INVALID:
      return CloseBorderIcon;
    case ValidationStatus.UNKNOWN:
      return RefreshIcon;
  }
}

export const ValidatePersonButton: React.FC<ValidatePersonButtonProps> = ({
  onClick,
  validationStatus,
  ...buttonProps
}) => {
  return (
    <div className={styles.root}>
      <Button
        onClick={onClick}
        className={getClassNames(validationStatus)}
        iconLeft={getValidationIcon(validationStatus)}
        {...buttonProps}
      >
        {labels[validationStatus]}
      </Button>
    </div>
  );
};
