export interface AccordionOwnProps extends React.HTMLAttributes<HTMLElement> {
  summary: string;
  sectionKey: ClosableAccordionTypes;
}

export interface AccordionProps
  extends AccordionOwnProps,
    AccordionDispatchProps,
    AccordionStateProps {}

export interface AccordionDispatchProps {
  toggle: () => void;
  register: () => void;
  unregister: () => void;
}

export interface AccordionStateProps {
  isOpen?: boolean;
}

export enum ClosableAccordionTypes {
  PERSON_DATA = 'PERSON_DATA',
  GENERAL_INFORMATION = 'GENERAL_INFORMATION',
  INSURED_PERSON_PACKAGES = 'INSURED_PERSON_PACKAGES',
  CONSULTATION_PROTOCOL = 'CONSULTATION_PROTOCOL',
  AGREEMENTS = 'AGREEMENTS',
  PAYMENT_DATA = 'PAYMENT_DATA',
}
