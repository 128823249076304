import { AjaxError } from 'rxjs/ajax';
import { ActionsUnion, createAsyncAction } from '../../../services';
import { GetFullOfferApiResponse } from './app.types';

export enum AppActionsTypes {
  GET_FULL_OFFER_TRIGGER = '[entities][app] GET_FULL_OFFER_TRIGGER',
  GET_FULL_OFFER_SUCCESS = '[entities][app] GET_FULL_OFFER_SUCCESS',
  GET_FULL_OFFER_FAILURE = '[entities][app] GET_FULL_OFFER_FAILURE',
}

const getFullOffer = createAsyncAction(
  AppActionsTypes.GET_FULL_OFFER_TRIGGER,
  AppActionsTypes.GET_FULL_OFFER_SUCCESS,
  AppActionsTypes.GET_FULL_OFFER_FAILURE
)<void, GetFullOfferApiResponse, AjaxError>();

/**
 * combining and exporting of sync & async actions
 */

const actions = {};

const asyncActions = {
  getFullOffer,
};

export const appActions = {
  ...actions,
  ...asyncActions,
};

export type AppActions = ActionsUnion<typeof actions, typeof asyncActions>;
