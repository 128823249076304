import { combineReducers } from 'redux';
import {
  agencyReducer,
  appReducer,
  personsReducer,
  offerAgreementsReducer,
  offerFeesReducer,
  offerFieldsReducer,
  offerGeneralReducer,
  offerMessagesReducer,
  offerPackagesReducer,
  offerVariantsReducer,
} from './entities';
import { offerPaymentReducer } from './entities/offer/payment/payment.reducer';

export const entities = combineReducers({
  agency: agencyReducer,
  app: appReducer,
  persons: personsReducer,
  offer: combineReducers({
    agreements: offerAgreementsReducer,
    fees: offerFeesReducer,
    fields: offerFieldsReducer,
    general: offerGeneralReducer,
    messages: offerMessagesReducer,
    packages: offerPackagesReducer,
    payment: offerPaymentReducer,
    variants: offerVariantsReducer,
  }),
});
