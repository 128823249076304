import { AjaxError } from 'rxjs/ajax';
import { ActionsUnion, createAsyncAction } from '../../../../services';
import { UpdateFieldsApiRequest, UpdateFieldsApiResponse } from './fields.types';

export enum OfferFieldsActionsTypes {
  UPDATE_FIELDS_TRIGGER = '[entities][offer][fields] UPDATE_FIELDS_TRIGGER',
  UPDATE_FIELDS_SUCCESS = '[entities][offer][fields] UPDATE_FIELDS_SUCCESS',
  UPDATE_FIELDS_FAILURE = '[entities][offer][fields] UPDATE_FIELDS_FAILURE',
}

const updateFields = createAsyncAction(
  OfferFieldsActionsTypes.UPDATE_FIELDS_TRIGGER,
  OfferFieldsActionsTypes.UPDATE_FIELDS_SUCCESS,
  OfferFieldsActionsTypes.UPDATE_FIELDS_FAILURE
)<UpdateFieldsApiRequest, UpdateFieldsApiResponse, AjaxError>();

/**
 * combining and exporting of sync & async actions
 */

const actions = {};

const asyncActions = {
  updateFields,
};

export const offerFieldsActions = {
  ...actions,
  ...asyncActions,
};

export type OfferFieldsActions = ActionsUnion<typeof actions, typeof asyncActions>;
