import { ActionsUnion, createAction } from '../../services';

export enum FatalErrorModalActionsTypes {
  OPEN = '[ui][fatal-error-modal] OPEN',
  CLOSE = '[ui][fatal-error-modal] CLOSE',
}

const open = () => createAction(FatalErrorModalActionsTypes.OPEN);
const close = () => createAction(FatalErrorModalActionsTypes.CLOSE);

/**
 * combining and exporting of sync & async actions
 */

const actions = {
  open,
  close,
};

const asyncActions = {};

export const fatalErrorModalActions = {
  ...actions,
  ...asyncActions,
};

export type FatalErrorModalActions = ActionsUnion<typeof actions, typeof asyncActions>;
