import React, { useEffect, useState } from 'react';
import Checkbox from '@eg/elements/Checkbox';
import { Field, OfferTable } from '../../../../components';
import { OfficialAndPublicLiabilityProps } from './OfficialAndPublicLiability.types';
import { OfficialAndPublicLiabilityTooltip } from './OfficialAndPublicLiabilityTooltip';
import styles from './OfficialAndPublicLiability.module.scss';
import { OfficialAndPublicLiabilityPerson } from '../OfficialAndPublicLiabilityPerson';
import { OfferPackagesIds } from '../../../../store/entities';

export const OfficialAndPublicLiability: React.FC<OfficialAndPublicLiabilityProps> = ({
  hasUpdatePackagesRequestFailed,
  isAnyOfferRequestInProgress,
  select,
  selected,
}) => {
  const [checked, setChecked] = useState<boolean>(selected);

  useEffect(() => {
    setChecked(selected);
  }, [hasUpdatePackagesRequestFailed, selected]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    setChecked(checked);
    select(checked);
  };

  return (
    <>
      <OfferTable.Row
        className={styles.root}
        label='Amts- und Diensthaftpflicht'
        tooltip={OfficialAndPublicLiabilityTooltip}
      >
        <Field className={styles.field} disabled={isAnyOfferRequestInProgress}>
          <Checkbox label='' checked={checked} onChange={onChangeHandler} />
        </Field>
      </OfferTable.Row>
      {checked && (
        <OfficialAndPublicLiabilityPerson
          packageId={OfferPackagesIds.OFFICIAL_AND_PUBLIC_LIABILITY}
        />
      )}
    </>
  );
};
