import { get } from 'lodash';
import { OfferFee } from './fees.types';
import { RootState } from '../../../store.types';

const getAllFees = (state: RootState): Record<OfferFee['variantId'], OfferFee> =>
  state.entities.offer.fees.fees;

const getFeeGrossByVariantId = (state: RootState, variantId: string): number | null =>
  get(state.entities.offer.fees.fees, [variantId, 'gross'], null);

const getFeeNetByVariantId = (state: RootState, variantId: string): number | null =>
  get(state.entities.offer.fees.fees, [variantId, 'net'], null);

const getFeesByVariantId = (state: RootState, variantId: string): OfferFee | null =>
  get(state.entities.offer.fees.fees, [variantId], null);

export const offerFeesSelectors = {
  getAllFees,
  getFeeGrossByVariantId,
  getFeeNetByVariantId,
  getFeesByVariantId,
};
