import { template } from 'lodash';

export enum ROUTES {
  offer,
  notFound,
}

class RouteBuilderFactory {
  public ROUTES_TEMPLATES_URL: Record<ROUTES, string> = {
    [ROUTES.offer]: '/offer/<%= businessId %>',
    [ROUTES.notFound]: '/nicht-gefunden',
  };

  private businessId: string = '';

  setBusinessId(businessId: string) {
    this.businessId = businessId;
  }

  build(templateKey: ROUTES, templateParams = {}): string {
    const params = {
      businessId: this.businessId,
      ...templateParams,
    };

    const templateUrl = this.ROUTES_TEMPLATES_URL[templateKey];
    return template(templateUrl)(params);
  }
}

const routeBuilderFactory = new RouteBuilderFactory();
export const buildRoute = routeBuilderFactory.build.bind(routeBuilderFactory);
export default routeBuilderFactory;
