import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  agencySelectors,
  offerGeneralActions,
  offerGeneralSelectors,
  offerSelectors,
  personsSelectors,
  SalesProcess,
} from '../../store/entities';
import { RootState } from '../../store/store.types';
import { StickySidebar as StickySidebarComponent } from './StickySidebar';
import { StickySidebarDispatchProps, StickySidebarStateProps } from './StickySidebar.types';
import { accordionActions, accordionSelectors } from '../Accordions';

const mapStateToProps = (state: RootState): StickySidebarStateProps => ({
  // error messages component
  insuranceOwnerMicroPDSErrorTextMessages:
    personsSelectors.getInsuranceOwnerValidationErrors(state),
  offerErrorTextMessages: offerSelectors.getGenericAndSelectedVariantOfferErrorTextMessages(state),

  paymentMethod: offerSelectors.getSelectedVariantPaymentMethod(state),
  fee: offerSelectors.getSelectedVariantGrossPrice(state),

  // sales process component
  salesProcess: String(offerGeneralSelectors.getSalesProcess(state) || '') as SalesProcess,

  // statics component
  actionNumber: offerGeneralSelectors.getActionNr(state),
  oeNr: agencySelectors.getOeNr(state),
  employeeNr: offerGeneralSelectors.getCreatorNr(state),
  salesProduct: offerGeneralSelectors.getProductName(state),
  salesModel: String(offerGeneralSelectors.getSalesModel(state) || ''),
  offerStatus: offerGeneralSelectors.getOfferStatus(state),
  businessId: offerGeneralSelectors.getBusinessId(state),
  isProcessingFlow: offerGeneralSelectors.isProcessingFlow(state),

  // submit component
  isFinalizeOrderRequestInProgress: false,
  isAnyOfferRequestInProgress: offerSelectors.isAnyOfferRequestInProgress(state),
  isReadOnly: offerGeneralSelectors.isReadOnly(state),
  hasAtLeastOneAccordeonOpened: accordionSelectors.isAtLeastOneAccordionOpened(state),
});

const mapDispatchToProps = (dispatch: Dispatch): StickySidebarDispatchProps => ({
  finalizeOrder: () => dispatch(offerGeneralActions.finaliseOffer.trigger()),
  toggleAllAccordions: () => dispatch(accordionActions.toggleAllAccordions()),
});

export const StickySidebar = connect(mapStateToProps, mapDispatchToProps)(StickySidebarComponent);
