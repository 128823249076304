import { PersonalDataPayload } from '@disorder/personal-data-models';
import { ActionsUnion, createAction } from '../../../services';

export enum PersonsActionsTypes {
  PERSON_CHANGE = '[entities][persons] PERSON_CHANGE',
  UPDATE_INSURANCE_OWNER_BIRTHDATE = '[entities][persons] UPDATE_INSURANCE_OWNER_BIRTHDATE',
}

const personChange = (personPayload: PersonalDataPayload) =>
  createAction(PersonsActionsTypes.PERSON_CHANGE, personPayload);

const updateInsuranceOwnerBirthdate = (birthdate: string) =>
  createAction(PersonsActionsTypes.UPDATE_INSURANCE_OWNER_BIRTHDATE, birthdate);

const actions = {
  personChange,
  updateBirthdate: updateInsuranceOwnerBirthdate,
};

const asyncActions = {};

export const personsActions = {
  ...actions,
  ...asyncActions,
};

export type PersonsActions = ActionsUnion<typeof actions>;
