import React, { useEffect, useState } from 'react';
import Radio from '@eg/elements/Radio';
import { DurationOfContractProps } from './DurationOfContract.types';
import { DurationOfContractTooltip } from './DurationOfContractTooltip';
import { Field, RadioGroup } from '../../../../components';
import { OfferFieldValue } from '../../../../store/entities';
import { OfferTable } from '../../../../components/OfferTable';
import styles from './DurationOfContract.module.scss';

export const DurationOfContract: React.FC<DurationOfContractProps> = ({
  hasUpdateFieldsRequestFailed,
  isAnyOfferRequestInProgress,
  options,
  update,
  value,
}) => {
  const hasOptions = !!options.length;
  const disabledField = isAnyOfferRequestInProgress || !hasOptions;
  const [selectedValue, setSelectedValue] = useState<OfferFieldValue>(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [hasUpdateFieldsRequestFailed, value]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSelectedValue(value);
    update(value);
  };

  return (
    <OfferTable.Row
      className={styles.root}
      label='Vertragsdauer'
      tooltip={DurationOfContractTooltip}
    >
      <Field className={styles.field} disabled={disabledField}>
        <RadioGroup className={styles.radioGroup} value={selectedValue} onChange={onChangeHandler}>
          {!hasOptions && <Radio className={styles.radio} label='-' value='EMPTY' />}
          {options.map((option) => (
            <Radio
              className={styles.radio}
              value={option.value}
              label={option.label}
              key={`duration.of.contract.option.${option.value}`}
            />
          ))}
        </RadioGroup>
      </Field>
    </OfferTable.Row>
  );
};
