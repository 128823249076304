import React, { useEffect, useState } from 'react';
import Checkbox from '@eg/elements/Checkbox';
import { Field, OfferTable } from '../../../../components';
import { UnpaidDebtProps } from './UnpaidDebt.types';
import { UnpaidDebtTooltip } from './UnpaidDebtTooltip';
import styles from './UnpaidDebt.module.scss';

export const UnpaidDebt: React.FC<UnpaidDebtProps> = ({
  hasUpdatePackagesRequestFailed,
  isAnyOfferRequestInProgress,
  select,
  selected,
}) => {
  const [checked, setChecked] = useState<boolean>(selected);

  useEffect(() => {
    setChecked(selected);
  }, [hasUpdatePackagesRequestFailed, selected]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    setChecked(checked);
    select(checked);
  };

  return (
    <OfferTable.Row className={styles.root} label='Forderungsausfall' tooltip={UnpaidDebtTooltip}>
      <Field className={styles.field} disabled={isAnyOfferRequestInProgress}>
        <Checkbox label='' checked={checked} onChange={onChangeHandler} />
      </Field>
    </OfferTable.Row>
  );
};
