export const labels = {
  actionName: 'Akt-Name',
  actionNumber: 'Akt-Nr',
  agentDocumentId: 'AgDokID',
  businessId: 'Business ID',
  employeeNr: 'Mitarbeiter-ID',
  oeNr: 'OE-Nr',
  offerStatus: 'Status',
  salesModel: 'Vertriebsmodell',
  salesProduct: 'Verkaufsprodukt',
};
