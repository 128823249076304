import { DEFAULT_HTTP_HEADERS } from '../config';

class HeaderBuilderFactory {
  private businessId: string = '';

  setBusinessId(businessId: string) {
    this.businessId = businessId;
  }

  build() {
    const headers = DEFAULT_HTTP_HEADERS;

    if (this.businessId) {
      headers['X-Correlation-Id'] = this.businessId;
    }

    return headers;
  }
}

const headerBuilderFactory = new HeaderBuilderFactory();
export const buildDefaultHeaders = headerBuilderFactory.build.bind(headerBuilderFactory);
export default headerBuilderFactory;
