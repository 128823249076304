import { combineEpics, Epic, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { AppActionsTypes } from '../../store/entities';
import { fatalErrorModalActions } from './FatalErrorModal.actions';
import { RootActions, RootState } from '../../store/store.types';

export const onFatalError: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(AppActionsTypes.GET_FULL_OFFER_FAILURE),
    map(() => fatalErrorModalActions.open())
  );

export const fatalErrorModalEpics = combineEpics(onFatalError);
