import { map, distinctUntilChanged, skip, filter } from 'rxjs/operators';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { personsActions, PersonsActionsTypes } from './persons.actions';
import { personsSelectors } from './persons.selectors';
import { RootActions, RootState } from '../../store.types';

export const onInsuranceOwnerBirthdateChange: Epic<RootActions, RootActions, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    ofType(PersonsActionsTypes.PERSON_CHANGE),
    filter(
      (action) =>
        action.payload.person.personId === personsSelectors.getInsuranceOwnerId(state$.value)
    ),
    distinctUntilChanged(
      (previousAction, currentAction) =>
        previousAction.payload.person.birthDate === currentAction.payload.person.birthDate
    ),
    // skiping first request - when loading info from PDS
    skip(1),
    map((action) => personsActions.updateBirthdate(action.payload.person.birthDate))
  );

export const personsEpics = combineEpics(onInsuranceOwnerBirthdateChange);
