import { agencyHelper } from './agency.helper';
import { AgencyState } from './agency.types';
import { AppActions, AppActionsTypes } from '../app';

const defaultState: Readonly<AgencyState> = {
  id: '',
  oeNr: '',
  agentName: '',
  agentNumber: '',
  distributionChannel: '',
  departmentNumber: '',
  companyNumber: '',
};

export const agencyReducer = (
  state: AgencyState = defaultState,
  action: AppActions
): AgencyState => {
  switch (action.type) {
    case AppActionsTypes.GET_FULL_OFFER_SUCCESS:
      return {
        ...state,
        ...agencyHelper.mapAgencyBFFToAgencyState(action.payload.agency),
      };

    default:
      return state;
  }
};
