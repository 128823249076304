import { buildEndpoint, ENDPOINTS } from '../../services';

const getRelayState = (): string => {
  const url = new URL(window.location.href);
  const [context, businessId] = url.pathname.replace(/^\//, '').split('/');
  return encodeURIComponent(`context=${context}&businessId=${businessId}`);
};

const redirectToLoginPage = (): void => {
  window.location.href = buildEndpoint(ENDPOINTS.login, {
    RelayState: getRelayState(),
  });
};

export const UnauthorizedModalHelper = {
  redirectToLoginPage,
};
