import React from 'react';
interface FieldErrorProps {
  errors: string[];
}

export const FieldError: React.FC<FieldErrorProps> = ({ errors }) => {
  return (
    <>
      {errors.map((err, index) => (
        <div key={`$error-${err}-${index}`}>{err}</div>
      ))}
    </>
  );
};
