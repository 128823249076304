import { AjaxError } from 'rxjs/ajax';
import { ActionsUnion, createAsyncAction } from '../../../../services';
import {
  FinaliseOfferApiResponse,
  UpdateBaseApiFailureContext,
  UpdateBaseApiRequest,
  UpdateBaseApiResponse,
  UpdateBirthdateApiRequest,
  UpdateBirthdateApiResponse,
} from './general.types';

export enum OfferGeneralActionsTypes {
  UPDATE_BASE_TRIGGER = '[entities][offer][general] UPDATE_BASE_TRIGGER',
  UPDATE_BASE_SUCCESS = '[entities][offer][general] UPDATE_BASE_SUCCESS',
  UPDATE_BASE_FAILURE = '[entities][offer][general] UPDATE_BASE_FAILURE',

  UPDATE_BIRTHDATE_TRIGGER = '[entities][offer][general] UPDATE_BIRTHDATE_TRIGGER',
  UPDATE_BIRTHDATE_SUCCESS = '[entities][offer][general] UPDATE_BIRTHDATE_SUCCESS',
  UPDATE_BIRTHDATE_FAILURE = '[entities][offer][general] UPDATE_BIRTHDATE_FAILURE',

  FINALISE_OFFER_TRIGGER = '[entities][offer][general] FINALISE_OFFER_TRIGGER',
  FINALISE_OFFER_SUCCESS = '[entities][offer][general] FINALISE_OFFER_SUCCESS',
  FINALISE_OFFER_FAILURE = '[entities][offer][general] FINALISE_OFFER_FAILURE',
}

const updateBaseField = createAsyncAction(
  OfferGeneralActionsTypes.UPDATE_BASE_TRIGGER,
  OfferGeneralActionsTypes.UPDATE_BASE_SUCCESS,
  OfferGeneralActionsTypes.UPDATE_BASE_FAILURE
)<
  UpdateBaseApiRequest,
  UpdateBaseApiResponse,
  AjaxError,
  void,
  void,
  UpdateBaseApiFailureContext
>();

const updateBirthdate = createAsyncAction(
  OfferGeneralActionsTypes.UPDATE_BIRTHDATE_TRIGGER,
  OfferGeneralActionsTypes.UPDATE_BIRTHDATE_SUCCESS,
  OfferGeneralActionsTypes.UPDATE_BIRTHDATE_FAILURE
)<UpdateBirthdateApiRequest, UpdateBirthdateApiResponse, AjaxError>();

const finaliseOffer = createAsyncAction(
  OfferGeneralActionsTypes.FINALISE_OFFER_TRIGGER,
  OfferGeneralActionsTypes.FINALISE_OFFER_SUCCESS,
  OfferGeneralActionsTypes.FINALISE_OFFER_FAILURE
)<void, FinaliseOfferApiResponse, AjaxError>();

/**
 * combining and exporting of sync & async actions
 */

const actions = {};

const asyncActions = {
  updateBaseField,
  updateBirthdate,
  finaliseOffer,
};

export const offerGeneralActions = {
  ...actions,
  ...asyncActions,
};

export type OfferGeneralActions = ActionsUnion<typeof actions, typeof asyncActions>;
