import React from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import { DatePicker as ErgoDatePicker } from '@eg/elements/DatePicker';
import { DatePickerProps } from './DatePicker.types';
import styles from './DatePicker.module.scss';

export const DatePicker: React.FC<DatePickerProps> = ({
  disabled = false,
  loading = false,
  placeholder = 'TT.MM.JJ',
  minDate,
  maxDate,
  onChange = noop,
  value,
}) => {
  return (
    <div className={styles.root}>
      <ErgoDatePicker
        className={classNames({
          [styles['datepicker--loading']]: loading,
        })}
        placeholder={placeholder}
        valueDate={value}
        disabled={disabled}
        onChange={onChange}
        {...(!!minDate && { minDate })}
        {...(!!maxDate && { maxDate })}
      />
    </div>
  );
};
