import { ApiRequestStatus } from '../../../../types';
import { AppActions, AppActionsTypes } from '../../app';
import { OfferPaymentState } from './paymet.types';
import { OfferPaymentActions, OfferPaymentActionsTypes } from './payment.actions';
import { OfferRoleBFF, RoleType } from '../offer.types';

const defaultState: Readonly<OfferPaymentState> = {
  error: null,
  updatePaymentStatus: ApiRequestStatus.idle,
  paymentId: '',
};

const getPaymentId = (roles: OfferRoleBFF[]) =>
  roles?.find(({ role }) => role === RoleType.CONTRIBUTION_PAYER)?.paymentId || '';

export const offerPaymentReducer = (
  state: OfferPaymentState = defaultState,
  action: AppActions | OfferPaymentActions
): OfferPaymentState => {
  switch (action.type) {
    case AppActionsTypes.GET_FULL_OFFER_SUCCESS: {
      return {
        ...state,
        paymentId: getPaymentId(action.payload.offer?.roles),
      };
    }
    case OfferPaymentActionsTypes.UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentId: getPaymentId(action.payload.offer?.roles),
        updatePaymentStatus: ApiRequestStatus.succeeded,
      };

    case OfferPaymentActionsTypes.UPDATE_PAYMENT_TRIGGER:
      return {
        ...state,
        updatePaymentStatus: ApiRequestStatus.pending,
      };

    case OfferPaymentActionsTypes.UPDATE_PAYMENT_FAILURE:
      return {
        ...state,
        updatePaymentStatus: ApiRequestStatus.failed,
        error: action.payload,
      };

    default:
      return state;
  }
};
