import { combineReducers } from 'redux';
import {
  accordionsReducer,
  agreementsReducer,
  fatalErrorModalReducer,
  retryLastActionModalReducer,
  unauthorizedModalReducer,
  updateBirthdateErrorModalReducer,
} from '../features';

export const ui = combineReducers({
  agreements: agreementsReducer,
  fatalErrorModal: fatalErrorModalReducer,
  retryLastActionModal: retryLastActionModalReducer,
  updateBirthdateErrorModal: updateBirthdateErrorModalReducer,
  accordions: accordionsReducer,
  unauthorizedModal: unauthorizedModalReducer,
});
