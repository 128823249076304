import React, { useState, useCallback } from 'react';
import { noop } from 'lodash';

export const useFieldTouched = (element?: React.ReactNode) => {
  const [touched, setTouched] = useState(false);

  const onBlurHandler = useCallback(
    (evt: React.FocusEvent<HTMLInputElement>) => {
      const fieldBlurCallback =
        (React.isValidElement(element) &&
          (element.props as React.HTMLProps<HTMLInputElement>).onBlur) ||
        noop;

      setTouched(true);
      fieldBlurCallback(evt);
    },
    [setTouched, element]
  );

  return { touched, onBlurHandler };
};
