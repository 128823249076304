import React from 'react';
import { labels } from './labels';
import { PaymentProps } from './Payment.types';
import styles from './Payment.module.scss';

export const Payment: React.FC<PaymentProps> = ({ fee, formatCurrency, paymentMethod }) => {
  const formattedFee = formatCurrency(String(fee));

  return (
    <div className={styles.root}>
      <span className={styles.label}>Beitrag Gesamt:</span>
      <span>
        <strong>{formattedFee}</strong>
      </span>
      {!!paymentMethod && (
        <span className={styles.paymentMethod}>{`/ ${labels[paymentMethod]}`}</span>
      )}
    </div>
  );
};
