import { createSelector } from 'reselect';
import { uniq, uniqBy } from 'lodash';
import { Agreement, OfferAgreement, offerAgreementsSelectors } from './agreements';
import { OfferFee, offerFeesSelectors } from './fees';
import { OfferFieldsIds, offerFieldsSelectors, PaymentMethod } from './fields';
import { offerGeneralSelectors } from './general';
import { offerPackagesSelectors } from './packages';
import { offerVariantsSelectors } from './variants';
import { RootState } from '../../store.types';
import { OfferMessage, offerMessagesSelectors } from './messages';
import { OfferMessageSeverity } from './offer.types';

const getGenericAndSelectedVariantOfferErrorTextMessages = createSelector<
  RootState,
  OfferMessage[],
  string | null,
  string[]
>(
  offerMessagesSelectors.getAllMessages,
  offerVariantsSelectors.getSelectedId,
  (allMessages, selectedVariantId) => {
    const errorMessages = allMessages.filter((message) => {
      const isError = message.severity === OfferMessageSeverity.error;
      const isGeneric = !message.variantId;
      const isFromSelectedVariantId = message.variantId === selectedVariantId;
      const isToBeDisplayed = isGeneric || isFromSelectedVariantId;

      return isError && isToBeDisplayed;
    });

    const errorTexts = uniqBy(errorMessages, 'code').map(
      (message) => message.text || message.code || ''
    );

    return uniq(errorTexts);
  }
);

const getSelectedVariantAgreements = createSelector<
  RootState,
  Record<OfferAgreement['variantId'], Agreement[]>,
  string | null,
  Agreement[]
>(
  offerAgreementsSelectors.getAllAgreements,
  offerVariantsSelectors.getSelectedId,
  (agreementsByVariantId, selectedVariantId) => {
    if (!selectedVariantId) {
      return [];
    }

    return agreementsByVariantId[selectedVariantId];
  }
);

const hasSelectedVariantAgreements = createSelector<RootState, Agreement[], boolean>(
  getSelectedVariantAgreements,
  (agreements) => !!agreements.length
);

const getSelectedVariantGrossPrice = createSelector<
  RootState,
  Record<OfferFee['variantId'], OfferFee>,
  string | null,
  number | null
>(
  offerFeesSelectors.getAllFees,
  offerVariantsSelectors.getSelectedId,
  (feesByVariantId, selectedVariantId) => {
    if (!selectedVariantId) {
      return null;
    }

    return feesByVariantId[selectedVariantId]?.gross ?? null;
  }
);

const isVariantPriceAvailable = (state: RootState) => !!getSelectedVariantGrossPrice(state);

const getSelectedVariantPaymentMethod = (state: RootState): PaymentMethod => {
  const selectedVariantId = offerVariantsSelectors.getSelectedId(state);

  if (!selectedVariantId) {
    return '' as PaymentMethod;
  }

  return (offerFieldsSelectors.getField(state, selectedVariantId, OfferFieldsIds.PAYMENT_METHOD)
    ?.value ?? '') as PaymentMethod;
};

const isAnyOfferRequestInProgress = createSelector<
  RootState,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean
>(
  offerAgreementsSelectors.isUpdateAgreementsRequestInprogress,
  offerGeneralSelectors.isUpdateBaseRequestInprogress,
  offerGeneralSelectors.isUpdateBirthdateRequestInprogress,
  offerGeneralSelectors.isFinaliseOfferRequestInprogress,
  offerFieldsSelectors.isUpdateFieldsRequestInprogress,
  offerPackagesSelectors.isUpdatePackagesRequestInprogress,
  offerVariantsSelectors.isSelectVariantRequestInprogress,
  (
    isUpdateAgreementsRequestInprogress,
    isUpdateBaseRequestInprogress,
    isUpdateBirthdateRequestInprogress,
    isFinaliseOfferRequestInprogress,
    isUpdateFieldsRequestInprogress,
    isUpdatePackagesRequestInprogress,
    isSelectVariantRequestInprogress
  ) =>
    isUpdateAgreementsRequestInprogress ||
    isUpdateBaseRequestInprogress ||
    isUpdateBirthdateRequestInprogress ||
    isFinaliseOfferRequestInprogress ||
    isUpdateFieldsRequestInprogress ||
    isUpdatePackagesRequestInprogress ||
    isSelectVariantRequestInprogress
);

export const offerSelectors = {
  getGenericAndSelectedVariantOfferErrorTextMessages,
  getSelectedVariantAgreements,
  getSelectedVariantGrossPrice,
  isVariantPriceAvailable,
  getSelectedVariantPaymentMethod,
  hasSelectedVariantAgreements,
  isAnyOfferRequestInProgress,
};
