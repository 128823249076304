import { useEffect, useState, useRef } from 'react';
import { buildEndpoint, ENDPOINTS } from '../../services';
import styles from './RefreshUserToken.module.scss';

export const RefreshUserToken: React.FC = () => {
  const REFRESH_INTERVAL_IN_MILISECS = 15000; // 15 seconds
  const intervalTime = useRef<number>(REFRESH_INTERVAL_IN_MILISECS);
  const [key, setKey] = useState<number>(0);
  const iframeSrc = buildEndpoint(ENDPOINTS.refreshUserToken);

  useEffect(() => {
    const interval = setInterval(() => {
      setKey((key) => {
        const nextKey = key + 1;
        return Number.isSafeInteger(nextKey) ? nextKey : 0;
      });
    }, intervalTime.current);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <iframe
      data-testid={`refresh-user-token-${key}`}
      className={styles.root}
      title='refresh-user-token'
      src={iframeSrc}
      key={key}
    />
  );
};
