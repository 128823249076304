export const PaymentMethodTooltip = (
  <>
    <p>
      Je nach Zahlungsweise enthält Ihr Beitrag diese <strong>Ratenzahlungszuschläge</strong>:
    </p>
    <ul>
      <li>Monatlich: 6 %</li>
      <li>Vierteljährlich: 5 %</li>
      <li>Halbjährlich: 3 %</li>
      <li>Jährlich: Kein Zuschlag</li>
      <li>
        <strong>Achtung:</strong> Die monatliche Zahlungsweise ist nur im Lastschriftverfahren
        möglich
      </li>
    </ul>
  </>
);
