import React, { useEffect, useState } from 'react';
import { BundleLoader } from '@disorder/microfrontend-bundle-loader';
import { Radio } from '@eg/elements/Radio';
import { Loading } from '../../../../components';
import {
  CONFIG_PERSONAL_DATA_BACKEND_URL,
  CONFIG_PERSONAL_DATA_BUNDLE_URL,
} from '../../../../config';
import {
  INSURED_OFFICIALS_OPTIONS,
  isInsuredOfficialsOption,
  MaritalStatus,
  OfficialAndPublicLiabilityPersonProps,
} from './OfficialAndPublicLiabilityPerson.types';
import { PersonFieldName } from '@disorder/personal-data-models';
import styles from './OfficialAndPublicLiabilityPerson.module.scss';
import { RadioGroupRow } from '@eg/elements/RadioGroupRow';

export const OfficialAndPublicLiabilityPerson: React.FC<OfficialAndPublicLiabilityPersonProps> = ({
  businessId,
  hasUpdatePackagesRequestFailed,
  isAnyOfferRequestInProgress,
  insuredPersonsIds,
  setInsuredPersons,
  insuranceOwnerId,
  insuranceOwnerPartnerId,
  maritalStatus,
}) => {
  const [selectedOption, setSelectedOption] = useState<INSURED_OFFICIALS_OPTIONS | undefined>(
    undefined
  );

  const customProps = {
    appId: 'phv-tof-insurance-owner-partner',
    businessId: businessId,
    personId: insuranceOwnerPartnerId,
    backendUrl: CONFIG_PERSONAL_DATA_BACKEND_URL,
    visibleFields: [
      PersonFieldName.SALUTATION,
      PersonFieldName.FIRST_NAME,
      PersonFieldName.LAST_NAME,
    ],
    nullAddress: true,
  };

  const isMaritalStatusASingleAdult = [MaritalStatus.SINGLE, MaritalStatus.SINGLE_PARENT].includes(
    maritalStatus
  );
  const isInsuranceOwnerPartnerInsured = insuredPersonsIds?.includes(insuranceOwnerPartnerId);

  // when the marital status is updated
  useEffect(() => {
    if (isInsuranceOwnerPartnerInsured && isMaritalStatusASingleAdult) {
      setInsuredPersons([insuranceOwnerId]);
    }
  }, [
    insuranceOwnerId,
    isInsuranceOwnerPartnerInsured,
    isMaritalStatusASingleAdult,
    setInsuredPersons,
  ]);

  useEffect(() => {
    const isInsuranceOwnerInsured = insuredPersonsIds?.includes(insuranceOwnerId);
    if (isInsuranceOwnerInsured && isInsuranceOwnerPartnerInsured) {
      setSelectedOption(INSURED_OFFICIALS_OPTIONS.ME_AND_PARTNER);
    } else {
      setSelectedOption(INSURED_OFFICIALS_OPTIONS.ME);
    }
  }, [
    hasUpdatePackagesRequestFailed,
    insuredPersonsIds,
    insuranceOwnerId,
    isInsuranceOwnerPartnerInsured,
  ]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedOption = event.target.value;

    if (isInsuredOfficialsOption(newSelectedOption)) {
      setSelectedOption(newSelectedOption);

      if (newSelectedOption === INSURED_OFFICIALS_OPTIONS.ME) {
        setInsuredPersons([insuranceOwnerId]);
      } else if (newSelectedOption === INSURED_OFFICIALS_OPTIONS.ME_AND_PARTNER) {
        setInsuredPersons([insuranceOwnerId, insuranceOwnerPartnerId]);
      }
    }
  };

  if (isMaritalStatusASingleAdult) {
    return null;
  }

  return (
    <div className={styles.root}>
      <RadioGroupRow
        className={styles.radioGroup}
        value={selectedOption || ''}
        onChange={onChangeHandler}
        disabled={isAnyOfferRequestInProgress}
        name='official-and-public-liability-persons-radio-group'
        label=''
      >
        <Radio value={INSURED_OFFICIALS_OPTIONS.ME} label='Für mich' className={styles.radio} />
        <Radio
          className={styles.radio}
          value={INSURED_OFFICIALS_OPTIONS.ME_AND_PARTNER}
          label='Für meinen Partner und mich'
        />
      </RadioGroupRow>
      {isInsuranceOwnerPartnerInsured && (
        <div className={styles.personalDataForm}>
          <Loading loading={isAnyOfferRequestInProgress}>
            <BundleLoader bundleUrl={CONFIG_PERSONAL_DATA_BUNDLE_URL} customProps={customProps} />
          </Loading>
        </div>
      )}
    </div>
  );
};
