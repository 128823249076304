import { ActionsUnion, createAsyncAction } from '../../../../services';
import { AjaxError } from 'rxjs/ajax';
import { UpdatePaymentRequest, UpdatePaymentResponse } from './paymet.types';

export enum OfferPaymentActionsTypes {
  UPDATE_PAYMENT_TRIGGER = '[entities][offer][payment] UPDATE_PAYMENT_TRIGGER',
  UPDATE_PAYMENT_SUCCESS = '[entities][offer][payment] UPDATE_PAYMENT_SUCCESS',
  UPDATE_PAYMENT_FAILURE = '[entities][offer][payment] UPDATE_PAYMENT_FAILURE',
}

const updatePayment = createAsyncAction(
  OfferPaymentActionsTypes.UPDATE_PAYMENT_TRIGGER,
  OfferPaymentActionsTypes.UPDATE_PAYMENT_SUCCESS,
  OfferPaymentActionsTypes.UPDATE_PAYMENT_FAILURE
)<UpdatePaymentRequest, UpdatePaymentResponse, AjaxError>();

const actions = {};

const asyncActions = {
  updatePayment,
};

export const offerPaymentActions = {
  ...asyncActions,
  ...actions,
};

export type OfferPaymentActions = ActionsUnion<typeof actions, typeof asyncActions>;
