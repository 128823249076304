import React, { useEffect } from 'react';
import { UnmanagedDetails } from '@eg/elements/components/UnmanagedDetails';
import { AccordionProps } from './Accordion.types';

export const AccordionComponent: React.FC<AccordionProps> = ({
  children,
  summary,
  isOpen = true,
  toggle,
  register,
  unregister,
}) => {
  useEffect(() => {
    register();
    return function cleanup() {
      unregister();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UnmanagedDetails summary={summary} isOpen={isOpen} onToggleOpen={toggle}>
      {children}
    </UnmanagedDetails>
  );
};
