import { get } from 'lodash';
import { OfferBFF, OfferVariantBFF, OfferVariantPackageBFF } from '../offer.types';
import {
  OfferPackage,
  OfferPackagesIds,
  OfferPackagesState,
  OfferVariantPackages,
} from './packages.types';

const mapVariantsPackages = (offer: OfferBFF): OfferPackagesState['packagesByVariantId'] => {
  return get<OfferBFF, 'variants', OfferVariantBFF[]>(offer, 'variants', []).reduce(
    (acc: OfferPackagesState['packagesByVariantId'], variant: OfferVariantBFF) => {
      acc[variant.id] = {
        ids: get<OfferVariantBFF, 'packages', OfferVariantPackageBFF[]>(
          variant,
          'packages',
          []
        ).map((packageObj) => packageObj.id as OfferPackagesIds),
        packagesById: get<OfferVariantBFF, 'packages', OfferVariantPackageBFF[]>(
          variant,
          'packages',
          []
        ).reduce((acc: OfferVariantPackages['packagesById'], packageObj) => {
          acc[packageObj.id as OfferPackagesIds] = packageObj as OfferPackage;
          return acc;
        }, {}),
      };

      return acc;
    },
    {}
  );
};

export const offerPackagesHelper = {
  mapVariantsPackages,
};
