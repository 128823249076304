import { WithPackageInsuredPersonsIdsProps } from '../WithPackageInsuredPersonsIds';
import { OfferFieldValue } from '../../../../store/entities';

export interface OfficialAndPublicLiabilityPersonStateProps {
  hasUpdatePackagesRequestFailed: boolean;
  insuranceOwnerId: string;
  insuranceOwnerPartnerId: string;
  maritalStatus: MaritalStatus;
}

export enum MaritalStatus {
  SINGLE = 'SINGLE',
  FAMILY = 'FAMILIE',
  DUO = 'DUO',
  SINGLE_PARENT = 'ALLEINERZIEHENDER',
}

export const isMaritalStatusValue = (option: OfferFieldValue): option is MaritalStatus =>
  Object.values(MaritalStatus).includes(option as MaritalStatus);

export interface OfficialAndPublicLiabilityPersonProps
  extends OfficialAndPublicLiabilityPersonStateProps,
    WithPackageInsuredPersonsIdsProps {}

export enum INSURED_OFFICIALS_OPTIONS {
  ME = 'ME',
  ME_AND_PARTNER = 'ME_AND_PARTNER',
}

export const isInsuredOfficialsOption = (option: string): option is INSURED_OFFICIALS_OPTIONS =>
  Object.values(INSURED_OFFICIALS_OPTIONS).includes(option as INSURED_OFFICIALS_OPTIONS);
