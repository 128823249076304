import React, { useEffect, useState } from 'react';
import Checkbox from '@eg/elements/Checkbox';
import { Field, OfferTable } from '../../../../components';
import { MonoparentalFamilyProps } from './MonoparentalFamily.types';
import { MonoparentalFamilyTooltip } from './MonoparentalFamilyTooltip';
import { MonoparentalFamilyPerson } from '../MonoparentalFamilyPerson';
import styles from './MonoparentalFamily.module.scss';

export const MonoparentalFamily: React.FC<MonoparentalFamilyProps> = ({
  variantId,
  packageId,
  hasUpdatePackagesRequestFailed,
  isAnyOfferRequestInProgress,
  select,
  selected,
}) => {
  const [checked, setChecked] = useState<boolean>(selected);

  useEffect(() => {
    setChecked(selected);
  }, [hasUpdatePackagesRequestFailed, selected]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    setChecked(checked);
    select(checked);
  };

  return (
    <>
      <OfferTable.Row
        className={styles.root}
        label='Alleinstehender Familienangehöriger'
        tooltip={MonoparentalFamilyTooltip}
      >
        <Field className={styles.field} disabled={isAnyOfferRequestInProgress}>
          <Checkbox label='' checked={checked} onChange={onChangeHandler} />
        </Field>
      </OfferTable.Row>
      {checked && <MonoparentalFamilyPerson variantId={variantId} packageId={packageId} />}
    </>
  );
};
