import { get } from 'lodash';
import { OfferBFF, OfferVariantBFF } from '../offer.types';
import { OfferVariantsState } from './variants.types';

const mapIds = (offer: OfferBFF): OfferVariantsState['ids'] => {
  return get<OfferBFF, 'variants', OfferVariantBFF[]>(offer, 'variants', []).map(
    (variant: OfferVariantBFF) => variant.id
  );
};

const mapVariantsById = (offer: OfferBFF): OfferVariantsState['variantsById'] => {
  const VARIANTS_NAMES: Record<string, string> = {
    Smart: 'Smart (10 Millionen)',
    Best: 'Best (50 Millionen)',
  };

  return get<OfferBFF, 'variants', OfferVariantBFF[]>(offer, 'variants', []).reduce(
    (acc: OfferVariantsState['variantsById'], variant: OfferVariantBFF) => {
      const { id, selected } = variant;
      acc[variant.id] = {
        id,
        selected,
        name: VARIANTS_NAMES[variant.id],
      };

      return acc;
    },
    {}
  );
};

const getSelectedId = (offer: OfferBFF): string | null => {
  return (
    get<OfferBFF, 'variants', OfferVariantBFF[]>(offer, 'variants', []).find(
      (variant: OfferVariantBFF) => variant.selected
    )?.id ?? null
  );
};

export const offerVariantsHelper = {
  mapIds,
  mapVariantsById,
  getSelectedId,
};
