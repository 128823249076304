import React, { useEffect, useState } from 'react';
import { BundleLoader } from '@disorder/microfrontend-bundle-loader';
import { PersonFieldName } from '@disorder/personal-data-models';
import { Loading } from '../../../../components';
import { Radio } from '@eg/elements/Radio';
import {
  CONFIG_PERSONAL_DATA_BACKEND_URL,
  CONFIG_PERSONAL_DATA_BUNDLE_URL,
} from '../../../../config';
import {
  MonoparentalFamilyPersonProps,
  FamilyMembers,
  isFamilyMembersOption,
} from './MonoparentalFamilyPerson.types';
import messages from './MonoparentalFamilyPerson.labels';
import styles from './MonoparentalFamilyPerson.module.scss';
import { RadioGroupRow } from '@eg/elements/RadioGroupRow';

const mapPersonalDataProps = (businessId: string, personId: string) => ({
  appId: `phv-tof-insurance-owner-family${personId}`,
  businessId: businessId,
  personId: personId,
  backendUrl: CONFIG_PERSONAL_DATA_BACKEND_URL,
  visibleFields: [
    PersonFieldName.SALUTATION,
    PersonFieldName.FIRST_NAME,
    PersonFieldName.LAST_NAME,
  ],
  nullAddress: true,
});

export const MonoparentalFamilyPerson: React.FC<MonoparentalFamilyPersonProps> = ({
  businessId,
  hasUpdatePackagesRequestFailed,
  setInsuredPersons,
  familyMembersIds,
  isAnyOfferRequestInProgress,
  insuredPersonsIds,
}) => {
  const [selectedOption, setSelectedOption] = useState<FamilyMembers | number>(
    insuredPersonsIds?.length || FamilyMembers.One
  );
  const [insuredFamilyMembers, setInsuredFamilyMembers] = useState<string[]>(
    insuredPersonsIds?.length ? insuredPersonsIds : familyMembersIds.slice(0, 1)
  );

  useEffect(() => {
    setSelectedOption(insuredPersonsIds?.length || FamilyMembers.One);
    setInsuredFamilyMembers(
      insuredPersonsIds?.length ? insuredPersonsIds : familyMembersIds.slice(0, 1)
    );
  }, [familyMembersIds, hasUpdatePackagesRequestFailed, insuredPersonsIds]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changeValue = parseInt(event.target.value);
    if (isFamilyMembersOption(changeValue)) {
      const familyPersons = familyMembersIds.slice(0, changeValue);
      setSelectedOption(changeValue);
      setInsuredFamilyMembers(familyPersons);
      setInsuredPersons(familyPersons);
    }
  };

  return (
    <div className={styles.root}>
      <RadioGroupRow
        className={styles.radioGroup}
        value={selectedOption || ''}
        onChange={onChangeHandler}
        disabled={isAnyOfferRequestInProgress}
        name='monoparental-family-persons-radio-group'
        label=''
      >
        <Radio value={FamilyMembers.One} label={messages.onePerson} className={styles.radio} />
        <Radio className={styles.radio} value={FamilyMembers.Two} label={messages.twoPersons} />
        <Radio className={styles.radio} value={FamilyMembers.Three} label={messages.threePersons} />
      </RadioGroupRow>
      {insuredFamilyMembers.map((insuredId) => (
        <div className={styles.personalDataForm} key={insuredId}>
          <Loading loading={isAnyOfferRequestInProgress}>
            <BundleLoader
              bundleUrl={CONFIG_PERSONAL_DATA_BUNDLE_URL}
              customProps={mapPersonalDataProps(businessId, insuredId)}
            />
          </Loading>
        </div>
      ))}
    </div>
  );
};
