import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { partial } from 'lodash';
import {
  offerFieldsActions,
  OfferFieldsIds,
  offerFieldsSelectors,
  OfferFieldValue,
} from '../../../../store/entities';
import { offerSelectors } from '../../../../store/entities/offer/offer.selectors';
import { RootState } from '../../../../store/store.types';
import {
  WithFieldBaseDispatchProps,
  WithFieldBaseMergeProps,
  WithFieldBaseOwnProps,
  WithFieldBaseStateProps,
} from './WithFieldBase.types';

const makeMapStateToProps = () => {
  const getFieldValue = offerFieldsSelectors.makeGetFieldValue();

  const mapStateToProps = (
    state: RootState,
    ownProps: WithFieldBaseOwnProps
  ): WithFieldBaseStateProps => {
    return {
      hasUpdateFieldsRequestFailed: offerFieldsSelectors.hasUpdateFieldsRequestFailed(state),
      isAnyOfferRequestInProgress: offerSelectors.isAnyOfferRequestInProgress(state),
      value: getFieldValue(state, ownProps.variantId, ownProps.fieldId),
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch: Dispatch): WithFieldBaseDispatchProps => ({
  update: (fieldId: OfferFieldsIds, value: OfferFieldValue) =>
    dispatch(
      offerFieldsActions.updateFields.trigger([
        {
          fieldId,
          value,
        },
      ])
    ),
});

const mergeProps = (
  stateProps: WithFieldBaseStateProps,
  dispatchProps: WithFieldBaseDispatchProps,
  ownProps: WithFieldBaseOwnProps
): WithFieldBaseMergeProps => ({
  ...ownProps,
  ...stateProps,
  update: (value: OfferFieldValue) => partial(dispatchProps.update, ownProps.fieldId)(value),
});

const connector = connect(makeMapStateToProps, mapDispatchToProps, mergeProps);

export const WithFieldBase = (Component: React.ComponentType<ConnectedProps<typeof connector>>) =>
  connector(Component);
