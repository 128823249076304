import { ApiRequestStatus } from '../../../../types';
import { AppActions, AppActionsTypes } from '../../app';
import { OfferFieldsActions, OfferFieldsActionsTypes } from '../fields';
import { OfferGeneralActions, OfferGeneralActionsTypes } from '../general';
import { OfferPackagesActions, OfferPackagesActionsTypes } from './packages.actions';
import { offerPackagesHelper } from './packages.helper';
import { OfferPackagesState } from './packages.types';
import { OfferVariantsActions, OfferVariantsActionsTypes } from '../variants';

const defaultState: Readonly<OfferPackagesState> = {
  error: null,
  updatePackagesStatus: ApiRequestStatus.idle,
  packagesByVariantId: {},
};

export const offerPackagesReducer = (
  state: OfferPackagesState = defaultState,
  action:
    | AppActions
    | OfferFieldsActions
    | OfferGeneralActions
    | OfferPackagesActions
    | OfferVariantsActions
): OfferPackagesState => {
  switch (action.type) {
    case AppActionsTypes.GET_FULL_OFFER_SUCCESS:
    case OfferFieldsActionsTypes.UPDATE_FIELDS_SUCCESS:
    case OfferGeneralActionsTypes.UPDATE_BIRTHDATE_SUCCESS:
    case OfferVariantsActionsTypes.SELECT_VARIANT_SUCCESS:
      return {
        ...state,
        packagesByVariantId: offerPackagesHelper.mapVariantsPackages(action.payload.offer),
      };

    case OfferPackagesActionsTypes.UPDATE_PACKAGES_TRIGGER:
      return {
        ...state,
        updatePackagesStatus: ApiRequestStatus.pending,
      };

    case OfferPackagesActionsTypes.UPDATE_PACKAGES_SUCCESS:
      return {
        ...state,
        error: null,
        updatePackagesStatus: ApiRequestStatus.succeeded,
        packagesByVariantId: offerPackagesHelper.mapVariantsPackages(action.payload.offer),
      };

    case OfferPackagesActionsTypes.UPDATE_PACKAGES_FAILURE:
      return {
        ...state,
        error: action.payload,
        updatePackagesStatus: ApiRequestStatus.failed,
      };

    default:
      return state;
  }
};
