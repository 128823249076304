import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RetryLastActionModal as RetryLastActionModalComponent } from './RetryLastActionModal';
import { retryLastActionModalActions } from './RetryLastActionModal.actions';
import {
  RetryLastActionModalDispatchProps,
  RetryLastActionModalStateProps,
} from './RetryLastActionModal.types';
import { retryLastActionModalSelectors } from './RetryLastActionModal.selectors';
import { RootState } from '../../store/store.types';

const mapStateToProps = (state: RootState): RetryLastActionModalStateProps => ({
  isOpened: retryLastActionModalSelectors.isOpened(state),
});

const mapDispatchToProps = (dispatch: Dispatch): RetryLastActionModalDispatchProps => ({
  close: () => dispatch(retryLastActionModalActions.close()),
});

export const RetryLastActionModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(RetryLastActionModalComponent);
