import React from 'react';
import Provider from '@eg/elements/Provider';
import { AppProps } from './App.types';
import { AppRouter } from './App.router';
import {
  FatalErrorModal,
  RetryLastActionModal,
  UpdateBirthdateErrorModal,
  ThankYouModal,
  UnauthorizedModal,
} from '../../features';
import styles from './App.module.scss';

export const App: React.FC<AppProps> = ({ isInitialized, isReadOnly }) => {
  return (
    <Provider className={styles.root} theme='ergo-one'>
      <AppRouter />
      <FatalErrorModal />
      <RetryLastActionModal />
      <UpdateBirthdateErrorModal />
      <UnauthorizedModal />
      {isInitialized && isReadOnly && <div className={styles.readOnly}>Schreibgeschützt</div>}
      <ThankYouModal />
    </Provider>
  );
};
