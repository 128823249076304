export const MaritalStatusTooltip = (
  <>
    <p>
      <strong>Single:</strong>
      <br /> Versichert sind Sie als Versicherungsnehmer.
    </p>

    <p>
      <strong>Alleinerziehend:</strong>
      <br /> Versichert sind Sie und Ihr Kind bzw. Ihre Kinder*.
    </p>

    <p>
      <strong>Paar:</strong>
      <br /> Versichert sind Sie und Ihr (Ehe-)Partner. Statt Ihres (Ehe-)Partners können Sie auch
      ein anderes Familienmitglied mitversichern.
    </p>

    <p>
      <strong>Familie:</strong>
      <br /> Versichert sind Sie und Ihr (Ehe-)Partner. Außerdem Ihre und seine Kinder*.
    </p>
    <p>&nbsp;</p>
    <div>
      <p>* Mitversicherte Kinder sind:</p>
      <ul>
        <li>Unverheiratete minderjährige Kinder (auch Stief-, Adoptiv-, Pflegekinder)</li>
        <li>
          Volljährige Kinder in Schule, Berufsausbildung, Studium, freiwilligem Wehrdienst, sozialem
          Jahr
        </li>
        <li>
          Volljährige Kinder während Arbeitslosigkeit nach Schule, Berufsausbildung, Studium,
          freiwilligem Wehrdienst, sozialem Jahr
        </li>
        <li>Volljährige Kinder in Wartezeiten zwischen den Ausbildungsabschnitten</li>
      </ul>
    </div>
  </>
);
