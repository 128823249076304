import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { appActions, appSelectors } from '../../store/entities';
import { InitApp as InitAppComponent } from './InitApp';
import { InitAppStateProps, InitAppDispatchProps } from './InitApp.types';
import { RootState } from '../../store/store.types';

const mapStateToProps = (state: RootState): InitAppStateProps => ({
  isAppInitialized: appSelectors.isInitialized(state),
  isInitializedWithSuccess: appSelectors.isInitializedWithSuccess(state),
});

const mapDispatchToProps = (dispatch: Dispatch): InitAppDispatchProps => ({
  getFullOffer: () => dispatch(appActions.getFullOffer.trigger()),
});

export const InitApp = withRouter(connect(mapStateToProps, mapDispatchToProps)(InitAppComponent));
