import { AppActions, AppActionsTypes } from './app.actions';
import { AppState, InitializationStatus } from './app.types';

const defaultState: Readonly<AppState> = {
  initializationStatus: InitializationStatus.idle,
  isInitialized: false,
};

export const appReducer = (state: AppState = defaultState, action: AppActions): AppState => {
  switch (action.type) {
    case AppActionsTypes.GET_FULL_OFFER_TRIGGER:
      return {
        ...state,
        isInitialized: false,
        initializationStatus: InitializationStatus.pending,
      };
    case AppActionsTypes.GET_FULL_OFFER_FAILURE:
      return {
        ...state,
        isInitialized: true,
        initializationStatus: InitializationStatus.failedWithServiceError,
      };
    case AppActionsTypes.GET_FULL_OFFER_SUCCESS:
      return {
        ...state,
        isInitialized: true,
        initializationStatus: InitializationStatus.succeeded,
      };

    default:
      return state;
  }
};
