import { flowRight } from 'lodash';
import { InsuranceStartDate as InsuranceStartDateComponent } from './InsuranceStartDate';
import { WithFieldBase } from '../WithFieldBase';
import { WithFieldMax } from '../WithFieldMax';
import { WithFieldMin } from '../WithFieldMin';
import { WithFieldOptions } from '../WithFieldOptions';

export const InsuranceStartDate = flowRight(
  WithFieldBase,
  WithFieldMax,
  WithFieldMin,
  WithFieldOptions
)(InsuranceStartDateComponent);
