import React, { ReactElement } from 'react';
import { isNil } from 'lodash';

interface InputFieldProps<Value extends any> {
  checked?: boolean;
  value?: Value;
}

export const useFieldValidation = <Value = any>(element: React.ReactNode) => {
  if (React.isValidElement(element)) {
    const { value, checked } = (element as ReactElement).props as InputFieldProps<Value>;

    return !isNil(value || checked);
  }

  return false;
};
