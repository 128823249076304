import { ApiRequestStatus } from '../../../../types';
import { Flow, SalesModel, SalesProcess } from '../offer.types';
import { RootState } from '../../../store.types';
import { OfferGeneralState } from './general.types';

const getBusinessId = (state: RootState): string => state.entities.offer.general.businessId;
const getProductName = (state: RootState): string => state.entities.offer.general.productName;
const getActionNr = (state: RootState): string => state.entities.offer.general.actionNr;
const getAdsId = (state: RootState): string => state.entities.offer.general.adsId;
const getOfferStatus = (state: RootState): Flow => state.entities.offer.general.offerStatus;
const getSalesProcess = (state: RootState): SalesProcess | null =>
  state.entities.offer.general.salesProcess;
const getSalesModel = (state: RootState): SalesModel | null =>
  state.entities.offer.general.salesModel;
const getCreatorNr = (state: RootState): string => state.entities.offer.general.creatorNr;
const getBirthdate = (state: RootState): string => state.entities.offer.general.birthdate;
const getConsultationProtocolChannelDirection = (
  state: RootState
): OfferGeneralState['consultationProtocolChannelDirection'] =>
  state.entities.offer.general.consultationProtocolChannelDirection;

const isProcessingFlow = (state: RootState): boolean => getOfferStatus(state) === Flow.PROCESSING;

const isReadOnly = (state: RootState): boolean => getOfferStatus(state) !== Flow.NEW_BUSINESS;

const isOfferSuccessfullyClosed = (state: RootState): boolean =>
  state.entities.offer.general.offerSuccessfullyClosed;

const hasUpdateBaseRequestFailed = (state: RootState): boolean =>
  state.entities.offer.general.updateBaseStatus === ApiRequestStatus.failed;

const hasUpdateBaseRequestSucceeded = (state: RootState): boolean =>
  state.entities.offer.general.updateBaseStatus === ApiRequestStatus.succeeded;

const isUpdateBaseRequestInprogress = (state: RootState): boolean =>
  state.entities.offer.general.updateBaseStatus === ApiRequestStatus.pending;

const isUpdateBirthdateRequestInprogress = (state: RootState): boolean =>
  state.entities.offer.general.updateBirthdateStatus === ApiRequestStatus.pending;

const isFinaliseOfferRequestInprogress = (state: RootState): boolean =>
  state.entities.offer.general.finaliseOfferStatus === ApiRequestStatus.pending;

export const offerGeneralSelectors = {
  getBusinessId,
  getProductName,
  getActionNr,
  getAdsId,
  getOfferStatus,
  getSalesProcess,
  getSalesModel,
  getCreatorNr,
  getBirthdate,
  getConsultationProtocolChannelDirection,
  hasUpdateBaseRequestFailed,
  hasUpdateBaseRequestSucceeded,
  isProcessingFlow,
  isReadOnly,
  isOfferSuccessfullyClosed,
  isUpdateBaseRequestInprogress,
  isUpdateBirthdateRequestInprogress,
  isFinaliseOfferRequestInprogress,
};
