import { ApiRequestStatus } from '../../../../types';
import { AppActions, AppActionsTypes } from '../../app';
import { OfferAgreementsActions } from './agreements.actions';
import { offerAgreementsHelper } from './agreements.helper';
import { OfferAgreementsState } from './agreements.types';
import { OfferFieldsActions, OfferFieldsActionsTypes } from '../fields';
import { OfferGeneralActions, OfferGeneralActionsTypes } from '../general';
import { OfferPackagesActions, OfferPackagesActionsTypes } from '../packages';
import { OfferVariantsActions, OfferVariantsActionsTypes } from '../variants';
import { OfferAgreementsActionsTypes } from './agreements.actions';

const defaultState: Readonly<OfferAgreementsState> = {
  agreements: {},
  updateAgreementsStatus: ApiRequestStatus.idle,
  updateAgreementsError: null,
};

export const offerAgreementsReducer = (
  state: OfferAgreementsState = defaultState,
  action:
    | AppActions
    | OfferAgreementsActions
    | OfferFieldsActions
    | OfferGeneralActions
    | OfferPackagesActions
    | OfferVariantsActions
): OfferAgreementsState => {
  switch (action.type) {
    case AppActionsTypes.GET_FULL_OFFER_SUCCESS:
    case OfferFieldsActionsTypes.UPDATE_FIELDS_SUCCESS:
    case OfferGeneralActionsTypes.UPDATE_BIRTHDATE_SUCCESS:
    case OfferPackagesActionsTypes.UPDATE_PACKAGES_SUCCESS:
    case OfferVariantsActionsTypes.SELECT_VARIANT_SUCCESS:
      return {
        ...state,
        agreements: offerAgreementsHelper.mapOfferBFFToOfferAgreementsSet(action.payload.offer),
      };

    case OfferAgreementsActionsTypes.UPDATE_AGREEMENTS_SUCCESS:
      return {
        ...state,
        agreements: offerAgreementsHelper.mapOfferBFFToOfferAgreementsSet(action.payload.offer),
        updateAgreementsStatus: ApiRequestStatus.succeeded,
        updateAgreementsError: null,
      };

    case OfferAgreementsActionsTypes.UPDATE_AGREEMENTS_TRIGGER:
      return {
        ...state,
        updateAgreementsStatus: ApiRequestStatus.pending,
      };

    case OfferAgreementsActionsTypes.UPDATE_AGREEMENTS_FAILURE:
      return {
        ...state,
        updateAgreementsStatus: ApiRequestStatus.failed,
        updateAgreementsError: action.payload,
      };

    default:
      return state;
  }
};
