import { RootState } from '../../store/store.types';
import { ClosableAccordionTypes } from './Accordion.types';
import { createSelector } from 'reselect';
import { AccordionsState } from './Accordions.types';

const isAccordionOpened = (
  state: RootState,
  sectionKey: ClosableAccordionTypes
): boolean | undefined => state.ui.accordions[sectionKey];

const getAccordionsState = (state: RootState) => state.ui.accordions;

const isAtLeastOneAccordionOpened = createSelector<RootState, AccordionsState, boolean>(
  getAccordionsState,
  (accordionsState) => {
    return Object.keys(accordionsState).some((key) => {
      return accordionsState[key as ClosableAccordionTypes] === true;
    });
  }
);

export const accordionSelectors = {
  isAccordionOpened,
  isAtLeastOneAccordionOpened,
};
