import React, { useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import { Field, RadioGroup } from '../../../../components';
import { InsuranceStartDateProps } from './InsuranceStartDate.types';
import { InsuranceStartDateTooltip } from './InsuranceStartDateTooltip';
import { OfferFieldValue } from '../../../../store/entities';
import { OfferTable } from '../../../../components/OfferTable';
import { Option } from '../../../../types/Option';
import { RadioEditableDate } from '../RadioEditableDate';
import { RadioStaticDate } from '../RadioStaticDate';
import styles from './InsuranceStartDate.module.scss';

const BFF_DATE_FORMAT = 'yyyy-MM-dd';
const CUSTOM_DATE_VALUE = 'CUSTOM_DATE';

const parseToDate = (value: OfferFieldValue): Date =>
  parse(value as string, BFF_DATE_FORMAT, new Date());

const inferDatePickerDate = (options: Option[], value: OfferFieldValue): Date | undefined => {
  return !!value && options.find((option) => option.value === value) === undefined
    ? parseToDate(value)
    : undefined;
};

const computeSelectedValue = (date: Date | undefined, value: OfferFieldValue): OfferFieldValue => {
  return date === undefined ? value : CUSTOM_DATE_VALUE;
};

export const InsuranceStartDate: React.FC<InsuranceStartDateProps> = ({
  hasUpdateFieldsRequestFailed,
  isAnyOfferRequestInProgress,
  max,
  min,
  options,
  update,
  value,
}) => {
  const labels = ['Morgen', 'Nächster Monat'];
  const hasOptions = !!options.length;
  const disabledField = isAnyOfferRequestInProgress || !hasOptions;
  const [datePickerDate, setDatePickerDate] = useState<Date | undefined>(
    inferDatePickerDate(options, value)
  );

  const [selectedValue, setSelectedValue] = useState<OfferFieldValue>(
    computeSelectedValue(datePickerDate, value)
  );

  const updateOffer = (newValue: OfferFieldValue) => {
    if (value !== newValue) {
      update(newValue);
    }
  };

  useEffect(() => {
    const inferedDate = inferDatePickerDate(options, value);
    setDatePickerDate(inferedDate || datePickerDate);

    if (!datePickerDate || hasUpdateFieldsRequestFailed) {
      setSelectedValue(computeSelectedValue(inferedDate, value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUpdateFieldsRequestFailed, options, value]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSelectedValue(inputValue);

    if (inputValue === CUSTOM_DATE_VALUE) {
      datePickerDate !== undefined
        ? updateOffer(format(datePickerDate, BFF_DATE_FORMAT))
        : setDatePickerDate(parseToDate(value));
    } else if (inputValue !== CUSTOM_DATE_VALUE) {
      updateOffer(inputValue);
    }
  };

  const onChangeDatePickerHandler = (event: React.FormEvent<Element>, date: Date) => {
    if (!date) {
      return;
    }

    setDatePickerDate(date);
    updateOffer(format(date, BFF_DATE_FORMAT));
  };

  const shouldDisabledDateSelection = selectedValue !== CUSTOM_DATE_VALUE;
  return (
    <OfferTable.Row
      className={styles.root}
      label='Versicherungsbeginn'
      tooltip={InsuranceStartDateTooltip}
    >
      <Field className={styles.field} disabled={disabledField}>
        <RadioGroup className={styles.radioGroup} value={selectedValue} onChange={onChangeHandler}>
          {options.map((option, index: number) => (
            <RadioStaticDate
              className={styles.radio}
              value={option.value}
              labelText={labels[index]}
              labelDate={option.label}
              key={`insurance.start.date.option.${index}`}
            />
          ))}
          <RadioEditableDate
            className={styles.radio}
            value={CUSTOM_DATE_VALUE}
            label='Anderes Datum'
            disabledDateSelection={shouldDisabledDateSelection}
            minDate={min}
            maxDate={max}
            date={datePickerDate}
            onChangeDate={onChangeDatePickerHandler}
            loading={isAnyOfferRequestInProgress}
          />
        </RadioGroup>
      </Field>
    </OfferTable.Row>
  );
};
