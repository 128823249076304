import { connect } from 'react-redux';
import { FatalErrorModal as FatalErrorModalComponent } from './FatalErrorModal';
import { fatalErrorModalSelectors } from './FatalErrorModal.selectors';
import { FatalErrorModalStateProps } from './FatalErrorModal.types';
import { RootState } from '../../store/store.types';

const mapStateToProps = (state: RootState): FatalErrorModalStateProps => ({
  isOpened: fatalErrorModalSelectors.isOpened(state),
});

export const FatalErrorModal = connect(mapStateToProps)(FatalErrorModalComponent);
