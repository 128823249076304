import { isString, template } from 'lodash';
import { CONFIG_BACKEND_API_VERSION, CONFIG_BACKEND_URL } from '../config';

export enum ENDPOINTS {
  getFullOffer,
  selectVariant,
  updateAgreements,
  updateFields,
  updateBirthdate,
  updatePackages,
  updateBaseOfferData,
  finaliseOffer,
  updatePayment,
  refreshUserToken,
  login,
}

class EndpointBuilderFactory {
  private ENDPOINT_TEMPLATES_URL: Record<ENDPOINTS, string> = {
    [ENDPOINTS.getFullOffer]:
      '<%= backendUrl %>/api/<%= apiVersion %>/full-offer/<%= businessId %>',
    [ENDPOINTS.selectVariant]:
      '<%= backendUrl %>/api/<%= apiVersion %>/full-offer/<%= businessId %>/variant/select/<%= variantId %>',
    [ENDPOINTS.updateAgreements]:
      '<%= backendUrl %>/api/<%= apiVersion %>/full-offer/<%= businessId %>/agreements',
    [ENDPOINTS.updateFields]:
      '<%= backendUrl %>/api/<%= apiVersion %>/full-offer/<%= businessId %>/fields',
    [ENDPOINTS.updateBirthdate]:
      '<%= backendUrl %>/api/<%= apiVersion %>/full-offer/<%= businessId %>/birthdate',
    [ENDPOINTS.updatePackages]:
      '<%= backendUrl %>/api/<%= apiVersion %>/full-offer/<%= businessId %>/packages',
    [ENDPOINTS.updateBaseOfferData]:
      '<%= backendUrl %>/api/<%= apiVersion %>/full-offer/<%= businessId %>/base',
    [ENDPOINTS.finaliseOffer]:
      '<%= backendUrl %>/api/<%= apiVersion %>/full-offer/close/<%= businessId %>',
    [ENDPOINTS.updatePayment]:
      '<%= backendUrl %>/api/<%= apiVersion %>/full-offer/<%= businessId %>/payment',
    [ENDPOINTS.refreshUserToken]: '<%= backendUrl %>/api/auth/refresh/user-token',
    [ENDPOINTS.login]: '<%= backendUrl %>/api/auth/login?RelayState=<%= RelayState %>',
  };

  private apiVersion: string = CONFIG_BACKEND_API_VERSION;

  private backendUrl: string = CONFIG_BACKEND_URL;

  private businessId: string = '';

  setApiVersion(apiVersion?: string) {
    if (isString(apiVersion)) {
      this.apiVersion = apiVersion;
    }
  }

  setBackendUrl(backendUrl?: string) {
    if (isString(backendUrl)) {
      this.backendUrl = backendUrl;
    }
  }

  setBusinessId(businessId: string) {
    this.businessId = businessId;
  }

  build(templateKey: ENDPOINTS, templateParams = {}): string {
    const params = {
      apiVersion: this.apiVersion,
      backendUrl: this.backendUrl,
      businessId: this.businessId,
      ...templateParams,
    };

    const templateUrl = this.ENDPOINT_TEMPLATES_URL[templateKey];
    return template(templateUrl)(params);
  }
}

const endpointBuilderFactory = new EndpointBuilderFactory();
export const buildEndpoint = endpointBuilderFactory.build.bind(endpointBuilderFactory);
export default endpointBuilderFactory;
