import React from 'react';
import { InsuredPersonOfferDetailsProps } from './InsuredPersonOfferDetails.types';
import { OfferPackagesIds } from '../../store/entities';
import { OfferTable } from '../../components';
import {
  KeyLoss,
  MonoparentalFamily,
  OfficialAndPublicLiability,
  ReplacementCompensation,
  StartingBonus,
  UnpaidDebt,
} from './components';
import { VariantSelection } from './components/VariantSelection';
import styles from './InsuredPersonOfferDetails.module.scss';

export const InsuredPersonOfferDetails: React.FC<InsuredPersonOfferDetailsProps> = ({
  initialPersonsIdsForOfficialAndPublicLiabilityPackage,
  initialPersonsIdsForMonoparentalPackage,
  selectedVariantId,
  variantIds,
}) => {
  return (
    <div className={styles.insuredPersonOfferDetails}>
      <div className={styles.variantSelection}>
        <VariantSelection />
      </div>

      <OfferTable>
        {/* DO NOT REMOVE THE MANDATORY THEAD - requirement of Ergo Elements */}
        <thead className={styles.emptyTableHeader}>
          <tr>
            <th />
          </tr>
        </thead>
        <tbody>
          <StartingBonus
            variantId={selectedVariantId}
            packageId={OfferPackagesIds.STARTING_BONUS}
          />
          <KeyLoss variantId={selectedVariantId} packageId={OfferPackagesIds.KEY_LOSS} />
          <ReplacementCompensation
            variantId={selectedVariantId}
            packageId={OfferPackagesIds.REPLACEMENT_COMPENSATION}
          />
          <UnpaidDebt variantId={selectedVariantId} packageId={OfferPackagesIds.UNPAID_DEBT} />
          <OfficialAndPublicLiability
            variantIds={variantIds}
            packageId={OfferPackagesIds.OFFICIAL_AND_PUBLIC_LIABILITY}
            initialPersonsIds={initialPersonsIdsForOfficialAndPublicLiabilityPackage}
          />
          <MonoparentalFamily
            variantIds={variantIds}
            packageId={OfferPackagesIds.MONOPARENTAL_FAMILY}
            initialPersonsIds={initialPersonsIdsForMonoparentalPackage}
          />
        </tbody>
      </OfferTable>
    </div>
  );
};
