export const DeductibleTooltip = (
  <>
    <p>
      Mit einer Selbstbeteiligung im Schadensfall von <strong>150 €</strong> erhalten Sie{' '}
      <strong>20 % Nachlass</strong> auf Ihren Nettobeitrag.
    </p>

    <p>
      <strong>Achtung:</strong> Die vereinbarte Selbstbeteiligung gilt nicht für die Zusatzbausteine
      „Forderungsausfall“, „Schlüsselverlust“ und „Neuwertentschädigung“.
    </p>
  </>
);
