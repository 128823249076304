import { map } from 'rxjs/operators';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { accordionActions, AccordionsActionsTypes } from './Accordions.actions';
import { accordionSelectors } from './Accordions.selectors';
import { RootActions, RootState } from '../../store/store.types';

export const onToggleAccordions: Epic<RootActions, RootActions, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(AccordionsActionsTypes.TOGGLE_ALL_ACCORDIONS),
    map(() => {
      const isAtLeastOneAccordionOpened = accordionSelectors.isAtLeastOneAccordionOpened(
        state$.value
      );
      if (isAtLeastOneAccordionOpened) {
        return accordionActions.closeAllAccordions();
      } else {
        return accordionActions.openAllAccordions();
      }
    })
  );

export const accordionsEpics = combineEpics(onToggleAccordions);
