import { AccordionsState } from './Accordions.types';
import _ from 'lodash';
import { AccordionActions, AccordionsActionsTypes } from './Accordions.actions';

const defaultState: Readonly<AccordionsState> = {};

class AccordionsStateState {}

export const accordionsReducer = (
  state: AccordionsState = defaultState,
  action: AccordionActions
): AccordionsStateState => {
  switch (action.type) {
    case AccordionsActionsTypes.REGISTER_ACCORDION:
      return {
        ...state,
        [action.payload.sectionKey]: true,
      };
    case AccordionsActionsTypes.UNREGISTER_ACCORDION:
      const { [action.payload.sectionKey]: accordionState, ...restState } = state;
      return restState;
    case AccordionsActionsTypes.TOGGLE_ACCORDION:
      const currentValue = state[action.payload.sectionKey];
      return {
        ...state,
        [action.payload.sectionKey]: !currentValue,
      };
    case AccordionsActionsTypes.OPEN_ALL_ACCORDIONS:
      const newStateOpen = _.mapValues(state, () => true);
      return {
        ...newStateOpen,
      };
    case AccordionsActionsTypes.CLOSE_ALL_ACCORDIONS:
      const newStateClose = _.mapValues(state, () => false);
      return {
        ...newStateClose,
      };

    default:
      return state;
  }
};
