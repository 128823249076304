import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  offerGeneralSelectors,
  OfferPackageFieldsIds,
  offerPackagesActions,
  OfferPackagesIds,
  offerPackagesSelectors,
  offerSelectors,
  offerVariantsSelectors,
} from '../../../../store/entities';
import { RootState } from '../../../../store/store.types';
import {
  WithPackageInsuredPersonsIdsDispatchProps,
  WithPackageInsuredPersonsIdsMergeProps,
  WithPackageInsuredPersonsIdsOwnProps,
  WithPackageInsuredPersonsIdsStateProps,
} from '.';
import { Dispatch } from 'redux';
import { partial } from 'lodash';

const makeMapStateToProps = () => {
  const getInsuredPersonsIds = offerPackagesSelectors.makeGetPackageInsuredPersonsIds();

  const mapStateToProps = (
    state: RootState,
    ownProps: WithPackageInsuredPersonsIdsOwnProps
  ): WithPackageInsuredPersonsIdsStateProps => {
    const variantIds = offerVariantsSelectors.getAllIds(state);
    const selectedVariantId = offerVariantsSelectors.getSelectedId(state) ?? '';

    return {
      variantIds: variantIds,
      insuredPersonsIds: getInsuredPersonsIds(state, selectedVariantId, ownProps.packageId),
      isAnyOfferRequestInProgress: offerSelectors.isAnyOfferRequestInProgress(state),
      businessId: offerGeneralSelectors.getBusinessId(state),
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch: Dispatch): WithPackageInsuredPersonsIdsDispatchProps => ({
  setInsuredPersons: (
    variantIds: string[],
    packageId: OfferPackagesIds,
    fieldId: OfferPackageFieldsIds,
    insuredPersonsIds: string[]
  ) => {
    const updatePackagePayload = variantIds.map((variantId) => {
      return {
        variantId,
        packageId,
        fieldId,
        value: insuredPersonsIds,
      };
    });

    dispatch(offerPackagesActions.updatePackages.trigger(updatePackagePayload));
  },
});

const mergeProps = (
  stateProps: WithPackageInsuredPersonsIdsStateProps,
  dispatchProps: WithPackageInsuredPersonsIdsDispatchProps,
  ownProps: WithPackageInsuredPersonsIdsOwnProps
): WithPackageInsuredPersonsIdsMergeProps => ({
  ...ownProps,
  ...stateProps,
  setInsuredPersons: (insuredPersonsIds: string[]) =>
    partial(
      dispatchProps.setInsuredPersons,
      stateProps.variantIds,
      ownProps.packageId,
      OfferPackageFieldsIds.personsIds
    )(insuredPersonsIds),
});

const connector = connect(makeMapStateToProps, mapDispatchToProps, mergeProps);

export const WithPackageInsuredPersonsIds = (
  Component: React.ComponentType<ConnectedProps<typeof connector>>
) => connector(Component);
