import { combineEpics, Epic } from 'redux-observable';
import { filter, first, ignoreElements, map, tap } from 'rxjs/operators';
import {
  AppActionsTypes,
  appSelectors,
  OfferAgreementsActionsTypes,
  OfferFieldsActionsTypes,
  OfferGeneralActionsTypes,
  OfferPackagesActionsTypes,
  OfferVariantsActionsTypes,
} from '../../store/entities';
import { unauthorizedModalActions } from './UnauthorizedModal.actions';
import { UnauthorizedModalHelper } from './UnauthorizedModal.helper';
import { OfferPaymentActionsTypes } from '../../store/entities/offer/payment/payment.actions';

export const SESSION_STORAGE_KEY = 'ppz-tof-failure';

const FAILED_REQUESTS_ACTIONS = [
  AppActionsTypes.GET_FULL_OFFER_FAILURE,
  OfferAgreementsActionsTypes.UPDATE_AGREEMENTS_FAILURE,
  OfferFieldsActionsTypes.UPDATE_FIELDS_FAILURE,
  OfferGeneralActionsTypes.UPDATE_BASE_FAILURE,
  OfferGeneralActionsTypes.UPDATE_BIRTHDATE_FAILURE,
  OfferGeneralActionsTypes.FINALISE_OFFER_FAILURE,
  OfferPackagesActionsTypes.UPDATE_PACKAGES_FAILURE,
  OfferPaymentActionsTypes.UPDATE_PAYMENT_FAILURE,
  OfferVariantsActionsTypes.SELECT_VARIANT_FAILURE,
];

export const onAppInit: Epic = (action$, state$) => {
  return action$.pipe(
    filter((action) => {
      // flag to prevent redirect loop
      const hasRedirectLoopProtectionFlag = sessionStorage.getItem(SESSION_STORAGE_KEY) !== null;
      return (
        action.type === AppActionsTypes.GET_FULL_OFFER_FAILURE &&
        action.payload?.status === 401 &&
        !appSelectors.isInitializedWithSuccess(state$.value) &&
        hasRedirectLoopProtectionFlag
      );
    }),
    first(),
    map(() => unauthorizedModalActions.openUnauthorizedModal())
  );
};

export const onUnauthorizedInitResponse: Epic = (action$, state$) => {
  return action$.pipe(
    filter((action) => {
      // flag to prevent redirect loop
      const hasRedirectLoopProtectionFlag = sessionStorage.getItem(SESSION_STORAGE_KEY) !== null;
      return (
        action.type === AppActionsTypes.GET_FULL_OFFER_FAILURE &&
        action.payload?.status === 401 &&
        !appSelectors.isInitializedWithSuccess(state$.value) &&
        !hasRedirectLoopProtectionFlag
      );
    }),
    tap(() => {
      sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
      UnauthorizedModalHelper.redirectToLoginPage();
    }),
    ignoreElements()
  );
};

export const onInitSuccessOrWithoutAuthError: Epic = (action$, state$) => {
  return action$.pipe(
    filter((action) => {
      return (
        appSelectors.isInitializedWithSuccess(state$.value) ||
        (FAILED_REQUESTS_ACTIONS.includes(action.type) && action.payload?.status !== 401)
      );
    }),
    first(),
    tap(() => sessionStorage.removeItem(SESSION_STORAGE_KEY))
  );
};

export const onUnauthorizedResponse: Epic = (action$, state$) => {
  return action$.pipe(
    filter(
      (action) =>
        FAILED_REQUESTS_ACTIONS.includes(action.type) &&
        action.payload?.status === 401 &&
        appSelectors.isInitializedWithSuccess(state$.value)
    ),
    map(() => unauthorizedModalActions.openUnauthorizedModal())
  );
};

export const unauthorizedModalEpics = combineEpics(
  onAppInit,
  onUnauthorizedInitResponse,
  onInitSuccessOrWithoutAuthError,
  onUnauthorizedResponse
);
