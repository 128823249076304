import { connect } from 'react-redux';
import { offerGeneralSelectors } from '../../store/entities';
import { RootState } from '../../store/store.types';
import { ThankYouModal as ThankYouModalComponent } from './ThankYouModal';
import { ThankYouModalProps } from './ThankYouModal.types';

const mapStateToProps = (state: RootState): ThankYouModalProps => ({
  isOpened: offerGeneralSelectors.isOfferSuccessfullyClosed(state),
});

export const ThankYouModal = connect(mapStateToProps)(ThankYouModalComponent);
