import { ajax, AjaxError } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { buildDefaultHeaders, buildEndpoint, ENDPOINTS } from '../../../../services';
import { offerVariantsActions, OfferVariantsActionsTypes } from './variants.actions';
import { RootActions, RootState } from '../../../store.types';

export const onSelectVariantRequest: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferVariantsActionsTypes.SELECT_VARIANT_TRIGGER),
    mergeMap((action) => {
      return ajax({
        url: buildEndpoint(ENDPOINTS.selectVariant, { variantId: action.payload }),
        method: 'PUT',
        body: null,
        headers: buildDefaultHeaders(),
        withCredentials: true,
      }).pipe(
        map((data) => offerVariantsActions.selectVariant.success(data.response)),
        catchError((error: AjaxError) => of(offerVariantsActions.selectVariant.failure(error)))
      );
    })
  );

export const offerVariantsEpics = combineEpics(onSelectVariantRequest);
