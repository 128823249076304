import React from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import { RadioGroupProps } from './RadioGroup.types';
import styles from './RadioGroup.module.scss';

export const RadioGroup: React.FC<RadioGroupProps> = ({
  onChange = noop,
  className,
  disabled = false,
  children,
  value,
}) => {
  return (
    <div className={classNames(styles.root, className)}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ...child.props,
            onChange,
            disabled,
            checked: child.props.value === value,
          });
        }

        return child;
      })}
    </div>
  );
};
