import { PersonalDataPayload } from '@disorder/personal-data-models';
import { FullOfferBFF } from '../app';
import { RoleType } from '../offer';

const mapOfferBFFToInsuranceOwnerId = (offer: FullOfferBFF): string => {
  const insuranceOwner = offer.offer.roles.find(({ role }) => role === RoleType.INSURANCE_OWNER);

  return insuranceOwner?.personId || '';
};

const getInsuranceOwnerFromOffer = (offer: FullOfferBFF): string => offer.persons.insuranceOwnerId;
const getInsuranceOwnerPartnerFromOffer = (offer: FullOfferBFF): string =>
  offer.persons.insuranceOwnerPartnerId;
const getFamilyMembersIdsFromOffer = (offer: FullOfferBFF): string[] =>
  offer.persons.familyMembersIds;

const mapPersonChangeToValidationErrors = (
  personId: string,
  payload: PersonalDataPayload
): string[] | undefined => {
  const { personId: payloadPersonId } = payload.person;

  if (personId === payloadPersonId) {
    return payload.backendValidationErrors || [];
  }
};

export const personHelper = {
  mapOfferBFFToInsuranceOwnerId,
  getInsuranceOwnerFromOffer,
  getInsuranceOwnerPartnerFromOffer,
  getFamilyMembersIdsFromOffer,
  mapPersonChangeToValidationErrors,
};
