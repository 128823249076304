export const NO_CACHE_HEADERS = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: -1,
};

export const DEFAULT_HTTP_HEADERS: Record<string, string | number> = {
  'Content-Type': 'application/json',
  ...NO_CACHE_HEADERS,
};

export const CONFIG_BACKEND_URL: string =
  (window as any).CONFIG_BACKEND_URL || process.env.REACT_APP_BACKEND_URL;

export const CONFIG_BACKEND_API_VERSION: string =
  (window as any).CONFIG_BACKEND_API_VERSION || process.env.REACT_APP_BACKEND_API_VERSION;

export const CONFIG_PERSONAL_DATA_BUNDLE_URL: string =
  (window as any).CONFIG_PERSONAL_DATA_BUNDLE_URL ||
  process.env.REACT_APP_PERSONAL_DATA_BUNDLE_URL ||
  '/personal-data-telephony-v1.js';

export const CONFIG_PERSONAL_DATA_BACKEND_URL: string =
  (window as any).CONFIG_PERSONAL_DATA_BACKEND_URL ||
  process.env.REACT_APP_PERSONAL_DATA_BACKEND_URL;

export const CONFIG_MICRO_PAYMENT_DATA_BUNDLE_URL: string =
  (window as any).CONFIG_MICRO_PAYMENT_DATA_BUNDLE_URL ||
  process.env.REACT_APP_MICRO_PAYMENT_DATA_BUNDLE_URL ||
  '/micro-payment-data-telephony-v1.js';

export const CONFIG_MICRO_PAYMENT_DATA_BACKEND_URL: string =
  (window as any).CONFIG_MICRO_PAYMENT_DATA_BACKEND_URL ||
  process.env.REACT_APP_MICRO_PAYMENT_DATA_BACKEND_URL;
