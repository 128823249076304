import React, { useEffect, useState } from 'react';
import Checkbox from '@eg/elements/Checkbox';
import { AgreementProps } from './Agreement.types';
import styles from './Agreement.module.scss';

export const Agreement: React.FC<AgreementProps> = ({
  agreement,
  hasRequestFailed,
  isLoading,
  select,
}) => {
  const { checked, id, text } = agreement;
  const [checkedState, setCheckedState] = useState<boolean>(checked);

  useEffect(() => {
    setCheckedState(checked);
  }, [checked, hasRequestFailed]);

  const onChangeAgreementHandlder = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setCheckedState(isChecked);
    select(id, isChecked);
  };

  return (
    <Checkbox
      className={styles.root}
      label={<strong>{text}</strong>}
      disabled={isLoading}
      checked={checkedState}
      onChange={onChangeAgreementHandlder}
    />
  );
};
