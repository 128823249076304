import { Option } from '../../../types';

export enum Flow {
  NEW_BUSINESS = 'ANGELEGT',
  PROCESSING = 'VEREDELUNG_AKTIV',
}

export enum SalesProcess {
  DIRECT = 'DIREKTABSCHLUSS',
  MAILING = 'MAILING',
  WRITTEN_OFFER = 'VOLLANGEBOT',
}

export enum SalesModel {
  A = 'A_MODELL',
  I = 'I_MODELL',
}

export enum OfferMessageCode {
  AdresseFehlt = 'ADRESSE_FEHLT',
  AdresseUngueltig = 'ADRESSE_UNGUELTIG',
  CheckAlleDaHinweiseGelesen = 'error.KompositAngebotsVariante.checkAlleDaHinweiseGelesen',
  CheckBeratungsverzichtZugestimmt = 'error.KompositAngebot.checkBeratungsverzichtZugestimmt',
  CheckIddIdVorhandenBeiAbschluss = 'error.KompositAngebot.checkIddIdVorhandenBeiAbschluss',
  CheckPaymentStoreIdVorhandenBeiDa = 'error.KompositAngebot.checkPaymentStoreIdVorhandenBeiDA',
  CheckPdsDatenValide = 'error.KompositAngebot.checkPdsDatenValide',
  CheckVarianteGewaehlt = 'error.KompositAngebot.checkVarianteGewaehlt',
  KeineEmailVorhanden = 'KEINE_EMAIL_VORHANDEN',
}

export enum OfferMessageSeverity {
  error = 'ERROR',
  warning = 'WARNING',
  info = 'INFO',
}

export enum RoleType {
  CONTRIBUTION_PAYER = 'CONTRIBUTION_PAYER',
  INSURANCE_OWNER = 'INSURANCE_OWNER',
  INSURED_PERSON = 'INSURED_PERSON',
  SHIPPING_ADDRESS = 'SHIPPING_ADDRESS',
  BENEFICIARY = 'BENEFICIARY',
}

export enum ConsultancyChannelDirection {
  INBOUND = 'Inbound',
  OUTBOUND = 'Outbound',
}

export type OfferGeneralBFF = {
  businessId: string;
  productName: string;
  actionNr: string | null;
  adsId: string | null;
  consultationProtocolChannelDirection: ConsultancyChannelDirection | null;
  consultationProtocolId?: string;
  offerStatus: Flow;
  salesProcess: SalesProcess | null;
  salesModel: SalesModel | null;
  creatorNr: string | null;
  birthdate: string;
};

export interface OfferMessageBFF {
  code: OfferMessageCode;
  text: string;
  severity: OfferMessageSeverity;
  variantId: string | null;
}

export type OfferAgreementBFF = {
  id: string;
  checked: boolean;
  text: string;
};

export type OfferAgreementsBFF = {
  variantId: string;
  agreements: OfferAgreementBFF[];
};

export type OfferFeeBFF = {
  gross: number;
  net: number;
  variantId: string;
};

export type OfferVariantBFF = {
  id: string;
  selected: boolean;
  fields: OfferVariantFieldBFF[];
  packages: OfferVariantPackageBFF[];
};

export type OfferVariantPackageBFF = {
  id: string;
  selected: boolean;
  options: Option[];
  value?: string | null;
  insuredPersonsIds?: string[];
};

export type OfferVariantFieldBFF = {
  id: string;
  value: string | number;
  options?: Option[];
  min?: string;
  max?: string;
};

export type OfferRoleBFF = {
  personId: string;
  role: RoleType;
  paymentId: string;
};

export type OfferBFF = OfferGeneralBFF & {
  messages?: OfferMessageBFF[];
  agreements: OfferAgreementsBFF[];
  fees: OfferFeeBFF[] | null;
  roles: OfferRoleBFF[];
  variants: OfferVariantBFF[];
};
