import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import BaseSelect from '@eg/elements/Select';
import { noop } from 'lodash';
import { labels } from './Labels';
import { SalesProcess as SalesProcessEnum } from '../../../../store/entities';
import { SalesProcessProps } from './SalesProcess.types';
import styles from './SalesProcess.module.scss';

export const SalesProcess: React.FC<SalesProcessProps> = ({
  isLoading = false,
  readOnly = false,
  salesProcess,
  salesProcessOptions = [],
  updateSalesProcess = noop,
}) => {
  const [selectedSalesProcess, setSelectedSalesProcess] = useState<SalesProcessEnum>(salesProcess);
  const salesProcessLabel = labels[salesProcess] || salesProcess || '-';
  const onChangeSalesProcessHandler = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      setSelectedSalesProcess(value as SalesProcessEnum);
      updateSalesProcess(value as SalesProcessEnum);
    },
    [updateSalesProcess]
  );

  useEffect(() => {
    setSelectedSalesProcess(salesProcess);
  }, [salesProcess]);

  return (
    <div className={styles.root}>
      {readOnly ? (
        <span className={styles.salesProcessReadOnly}>
          <strong>{salesProcessLabel}</strong>
        </span>
      ) : (
        <BaseSelect
          value={selectedSalesProcess}
          disabled={isLoading}
          onChange={onChangeSalesProcessHandler}
        >
          {!selectedSalesProcess && <option disabled value='' />}
          {salesProcessOptions.map(({ label: optionLabel, value: optionValue }, index: number) => (
            <option key={`sales.process.${optionValue}.${index}`} value={optionValue as string}>
              {optionLabel}
            </option>
          ))}
        </BaseSelect>
      )}
    </div>
  );
};
