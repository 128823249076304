import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BundleParcelElement } from '@disorder/microfrontend-bundle-loader';
import { MicroPersonalDataEvent, PersonalDataPayload } from '@disorder/personal-data-models';
import { personsActions } from '../../store/entities/persons';

const addEventsListeners = (
  appId: string,
  el: HTMLElement | undefined,
  onChangeHandler: EventListener
) => {
  el?.addEventListener(`upds:${appId}:${MicroPersonalDataEvent.ON_LOAD}`, onChangeHandler);
  el?.addEventListener(`upds:${appId}:${MicroPersonalDataEvent.ON_UPDATE}`, onChangeHandler);
  el?.addEventListener(`upds:${appId}:${MicroPersonalDataEvent.ON_ERROR}`, onChangeHandler);
};

const removeEventsListeners = (
  appId: string,
  el: HTMLElement | undefined,
  onChangeHandler: EventListener
) => {
  el?.removeEventListener(`upds:${appId}:${MicroPersonalDataEvent.ON_LOAD}`, onChangeHandler);
  el?.removeEventListener(`upds:${appId}:${MicroPersonalDataEvent.ON_UPDATE}`, onChangeHandler);
  el?.removeEventListener(`upds:${appId}:${MicroPersonalDataEvent.ON_ERROR}`, onChangeHandler);
};

export function usePersonalDataEvents(appId: string) {
  const [microfrontRef, setMicrofrontRef] = useState<React.RefObject<BundleParcelElement>>(
    React.createRef()
  );
  const dispatch = useDispatch();
  const onChangeHandler = useCallback(
    (evt: CustomEvent<PersonalDataPayload>) => {
      dispatch(personsActions.personChange(evt.detail));
    },
    [dispatch]
  );

  const element = microfrontRef.current?.el;

  useEffect(() => {
    addEventsListeners(appId, element, onChangeHandler as EventListener);

    return () => removeEventsListeners(appId, element, onChangeHandler as EventListener);
  }, [appId, element, onChangeHandler]);

  const validatePersonEvent = () => {
    element?.dispatchEvent(new CustomEvent(`upds:${appId}:submit`) as Event);
  };

  return { setMicrofrontRef, validatePersonEvent };
}
