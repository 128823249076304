import Button from '@eg/elements/Button';
import React from 'react';
import { Sidebar } from '../../components/Sidebar';
import { useFormatCurrency } from '../../hooks';
import styles from './StickySidebar.module.scss';
import { StickySidebarProps } from './StickySidebar.types';
import { ArrowDownIcon, ArrowUpIcon } from '@eg/elements/components/Icons';

export const StickySidebar: React.FC<StickySidebarProps> = ({
  insuranceOwnerMicroPDSErrorTextMessages,
  offerErrorTextMessages,
  paymentMethod,
  fee,
  salesProcess,
  actionNumber,
  oeNr,
  employeeNr,
  salesProduct,
  salesModel,
  offerStatus,
  businessId,
  isProcessingFlow,
  finalizeOrder,
  isFinalizeOrderRequestInProgress,
  isAnyOfferRequestInProgress,
  isReadOnly,
  toggleAllAccordions,
  hasAtLeastOneAccordeonOpened,
}) => {
  const hasInsuranceOwnerMicroPDSErrorTextMessages =
    !!insuranceOwnerMicroPDSErrorTextMessages.length;

  const hasOfferErrorTextMessages = !!offerErrorTextMessages.length;

  const hasErrorTextMessages =
    hasInsuranceOwnerMicroPDSErrorTextMessages || hasOfferErrorTextMessages;

  const shouldDisableSubmitButton =
    hasInsuranceOwnerMicroPDSErrorTextMessages ||
    isAnyOfferRequestInProgress ||
    isFinalizeOrderRequestInProgress ||
    isReadOnly;

  return (
    <section className={styles.root}>
      <Sidebar>
        <div className={styles.salesProcess}>
          <Sidebar.SalesProcess readOnly={true} salesProcess={salesProcess} />
        </div>

        <Sidebar.Statics
          actionNumber={actionNumber}
          oeNr={oeNr}
          employeeNr={employeeNr}
          salesProduct={salesProduct}
          salesModel={salesModel}
          offerStatus={offerStatus}
          businessId={businessId}
          isProcessingFlow={isProcessingFlow}
        />

        <div className={styles.payment}>
          <Sidebar.Payment
            formatCurrency={useFormatCurrency}
            paymentMethod={paymentMethod}
            fee={fee}
          />
        </div>

        {hasErrorTextMessages && (
          <div className={styles.errors}>
            <Sidebar.Section title='Meldungen'>
              <div className={styles.errorsLists}>
                {hasInsuranceOwnerMicroPDSErrorTextMessages && (
                  <div className={styles.errorsList}>
                    <Sidebar.Errors messages={insuranceOwnerMicroPDSErrorTextMessages} />
                  </div>
                )}
                {hasOfferErrorTextMessages && (
                  <div className={styles.errorsList}>
                    <Sidebar.Errors messages={offerErrorTextMessages} />
                  </div>
                )}
              </div>
            </Sidebar.Section>
          </div>
        )}

        <div className={styles.submit}>
          <Sidebar.Submit finalizeOrder={finalizeOrder} disabled={shouldDisableSubmitButton} />
        </div>
        <div className={styles.accordionsToggle}>
          <Button
            variant='text-link'
            iconLeft={hasAtLeastOneAccordeonOpened ? ArrowUpIcon : ArrowDownIcon}
            onClick={() => toggleAllAccordions()}
          >
            {hasAtLeastOneAccordeonOpened ? 'Details minimieren' : 'Details maximieren'}
          </Button>
        </div>
      </Sidebar>
    </section>
  );
};
