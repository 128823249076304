import { createAction, ActionsUnion } from '../../services';

export enum UnauthorizedModalActionTypes {
  OPEN_UNAUTHORIZED_MODAL = '[entites][unauthorized_modal] OPEN_MODAL',
  CLOSE_UNAUTHORIZED_MODAL = '[entites][unauthorized_modal] CLOSE_MODAL',
}

const openUnauthorizedModal = () =>
  createAction(UnauthorizedModalActionTypes.OPEN_UNAUTHORIZED_MODAL);

const closeUnauthorizedModal = () =>
  createAction(UnauthorizedModalActionTypes.CLOSE_UNAUTHORIZED_MODAL);

const actions = {
  openUnauthorizedModal,
  closeUnauthorizedModal,
};

const asyncActions = {};

export const unauthorizedModalActions = {
  ...actions,
  ...asyncActions,
};

export type UnauthorizedModalActions = ActionsUnion<typeof actions, typeof asyncActions>;
