import React from 'react';
import Button from '@eg/elements/Button';
import Modal from '@eg/elements/Modal';
import { FatalErrorModalProps } from './FatalErrorModal.types';
import styles from './FatalErrorModal.module.scss';

export const FatalErrorModal: React.FC<FatalErrorModalProps> = ({ isOpened }) => {
  const onClickHanlder = () => window.location.reload();

  return (
    <Modal className={styles.root} open={isOpened} viewport='fixed'>
      <div className={styles.wrapper}>
        <h1>Etwas ist schief gelaufen</h1>
        <p>
          Bei der laufenden Beratung ist leider der oben genannte Fehler aufgetreten. Bitte drücken
          Sie die Reload-Taste, um das Problem zu lösen. Wenn das Problem weiterhin besteht, wenden
          Sie sich an die Support-Hotline.
        </p>
        <Button className={styles.button} variant='primary' onClick={onClickHanlder}>
          Neu laden
        </Button>
      </div>
    </Modal>
  );
};
