import { RootState } from '../../store.types';
import { InitializationStatus } from './app.types';

const isInitialized = (state: RootState): boolean => state.entities.app.isInitialized;

const getInitializationStatus = (state: RootState): InitializationStatus =>
  state.entities.app.initializationStatus;

const isInitializedWithSuccess = (state: RootState) =>
  state.entities.app.initializationStatus === InitializationStatus.succeeded;

export const appSelectors = {
  isInitialized,
  getInitializationStatus,
  isInitializedWithSuccess,
};
