import { ajax, AjaxError } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { buildDefaultHeaders, buildEndpoint, ENDPOINTS } from '../../../../services';
import { offerPackagesActions, OfferPackagesActionsTypes } from './packages.actions';
import { RootActions, RootState } from '../../../store.types';

export const onUpdatePackagesRequest: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferPackagesActionsTypes.UPDATE_PACKAGES_TRIGGER),
    mergeMap((action) => {
      return ajax({
        url: buildEndpoint(ENDPOINTS.updatePackages),
        method: 'PUT',
        body: action.payload,
        headers: buildDefaultHeaders(),
        withCredentials: true,
      }).pipe(
        map((data) => offerPackagesActions.updatePackages.success(data.response)),
        catchError((error: AjaxError) => of(offerPackagesActions.updatePackages.failure(error)))
      );
    })
  );

export const offerPackagesEpics = combineEpics(onUpdatePackagesRequest);
