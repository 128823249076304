import { createSelector } from 'reselect';
import { ApiRequestStatus, Option } from '../../../../types';
import { OfferPackage, OfferPackagesIds } from './packages.types';
import { RootState } from '../../../store.types';

const getPackage = (
  state: RootState,
  variantId: string,
  packageId: OfferPackagesIds
): OfferPackage | null => {
  const variantPackages = state.entities.offer.packages.packagesByVariantId[variantId] || null;

  if (variantPackages === null) {
    return null;
  }

  const packageObj = variantPackages.packagesById[packageId];

  return packageObj || null;
};

const makeGetPackageInsuredPersonsIds = () =>
  createSelector(getPackage, (packageObj): string[] => packageObj?.insuredPersonsIds ?? []);

const makeGetPackageOptions = () =>
  createSelector(getPackage, (packageObj): Option[] => packageObj?.options ?? []);

const makeGetPackageSelected = () =>
  createSelector(getPackage, (packageObj): boolean => packageObj?.selected ?? false);

const makeGetPackageValue = () =>
  createSelector(getPackage, (packageObj): string => packageObj?.value ?? '');

const getUpdatePackagesStatus = (state: RootState): ApiRequestStatus =>
  state.entities.offer.packages.updatePackagesStatus;

const hasUpdatePackagesRequestFailed = createSelector<RootState, ApiRequestStatus, boolean>(
  getUpdatePackagesStatus,
  (status) => status === ApiRequestStatus.failed
);

const isUpdatePackagesRequestInprogress = createSelector<RootState, ApiRequestStatus, boolean>(
  getUpdatePackagesStatus,
  (status) => status === ApiRequestStatus.pending
);

export const offerPackagesSelectors = {
  getPackage,
  makeGetPackageInsuredPersonsIds,
  makeGetPackageOptions,
  makeGetPackageSelected,
  makeGetPackageValue,
  getUpdatePackagesStatus,
  hasUpdatePackagesRequestFailed,
  isUpdatePackagesRequestInprogress,
};
