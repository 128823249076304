import React from 'react';
import { BundleLoader } from '@disorder/microfrontend-bundle-loader';
import { MicroPaymentDataProps } from '@disorder/micro-payment-data-models';
import {
  CONFIG_MICRO_PAYMENT_DATA_BACKEND_URL,
  CONFIG_MICRO_PAYMENT_DATA_BUNDLE_URL,
} from '../../config';
import { MicroPaymentFrontendProps } from './MicroPaymentFrontend.types';

export const MicroPaymentFrontend: React.FC<MicroPaymentFrontendProps> = ({
  customProps,
  eventBus,
}) => {
  return (
    <BundleLoader<MicroPaymentDataProps>
      bundleUrl={CONFIG_MICRO_PAYMENT_DATA_BUNDLE_URL}
      customProps={{
        backendUrl: CONFIG_MICRO_PAYMENT_DATA_BACKEND_URL,
        eventBus: eventBus,
        customProps: {
          businessId: customProps.businessId,
          accountOwnerId: customProps.accountOwnerId,
          accountOwnerFullName: customProps.accountOwnerFullName,
        },
      }}
    />
  );
};
