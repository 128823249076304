import React from 'react';
import classNames from 'classnames';
import ControlWithHint from '@eg/elements/ControlWithHint';
import { FieldError } from './FieldError';
import { FieldProps } from './Fields.types';
import { labels } from './labels';
import { useFieldTouched } from './hooks/useFieldTouched';
import { useFieldValidation } from './hooks/useFieldValidation';
import styles from './Field.module.scss';

export const Field: React.FC<FieldProps> = ({
  children,
  className = '',
  errors = [],
  label = '',
  required = false,
  allowEmptyValue = false,
  disabled,
}: FieldProps) => {
  const { onBlurHandler } = useFieldTouched(children);
  const hasEmptyValue = !useFieldValidation(children);

  const extendedErrors = [
    ...errors,
    ...(!allowEmptyValue && hasEmptyValue ? [labels.mandatoryField] : []),
  ];

  const hasValueAndOrIsRequired = !hasEmptyValue || required;

  const errorMsg =
    hasValueAndOrIsRequired && extendedErrors && extendedErrors.length ? (
      <FieldError errors={extendedErrors} />
    ) : (
      false
    );

  return (
    <div className={classNames(styles.root, className)}>
      <ControlWithHint error={errorMsg}>
        <>
          {React.Children.map(children, (child) =>
            React.isValidElement(child)
              ? React.cloneElement(child, {
                  ...child.props,
                  ...(disabled !== undefined && { disabled }),
                  onBlur: onBlurHandler,
                } as React.HTMLProps<HTMLInputElement>)
              : child
          )}
          {!!label && (
            <span title={label} className={styles.label}>
              {label}
            </span>
          )}
        </>
      </ControlWithHint>
    </div>
  );
};
