import { AjaxError } from 'rxjs/ajax';
import { ApiRequestStatus } from '../../../../types';
import { FullOfferBFF } from '../../app';
import { Option } from '../../../../types/Option';

export enum OfferPackagesIds {
  DEDUCTIBLE = 'HP-PRIVAT', // Selbstbeteiligung
  STARTING_BONUS = 'Startbonus', // Startzuzahlung unter 36J 13%
  KEY_LOSS = '004615', // Schlüsselverlust
  REPLACEMENT_COMPENSATION = '004623', // Neuwertentschädigung
  UNPAID_DEBT = '004619', // Forderungsausfall
  OFFICIAL_AND_PUBLIC_LIABILITY = '004624', // Amts und Diensthaftpflicht
  MONOPARENTAL_FAMILY = '000539', // Alleinstehender Familienangehöriger
  // to be clarified: '000387'
}

export type OfferPackageValue = string | null;

export type OfferPackage = {
  id: OfferPackagesIds;
  selected: boolean;
  insuredPersonsIds?: string[];
  options: Option[];
  value: OfferPackageValue;
};

export type OfferPackagesState = {
  error: AjaxError | null;
  packagesByVariantId: Record<string, OfferVariantPackages>;
  updatePackagesStatus: ApiRequestStatus;
};

export type OfferVariantPackages = {
  ids: OfferPackagesIds[];
  packagesById: Partial<Record<OfferPackagesIds, OfferPackage>>;
};

export type UpdatePackageValue = boolean | string | string[];

export enum OfferPackageFieldsIds {
  selected = 'SELECTED',
  option = 'OPTION',
  personsIds = 'PERSONS_IDS',
}

export type UpdatePackage = {
  variantId: string;
  packageId: string;
  fieldId: OfferPackageFieldsIds;
  value: UpdatePackageValue;
};

export type UpdatePackagesApiRequest = UpdatePackage[];

export type UpdatePackagesApiResponse = FullOfferBFF;
