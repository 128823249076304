import { connect } from 'react-redux';
import { OfferPage as OfferPageComponent } from './OfferPage';
import { OfferPageStateProps } from './OfferPage.types';
import { appSelectors, offerSelectors, personsSelectors } from '../../store/entities';
import { RootState } from '../../store/store.types';

const mapStateToProps = (state: RootState): OfferPageStateProps => ({
  hasAgreements: offerSelectors.hasSelectedVariantAgreements(state),
  isAppInitialized: appSelectors.isInitialized(state),
  insuranceOwnerFullName: personsSelectors.getInsuranceOwnerFullName(state),
  insuranceSumIsAvailable: offerSelectors.isVariantPriceAvailable(state),
});

export const OfferPage = connect(mapStateToProps)(OfferPageComponent);
