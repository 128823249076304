import { combineEpics, Epic, ofType } from 'redux-observable';
import { zip } from 'rxjs';
import { map, filter, take } from 'rxjs/operators';
import {
  AppActionsTypes,
  OfferGeneralActionsTypes,
  offerGeneralSelectors,
  PersonsActionsTypes,
  personsSelectors,
} from '../../store/entities';
import { RootActions, RootState } from '../../store/store.types';
import { updateBirthdateErrorModalActions } from './UpdateBirthdateErrorModal.actions';
import { updateBirthdateErrorModalSelectors } from './UpdateBirthdateErrorModal.selectors';

/* checking if the PDS birthdate matches the offer birthdate in case we had some error
  previously when syncronizing the offer birthdate with the PDS birthdate */
export const onInitBirthdateValidation: Epic<RootActions, RootActions, RootState> = (
  action$,
  state$
) => {
  // when the app has loaded init data and the microPDS has loaded data
  return zip(
    action$.pipe(ofType(AppActionsTypes.GET_FULL_OFFER_SUCCESS)),
    // person change triggers after the initial PDS get
    action$.pipe(ofType(PersonsActionsTypes.PERSON_CHANGE))
  ).pipe(
    take(1),
    filter(
      () =>
        personsSelectors.getInsuranceOwnerBirthate(state$.value) !==
        offerGeneralSelectors.getBirthdate(state$.value)
    ),
    map(() => updateBirthdateErrorModalActions.open())
  );
};

export const onUpdateBirthdateError: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferGeneralActionsTypes.UPDATE_BIRTHDATE_FAILURE),
    map(() => updateBirthdateErrorModalActions.open())
  );

export const onUpdateBirthdateRequest: Epic<RootActions, RootActions, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    ofType(OfferGeneralActionsTypes.UPDATE_BIRTHDATE_TRIGGER),
    filter(() => updateBirthdateErrorModalSelectors.isOpened(state$.value)),
    map(() => updateBirthdateErrorModalActions.close())
  );

export const updateBirthdateErrorModalEpics = combineEpics(
  onInitBirthdateValidation,
  onUpdateBirthdateError,
  onUpdateBirthdateRequest
);
