import React from 'react';
import Button from '@eg/elements/Button';
import Modal from '@eg/elements/Modal';
import { UpdateBirthdateErrorModalProps } from './UpdateBirthdateErrorModal.types';
import styles from './UpdateBirthdateErrorModal.module.scss';

export const UpdateBirthdateErrorModal: React.FC<UpdateBirthdateErrorModalProps> = ({
  isOpened,
  updateBirthdate,
}) => {
  return (
    <Modal className={styles.root} open={isOpened} viewport='fixed'>
      <div className={styles.wrapper}>
        <h1>Beim Update des Geburtsdatums ist ein Fehler aufgetreten</h1>
        <p>
          Leider ist ein Fehler im Zusammenhang mit der Aktualisierung des Geburtsdatums
          aufgetreten. Drücken Sie die Taste, um die Aktualisierung des Geburtsdatums zu erzwingen,
          um das Problem zu lösen. Wenn das Problem weiterhin besteht, wenden Sie sich an die
          Support-Hotline.
        </p>
        <Button className={styles.button} variant='primary' onClick={updateBirthdate}>
          Geburtsdatum erzwingen
        </Button>
      </div>
    </Modal>
  );
};
