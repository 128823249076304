import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { partial } from 'lodash';
import { Deductible as DeductibleComponent } from './Deductible';
import {
  DeductibleDispatchProps,
  DeductibleMergeProps,
  DeductibleOwnProps,
  DeductibleStateProps,
} from './Deductible.types';
import {
  OfferPackageFieldsIds,
  offerPackagesActions,
  OfferPackagesIds,
  offerPackagesSelectors,
} from '../../../../store/entities';
import { offerSelectors } from '../../../../store/entities/offer/offer.selectors';
import { RootState } from '../../../../store/store.types';

const makeMapStateToProps = () => {
  const getPackageOptions = offerPackagesSelectors.makeGetPackageOptions();
  const getPackageValue = offerPackagesSelectors.makeGetPackageValue();

  const mapStateToProps = (
    state: RootState,
    ownProps: DeductibleOwnProps
  ): DeductibleStateProps => {
    return {
      hasUpdatePackagesRequestFailed: offerPackagesSelectors.hasUpdatePackagesRequestFailed(state),
      isAnyOfferRequestInProgress: offerSelectors.isAnyOfferRequestInProgress(state),
      options: getPackageOptions(state, ownProps.variantId, ownProps.packageId),
      value: getPackageValue(state, ownProps.variantId, ownProps.packageId),
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch: Dispatch): DeductibleDispatchProps => ({
  update: (
    variantId: string,
    packageId: OfferPackagesIds,
    fieldId: OfferPackageFieldsIds,
    value: string
  ) =>
    dispatch(
      offerPackagesActions.updatePackages.trigger([
        {
          variantId,
          packageId,
          fieldId,
          value,
        },
      ])
    ),
});

const mergeProps = (
  stateProps: DeductibleStateProps,
  dispatchProps: DeductibleDispatchProps,
  ownProps: DeductibleOwnProps
): DeductibleMergeProps => ({
  ...ownProps,
  ...stateProps,
  update: (value: string) =>
    partial(
      dispatchProps.update,
      ownProps.variantId,
      ownProps.packageId,
      OfferPackageFieldsIds.option
    )(value),
});

export const Deductible = connect(
  makeMapStateToProps,
  mapDispatchToProps,
  mergeProps
)(DeductibleComponent);
