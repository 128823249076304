import React from 'react';
import classNames from 'classnames';
import Radio from '@eg/elements/Radio';
import { noop } from 'lodash';
import { DatePicker } from './components';
import { RadioEditableDateProps } from './RadioEditableDate.types';
import styles from './RadioEditableDate.module.scss';

export const RadioEditableDate: React.FC<RadioEditableDateProps> = ({
  checked,
  className = '',
  disabled = false,
  disabledDateSelection = false,
  loading = false,
  date,
  label,
  maxDate,
  minDate,
  onChange = noop,
  onChangeDate = noop,
  value,
}) => (
  <div className={classNames(styles.root, className)}>
    <Radio
      {...(checked !== undefined && { checked })}
      value={value}
      onChange={onChange}
      disabled={disabled}
      label={
        <div className={styles.container}>
          <span>{label}</span>
          <DatePicker
            value={date}
            disabled={disabled || disabledDateSelection}
            onChange={onChangeDate}
            minDate={minDate}
            maxDate={maxDate}
            loading={loading}
          />
        </div>
      }
    />
  </div>
);
