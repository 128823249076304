import {
  UpdateBirthdateErrorModalActions,
  UpdateBirthdateErrorModalActionsTypes,
} from './UpdateBirthdateErrorModal.actions';
import { UpdateBirthdateErrorModalState } from './UpdateBirthdateErrorModal.types';

const defaultState: Readonly<UpdateBirthdateErrorModalState> = {
  opened: false,
};

export const updateBirthdateErrorModalReducer = (
  state: UpdateBirthdateErrorModalState = defaultState,
  action: UpdateBirthdateErrorModalActions
): UpdateBirthdateErrorModalState => {
  switch (action.type) {
    case UpdateBirthdateErrorModalActionsTypes.OPEN:
      return {
        ...state,
        opened: true,
      };

    case UpdateBirthdateErrorModalActionsTypes.CLOSE:
      return {
        ...state,
        opened: false,
      };

    default:
      return state;
  }
};
