import { connect } from 'react-redux';
import { RootState } from '../../store/store.types';
import { PaymentDataDispatchProps, PaymentDataStateProps } from './PaymentData.types';
import { PaymentData as PaymentDataComponent } from './PaymentData';
import { offerGeneralSelectors, personsSelectors } from '../../store/entities';
import { Dispatch } from 'redux';
import { offerPaymentActions } from '../../store/entities/offer/payment/payment.actions';
import { UpdatePaymentRequest } from '../../store/entities/offer/payment/paymet.types';
import { retryLastActionModalActions } from '../RetryLastActionModal';
import { paymentSelectors } from '../../store/entities/offer/payment/payment.selectors';

const mapStateToProps = (state: RootState): PaymentDataStateProps => ({
  businessId: offerGeneralSelectors.getBusinessId(state),
  insuranceOwner: personsSelectors.getInsuranceOwner(state),
  paymentId: paymentSelectors.getPaymentId(state),
});

const mapDispatchToProps = (dispatch: Dispatch): PaymentDataDispatchProps => ({
  updatePayment: (updatePaymentPayload: UpdatePaymentRequest) =>
    dispatch(offerPaymentActions.updatePayment.trigger(updatePaymentPayload)),
  openModalError: () => dispatch(retryLastActionModalActions.open()),
});

export const PaymentData = connect(mapStateToProps, mapDispatchToProps)(PaymentDataComponent);
