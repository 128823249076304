import { connect } from 'react-redux';
import { InsuredPersonOfferDetails as InsuredPersonOfferDetailsComponent } from './InsuredPersonOfferDetails';
import { InsuredPersonOfferDetailsStateProps } from './InsuredPersonOfferDetails.types';
import { offerVariantsSelectors, personsSelectors } from '../../store/entities';
import { RootState } from '../../store/store.types';

const mapStateToProps = (state: RootState): InsuredPersonOfferDetailsStateProps => ({
  initialPersonsIdsForOfficialAndPublicLiabilityPackage: [
    personsSelectors.getInsuranceOwnerId(state),
  ],
  initialPersonsIdsForMonoparentalPackage: personsSelectors.getFamilyMembersIds(state).slice(0, 1),
  selectedVariantId: offerVariantsSelectors.getSelectedId(state) ?? '',
  variantIds: offerVariantsSelectors.getAllIds(state),
});

export const InsuredPersonOfferDetails = connect(mapStateToProps)(
  InsuredPersonOfferDetailsComponent
);
