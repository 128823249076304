import { ApiRequestStatus } from '../../../../types';
import { AppActions, AppActionsTypes } from '../../app';
import { Flow } from '../offer.types';
import { OfferFieldsActions, OfferFieldsActionsTypes } from '../fields';
import { OfferGeneralActions, OfferGeneralActionsTypes } from './general.actions';
import { offerGeneralHelper } from './general.helper';
import { OfferGeneralState } from './general.types';
import { OfferPackagesActions, OfferPackagesActionsTypes } from '../packages';
import { OfferVariantsActions, OfferVariantsActionsTypes } from '../variants';

const defaultState: Readonly<OfferGeneralState> = {
  businessId: '',
  productName: '',
  actionNr: '',
  adsId: '',
  consultationProtocolChannelDirection: null,
  consultationProtocolId: null,
  offerStatus: Flow.NEW_BUSINESS,
  offerSuccessfullyClosed: false,
  salesProcess: null,
  salesModel: null,
  creatorNr: '',
  birthdate: '',

  updateBirthdateStatus: ApiRequestStatus.idle,
  updateBirthdateError: null,

  finaliseOfferStatus: ApiRequestStatus.idle,
  finaliseOfferError: null,

  updateBaseStatus: ApiRequestStatus.idle,
  updateBaseError: null,
};

export const offerGeneralReducer = (
  state: OfferGeneralState = defaultState,
  action:
    | AppActions
    | OfferFieldsActions
    | OfferGeneralActions
    | OfferPackagesActions
    | OfferVariantsActions
): OfferGeneralState => {
  switch (action.type) {
    case AppActionsTypes.GET_FULL_OFFER_SUCCESS:
    case OfferFieldsActionsTypes.UPDATE_FIELDS_SUCCESS:
    case OfferPackagesActionsTypes.UPDATE_PACKAGES_SUCCESS:
    case OfferVariantsActionsTypes.SELECT_VARIANT_SUCCESS:
      return {
        ...state,
        ...offerGeneralHelper.mapOfferBFFToPartialOfferGeneralState(action.payload.offer),
      };

    case OfferGeneralActionsTypes.UPDATE_BIRTHDATE_SUCCESS:
      return {
        ...state,
        ...offerGeneralHelper.mapOfferBFFToPartialOfferGeneralState(action.payload.offer),
        updateBirthdateStatus: ApiRequestStatus.succeeded,
        updateBirthdateError: null,
      };

    case OfferGeneralActionsTypes.UPDATE_BASE_SUCCESS:
      return {
        ...state,
        ...offerGeneralHelper.mapOfferBFFToPartialOfferGeneralState(action.payload.offer),
        updateBaseStatus: ApiRequestStatus.succeeded,
        updateBaseError: null,
      };

    case OfferGeneralActionsTypes.UPDATE_BIRTHDATE_TRIGGER:
      return {
        ...state,
        updateBirthdateStatus: ApiRequestStatus.pending,
      };

    case OfferGeneralActionsTypes.UPDATE_BIRTHDATE_FAILURE:
      return {
        ...state,
        updateBirthdateStatus: ApiRequestStatus.failed,
        updateBirthdateError: action.payload,
      };

    case OfferGeneralActionsTypes.FINALISE_OFFER_TRIGGER:
      return {
        ...state,
        finaliseOfferStatus: ApiRequestStatus.pending,
      };

    case OfferGeneralActionsTypes.FINALISE_OFFER_SUCCESS:
      return {
        ...state,
        offerSuccessfullyClosed: action.payload.messages?.length === 0,
        finaliseOfferStatus: ApiRequestStatus.succeeded,
        finaliseOfferError: null,
      };

    case OfferGeneralActionsTypes.FINALISE_OFFER_FAILURE:
      return {
        ...state,
        finaliseOfferStatus: ApiRequestStatus.failed,
        finaliseOfferError: action.payload,
      };

    case OfferGeneralActionsTypes.UPDATE_BASE_TRIGGER:
      return {
        ...state,
        updateBaseStatus: ApiRequestStatus.pending,
      };

    case OfferGeneralActionsTypes.UPDATE_BASE_FAILURE:
      return {
        ...state,
        updateBaseStatus: ApiRequestStatus.failed,
        updateBaseError: action.payload,
      };

    default:
      return state;
  }
};
