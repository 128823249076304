import React from 'react';
import { labels } from './Statics.labels';
import { StaticItem } from './components/StaticItem';
import { StaticsProps } from './Statics.types';
import styles from './Statics.module.scss';

export const Statics: React.FC<StaticsProps> = ({
  actionNumber,
  businessId,
  employeeNr,
  isProcessingFlow,
  oeNr,
  offerStatus,
  salesModel,
  salesProduct,
}) => (
  <div className={styles.root}>
    <StaticItem label={labels.actionNumber} value={actionNumber} />
    <StaticItem label={labels.salesProduct} value={salesProduct} />
    <StaticItem label={labels.oeNr} value={oeNr} />
    <StaticItem label={labels.employeeNr} value={employeeNr} />
    <StaticItem label={labels.salesModel} value={salesModel} />
    <StaticItem label={labels.offerStatus} value={offerStatus} />
    <StaticItem
      label={isProcessingFlow ? labels.agentDocumentId : labels.businessId}
      value={businessId}
    />
  </div>
);
