import { FatalErrorModalActions, FatalErrorModalActionsTypes } from './FatalErrorModal.actions';
import { FatalErrorModalState } from './FatalErrorModal.types';

const defaultState: Readonly<FatalErrorModalState> = {
  opened: false,
};

export const fatalErrorModalReducer = (
  state: FatalErrorModalState = defaultState,
  action: FatalErrorModalActions
): FatalErrorModalState => {
  switch (action.type) {
    case FatalErrorModalActionsTypes.OPEN:
      return {
        ...state,
        opened: true,
      };

    case FatalErrorModalActionsTypes.CLOSE:
      return {
        ...state,
        opened: false,
      };

    default:
      return state;
  }
};
