import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { partial } from 'lodash';
import { Agreements as AgreementsComponent } from './Agreements';
import { agreementsActions } from './Agreements.actions';
import {
  AgreementsDispatchProps,
  AgreementsMergeProps,
  AgreementsStateProps,
} from './Agreements.types';
import { agreementsSelectors } from './Agreements.selectors';
import {
  offerAgreementsActions,
  offerAgreementsSelectors,
  offerSelectors,
  offerVariantsSelectors,
} from '../../store/entities';
import { RootState } from '../../store/store.types';

const mapStateToProps = (state: RootState): AgreementsStateProps => ({
  agreements: offerSelectors.getSelectedVariantAgreements(state),
  hasUpdateAgreementsRequestFailed:
    offerAgreementsSelectors.hasUpdateAgreementsRequestFailed(state),
  isAnyOfferRequestInProgress: offerSelectors.isAnyOfferRequestInProgress(state),
  isInsuranceOwnerInformedAboutConclusion:
    agreementsSelectors.isInsuranceOwnerInformedAboutContractConlusion(state),

  selectedVariantId: offerVariantsSelectors.getSelectedId(state) ?? '',
});

const mapDispatchToProps = (dispatch: Dispatch): AgreementsDispatchProps => ({
  select: (variantId: string, agreementId: string, checked: boolean) =>
    dispatch(
      offerAgreementsActions.updateAgreements.trigger({
        variantId,
        agreements: [
          {
            id: agreementId,
            checked,
          },
        ],
      })
    ),
  setIsInsuranceOwnerInformedAboutConclusion: (isInsuranceOwnerInformedAboutConclusion: boolean) =>
    dispatch(
      agreementsActions.setIsInsuranceOwnerInformedAboutConclusion(
        isInsuranceOwnerInformedAboutConclusion
      )
    ),
});

const mergeProps = (
  stateProps: AgreementsStateProps,
  dispatchProps: AgreementsDispatchProps
): AgreementsMergeProps => {
  const { selectedVariantId, ...restStateProps } = stateProps;
  const { select, ...restDispatchProps } = dispatchProps;
  return {
    ...restStateProps,
    ...restDispatchProps,
    select: (agreementId: string, checked: boolean) =>
      partial(dispatchProps.select, selectedVariantId)(agreementId, checked),
  };
};

export const Agreements = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AgreementsComponent);
