import React, { useEffect, useState } from 'react';
import Radio from '@eg/elements/Radio';
import { Field, RadioGroup } from '../../../../components';
import { VariantSelectionProps } from './VariantSelection.types';
import styles from './VariantSelection.module.scss';

export const VariantSelection: React.FC<VariantSelectionProps> = ({
  hasSelectVariantRequestFailed,
  isAnyOfferRequestInProgress,
  select,
  selectedVariantId,
  variants,
}) => {
  const hasVariants = !!variants.length;
  const disabledField = isAnyOfferRequestInProgress || !hasVariants;
  const [selectedVariantIdState, setSelectedVariantIdState] = useState<string>(selectedVariantId);

  useEffect(() => {
    setSelectedVariantIdState(selectedVariantId);
  }, [hasSelectVariantRequestFailed, selectedVariantId]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSelectedVariantIdState(value);
    select(value);
  };

  return (
    <Field disabled={disabledField}>
      <RadioGroup
        className={styles.radioGroup}
        value={selectedVariantIdState}
        onChange={onChangeHandler}
      >
        {variants.map((variant) => (
          <Radio
            className={styles.radio}
            value={variant.id}
            label={variant.name}
            key={`variant.selection.option.${variant.id}`}
          />
        ))}
      </RadioGroup>
    </Field>
  );
};
