import { AjaxError } from 'rxjs/ajax';
import { ApiRequestStatus } from '../../../../types';
import { FullOfferBFF } from '../../app';
import { Option } from '../../../../types/Option';

export enum PaymentMethod {
  PER_MONTH = 'MONATLICH',
  QUARTERLY = 'VIERTELJAEHRLICH',
  HALF_YEARLY = 'HALBJAEHRLICH',
  YEARLY = 'JAEHRLICH',
}

export enum OfferFieldsIds {
  DURATION_OF_CONTRACT = 'durationOfContract',
  DEBIT_DAY = 'debitDay',
  INSURANCE_START_DATE = 'insuranceStartDate',
  MARITAL_STATUS = 'maritalStatus',
  PAYMENT_METHOD = 'paymentMethod',
}

export interface OfferFieldMinMaxExtension {
  min: Date;
  max: Date;
}

export type OfferFieldValue = string | number;

export interface OfferFieldOptionExtension {
  options: Option<OfferFieldValue>[];
}

export interface OfferField
  extends Partial<OfferFieldMinMaxExtension>,
    Partial<OfferFieldOptionExtension> {
  id: OfferFieldsIds;
  value: OfferFieldValue;
}

export type OfferFieldsState = {
  error: AjaxError | null;
  fieldsByVariantId: Record<string, OfferVariantFields>;
  updateFieldsStatus: ApiRequestStatus;
};

export type OfferVariantFields = {
  ids: OfferFieldsIds[];
  fieldsById: Partial<Record<OfferFieldsIds, OfferField>>;
};

export type UpdateField = {
  fieldId: string;
  value: OfferFieldValue;
};

export type UpdateFieldsApiRequest = UpdateField[];

export type UpdateFieldsApiResponse = FullOfferBFF;
