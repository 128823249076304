import React from 'react';
import classNames from 'classnames';
import { Agreements } from '../Agreements';
import { ConsultancyProtocol } from '../ConsultancyProtocol';
import { GeneralContract } from '../GeneralContract';
import { InitApp } from '../InitApp';
import { InsuredPersonOfferDetails } from '../InsuredPersonOfferDetails';
import { OfferPageProps } from './OfferPage.types';
import { PersonManagement } from '../PersonManagement';
import { StickySidebar } from '../StickySidebar';
import styles from './OfferPage.module.scss';
import { PaymentData } from '../PaymentData';
import { ReadOnlyMode } from '../ReadOnlyMode';
import { Accordion, ClosableAccordionTypes } from '../Accordions';

export const OfferPage: React.FC<OfferPageProps> = ({
  hasAgreements,
  isAppInitialized,
  insuranceSumIsAvailable,
  insuranceOwnerFullName,
}) => {
  return (
    <div className={styles.root}>
      <InitApp />
      {isAppInitialized && (
        <>
          <section className={styles.content}>
            <ReadOnlyMode>
              <Accordion summary='Personendaten' sectionKey={ClosableAccordionTypes.PERSON_DATA}>
                <PersonManagement />
              </Accordion>

              <Accordion
                summary='Allgemein'
                sectionKey={ClosableAccordionTypes.GENERAL_INFORMATION}
              >
                <GeneralContract />
              </Accordion>

              <Accordion
                summary={`Versicherte Person ${insuranceOwnerFullName}`}
                sectionKey={ClosableAccordionTypes.INSURED_PERSON_PACKAGES}
              >
                <InsuredPersonOfferDetails />
              </Accordion>

              <Accordion
                summary='Beratungsprotokoll'
                /* When the component is unmounted the IDD form resets.
              To avoid that the component is only hidden if there is no price available.
            */
                className={classNames({
                  [styles['idd--hidden']]: !insuranceSumIsAvailable,
                })}
                sectionKey={ClosableAccordionTypes.CONSULTATION_PROTOCOL}
              >
                <ConsultancyProtocol />
              </Accordion>

              {hasAgreements && (
                <Accordion summary='DA Hinweise' sectionKey={ClosableAccordionTypes.AGREEMENTS}>
                  <Agreements />
                </Accordion>
              )}
              <Accordion
                summary={'Bankverbindung'}
                sectionKey={ClosableAccordionTypes.PAYMENT_DATA}
              >
                <PaymentData />
              </Accordion>
            </ReadOnlyMode>
          </section>
          <StickySidebar />
        </>
      )}
    </div>
  );
};
