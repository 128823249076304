import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import { MonoparentalFamilyPerson as MonoparentalFamilyPersonComponent } from './MonoparentalFamilyPerson';
import { MonoparentalFamilyPersonStateProps } from './MonoparentalFamilyPerson.types';
import { offerPackagesSelectors, personsSelectors } from '../../../../store/entities';
import { RootState } from '../../../../store/store.types';
import { WithPackageInsuredPersonsIds } from '../WithPackageInsuredPersonsIds';

const mapStateToProps = (state: RootState): MonoparentalFamilyPersonStateProps => ({
  familyMembersIds: personsSelectors.getFamilyMembersIds(state),
  hasUpdatePackagesRequestFailed: offerPackagesSelectors.hasUpdatePackagesRequestFailed(state),
});

export const MonoparentalFamilyPerson = flowRight(
  WithPackageInsuredPersonsIds,
  connect(mapStateToProps)
)(MonoparentalFamilyPersonComponent);
