import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { offerSelectors } from '../../../../store/entities/offer/offer.selectors';
import { offerVariantsActions, offerVariantsSelectors } from '../../../../store/entities';
import { RootState } from '../../../../store/store.types';
import { VariantSelection as VariantSelectionComponent } from './VariantSelection';
import {
  VariantSelectionDispatchProps,
  VariantSelectionStateProps,
} from './VariantSelection.types';

const mapStateToProps = (state: RootState): VariantSelectionStateProps => ({
  hasSelectVariantRequestFailed: offerVariantsSelectors.hasSelectVariantRequestFailed(state),
  isAnyOfferRequestInProgress: offerSelectors.isAnyOfferRequestInProgress(state),
  selectedVariantId: offerVariantsSelectors.getSelectedId(state) ?? '',
  variants: offerVariantsSelectors.getAllVariants(state),
});

const mapDispatchToProps = (dispatch: Dispatch): VariantSelectionDispatchProps => ({
  select: (variantId: string) => dispatch(offerVariantsActions.selectVariant.trigger(variantId)),
});

export const VariantSelection = connect(
  mapStateToProps,
  mapDispatchToProps
)(VariantSelectionComponent);
