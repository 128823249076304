import React from 'react';
import YesNoInput from '@eg/elements/YesNoInput';
import { Agreement } from './components';
import { AgreementsProps } from './Agreements.types';
import styles from './Agreements.module.scss';

export const Agreements: React.FC<AgreementsProps> = ({
  agreements,
  hasUpdateAgreementsRequestFailed,
  isAnyOfferRequestInProgress,
  isInsuranceOwnerInformedAboutConclusion,
  select,
  setIsInsuranceOwnerInformedAboutConclusion,
}) => {
  return (
    <section className={styles.root}>
      <p className={styles.description}>
        Sie erhalten alle detaillierten Informationen sowie die Vertragsunterlagen nach
        Vertragsabschluss. Auf Wunsch stellen wir Ihnen diese auch vorab zur Verfügung. Für den
        Abschluss am Telefon beschränken wir uns auf die wichtigsten Informationen. Sind Sie damit
        einverstanden?
      </p>

      <YesNoInput
        name='agreements-acceptance-confirmation'
        value={isInsuranceOwnerInformedAboutConclusion}
        onChange={setIsInsuranceOwnerInformedAboutConclusion}
      />

      {isInsuranceOwnerInformedAboutConclusion && (
        <div className={styles.agreements}>
          {agreements.map((agreement) => (
            <Agreement
              key={`agreement.${agreement.id}`}
              agreement={agreement}
              isLoading={isAnyOfferRequestInProgress}
              hasRequestFailed={hasUpdateAgreementsRequestFailed}
              select={select}
            />
          ))}
        </div>
      )}
    </section>
  );
};
