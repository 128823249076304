import React from 'react';
import { SectionProps } from './Section.types';
import styles from './Section.module.scss';

export const Section: React.FC<SectionProps> = ({ children, title }) => {
  const hasChildren = !!children;

  return (
    <section className={styles.root}>
      <div className={styles.title}>
        <strong>{title}</strong>
      </div>
      {hasChildren && <div className={styles.content}>{children}</div>}
    </section>
  );
};
