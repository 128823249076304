import { AgencyBFF, AgencyState } from './agency.types';

const mapAgencyBFFToAgencyState = (agency?: AgencyBFF): AgencyState => {
  return {
    id: agency?.id ?? '',
    oeNr: agency?.oeNr ?? '',
    agentName: agency?.agentName ?? '',
    agentNumber: agency?.agentNumber ?? '',
    distributionChannel: agency?.distributionChannel ?? '',
    departmentNumber: agency?.departmentNumber ?? '',
    companyNumber: agency?.companyNumber ?? '',
  };
};

export const agencyHelper = {
  mapAgencyBFFToAgencyState,
};
