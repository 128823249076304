import React, { useEffect, useRef, useState } from 'react';
import { ReadOnlyModeProps } from './ReadOnlyMode.types';
import styles from './ReadOnlyMode.module.scss';

export const ReadOnlyMode: React.FC<ReadOnlyModeProps> = ({ children, readOnly = false }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasListeners, setHasListeners] = useState<boolean>(false);

  const keyboardHandler = (event: KeyboardEvent) => {
    event.preventDefault();
    event.stopImmediatePropagation();
  };

  useEffect(() => {
    if (readOnly && !hasListeners) {
      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/inert
      containerRef?.current?.setAttribute('inert', '');
      containerRef?.current?.addEventListener('keydown', keyboardHandler);
      containerRef?.current?.addEventListener('keyup', keyboardHandler);
      containerRef?.current?.addEventListener('keypress', keyboardHandler);
      setHasListeners(true);
    } else if (!readOnly && hasListeners) {
      containerRef?.current?.removeAttribute('inert');
      containerRef?.current?.removeEventListener('keydown', keyboardHandler);
      containerRef?.current?.removeEventListener('keyup', keyboardHandler);
      containerRef?.current?.removeEventListener('keypress', keyboardHandler);
      setHasListeners(false);
    }
  }, [hasListeners, readOnly]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      containerRef?.current?.removeEventListener('keydown', keyboardHandler);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      containerRef?.current?.removeEventListener('keyup', keyboardHandler);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      containerRef?.current?.removeEventListener('keypress', keyboardHandler);
    };
  }, []);

  if (!readOnly) {
    return <>{children}</>;
  }

  return (
    <div className={styles.root} ref={containerRef}>
      {children}
    </div>
  );
};
