import { OfferFieldValue, uPdsPersonWithValidation } from '../../store/entities';

export interface PersonManagementStateProps {
  businessId: string;
  insuranceStartDate: string;
  insuranceOwner: uPdsPersonWithValidation;
  insuranceOwnerId: string;
  readOnly: boolean;
}

export type PersonManagementProps = PersonManagementStateProps;

export const isString = (value: OfferFieldValue): value is string => typeof value === 'string';
