import { PersonSalutation, ValidationStatus } from '@disorder/personal-data-models';
import { AppActions, AppActionsTypes } from '../app';
import { personHelper } from './person.helper';
import { PersonsActions, PersonsActionsTypes } from './persons.actions';
import { PersonsState } from './persons.types';

const defaultState: Readonly<PersonsState> = {
  insuranceOwnerId: '',
  insuranceOwnerPartnerId: '',
  familyMembersIds: [],
  insuranceOwner: {
    personId: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    phoneAreaPrefix: '',
    personalEmail: '',
    nationality: '',
    birthPlace: '',
    birthDate: '',
    salutation: PersonSalutation.UNKNOWN,
    phoneInternationalPrefix: '',
    pdsValidationStatus: ValidationStatus.UNKNOWN,
    isValid: false,
    title: '',
    address: {
      addressAdditionalInfo: '',
      buildingAdditionalInfo: '',
      buildingNumber: '',
      city: '',
      cityAdditionalInfo: '',
      postCode: '',
      streetName: '',
    },
  },
  insuranceOwnerValidationErrors: [],
};

export const personsReducer = (
  state: PersonsState = defaultState,
  action: PersonsActions | AppActions
): PersonsState => {
  switch (action.type) {
    case AppActionsTypes.GET_FULL_OFFER_SUCCESS:
      return {
        ...state,
        insuranceOwnerId: personHelper.getInsuranceOwnerFromOffer(action.payload),
        insuranceOwnerPartnerId: personHelper.getInsuranceOwnerPartnerFromOffer(action.payload),
        familyMembersIds: personHelper.getFamilyMembersIdsFromOffer(action.payload),
      };
    case PersonsActionsTypes.PERSON_CHANGE:
      return {
        ...state,
        insuranceOwner: {
          ...state.insuranceOwner,
          ...action.payload.person,
          pdsValidationStatus: action.payload.pdsValidationStatus,
        },
        insuranceOwnerValidationErrors:
          personHelper.mapPersonChangeToValidationErrors(state.insuranceOwnerId, action.payload) ||
          state.insuranceOwnerValidationErrors,
      };
    default:
      return state;
  }
};
