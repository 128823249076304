import { AppActions, AppActionsTypes } from '../../app';
import { offerFeesHelper } from './fees.helper';
import { OfferFeesState } from './fees.types';
import { OfferFieldsActions, OfferFieldsActionsTypes } from '../fields';
import { OfferGeneralActions, OfferGeneralActionsTypes } from '../general';
import { OfferPackagesActions, OfferPackagesActionsTypes } from '../packages';
import { OfferVariantsActions, OfferVariantsActionsTypes } from '../variants';

const defaultState: Readonly<OfferFeesState> = {
  fees: {},
};

export const offerFeesReducer = (
  state: OfferFeesState = defaultState,
  action:
    | AppActions
    | OfferFieldsActions
    | OfferGeneralActions
    | OfferPackagesActions
    | OfferVariantsActions
): OfferFeesState => {
  switch (action.type) {
    case AppActionsTypes.GET_FULL_OFFER_SUCCESS:
    case OfferFieldsActionsTypes.UPDATE_FIELDS_SUCCESS:
    case OfferGeneralActionsTypes.UPDATE_BIRTHDATE_SUCCESS:
    case OfferPackagesActionsTypes.UPDATE_PACKAGES_SUCCESS:
    case OfferVariantsActionsTypes.SELECT_VARIANT_SUCCESS:
      return {
        ...state,
        fees: offerFeesHelper.mapOfferBFFToOfferFeesSet(action.payload.offer),
      };

    default:
      return state;
  }
};
