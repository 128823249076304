import { ActionsUnion, createAction } from '../../services';

export enum AgreementsActionsTypes {
  SET_IS_INSURANCE_OWNER_INFORMED_ABOUT_CONCLUSION = '[ui][agreements] SET_IS_INSURANCE_OWNER_INFORMED_ABOUT_CONCLUSION',
}

const setIsInsuranceOwnerInformedAboutConclusion = (
  isInsuranceOwnerInformedAboutConclusion: boolean
) =>
  createAction(
    AgreementsActionsTypes.SET_IS_INSURANCE_OWNER_INFORMED_ABOUT_CONCLUSION,
    isInsuranceOwnerInformedAboutConclusion
  );

/**
 * combining and exporting of sync & async actions
 */

const actions = {
  setIsInsuranceOwnerInformedAboutConclusion,
};

const asyncActions = {};

export const agreementsActions = {
  ...actions,
  ...asyncActions,
};

export type AgreementsActions = ActionsUnion<typeof actions, typeof asyncActions>;
