import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { rootEpic } from './root.epic';
import { rootReducer } from './root.reducer';
import { RootActions, RootState } from './store.types';

export const history = createBrowserHistory();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const epicMiddleware = createEpicMiddleware<RootActions, RootActions, RootState>();
const middlewares: Middleware[] = [routerMiddleware(history), epicMiddleware];
const store = createStore(rootReducer(), composeEnhancers(applyMiddleware(...middlewares)));

epicMiddleware.run(rootEpic);

export default store;
