import { combineEpics, Epic, ofType } from 'redux-observable';
import { RootActions, RootState } from '../../../store.types';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ajax, AjaxError } from 'rxjs/ajax';
import { buildDefaultHeaders, buildEndpoint, ENDPOINTS } from '../../../../services';
import { of } from 'rxjs';
import { offerPaymentActions, OfferPaymentActionsTypes } from './payment.actions';

export const onPaymentUpdate: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferPaymentActionsTypes.UPDATE_PAYMENT_TRIGGER),
    mergeMap((action) => {
      return ajax({
        url: buildEndpoint(ENDPOINTS.updatePayment, {
          businessId: action.payload.businessId,
        }),
        method: 'PUT',
        body: action.payload,
        headers: buildDefaultHeaders(),
        withCredentials: true,
      }).pipe(
        map((data) => offerPaymentActions.updatePayment.success(data.response)),
        catchError((error: AjaxError) => of(offerPaymentActions.updatePayment.failure(error)))
      );
    })
  );

export const offerPaymentEpics = combineEpics(onPaymentUpdate);
