import React from 'react';
import classNames from 'classnames';
import Radio from '@eg/elements/Radio';
import { RadioStaticDateProps } from './RadioStaticDate.types';
import styles from './RadioStaticDate.module.scss';

export const RadioStaticDate: React.FC<RadioStaticDateProps> = ({
  checked,
  className = '',
  disabled = false,
  labelDate,
  labelText,
  onChange,
  value,
}) => {
  return (
    <div className={classNames(styles.root, className)}>
      <Radio
        value={value}
        disabled={disabled}
        label={
          <div className={styles.container}>
            <span>{labelText}</span>
            <span>{labelDate}</span>
          </div>
        }
        {...(onChange && { onChange })}
        {...(checked !== undefined && { checked })}
      />
    </div>
  );
};
