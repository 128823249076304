import React, { useEffect, useState } from 'react';
import {
  MicroPaymentData,
  MicroPaymentDataEvent,
  PaymentDataPayload,
} from '@disorder/micro-payment-data-models';
import { PaymentDataProps } from './PaymentData.types';
import { MicroPaymentFrontend } from '../../components/MicroPaymentFrontend';
import { usePrevious } from '../../hooks/usePrevious';
import { StreamEventBus } from '@disorder/microfrontend-event-bus';

export const PaymentData: React.FC<PaymentDataProps> = ({
  businessId,
  insuranceOwner,
  paymentId,
  updatePayment,
  openModalError,
}) => {
  const [eventBus] = useState(new StreamEventBus<MicroPaymentData>());
  const insuranceOwnerFullName = `${insuranceOwner.firstName} ${insuranceOwner.lastName}`.trim();
  const prevInsuranceOwnerFullName = usePrevious(insuranceOwnerFullName);
  const anyRequiredPropIsEmpty =
    !businessId ||
    !insuranceOwner.firstName ||
    !insuranceOwner.lastName ||
    !insuranceOwner.personId;

  useEffect(() => {
    const unsubscribeOnCreate = eventBus.once(MicroPaymentDataEvent.ON_CREATE, (data) => {
      updatePayment({
        paymentId: data.paymentData.id,
        personId: insuranceOwner.personId,
        businessId,
      });
    });
    const unsubscribeOnUpdate = eventBus.on(MicroPaymentDataEvent.ON_UPDATE, (data) => {
      const isPaymentIdNotExistsInOffer = !paymentId;
      if (isPaymentIdNotExistsInOffer) {
        updatePayment({
          paymentId: data.paymentData.id,
          personId: insuranceOwner.personId,
          businessId,
        });
      }
    });
    const unsubscribeOnError = eventBus.on(MicroPaymentDataEvent.ON_ERROR, () => {
      openModalError();
    });

    return () => {
      unsubscribeOnCreate();
      unsubscribeOnUpdate();
      unsubscribeOnError();
    };
  }, [eventBus, businessId, paymentId, insuranceOwner, openModalError, updatePayment]);

  useEffect(() => {
    const doesExistingFullNameChanged =
      !!prevInsuranceOwnerFullName && prevInsuranceOwnerFullName !== insuranceOwnerFullName;
    if (doesExistingFullNameChanged) {
      eventBus.emit(MicroPaymentDataEvent.ON_UPDATE_PAYMENT, {
        paymentData: { accountOwnerFullName: insuranceOwnerFullName },
      } as PaymentDataPayload);
    }
  }, [eventBus, insuranceOwnerFullName, prevInsuranceOwnerFullName]);

  if (anyRequiredPropIsEmpty) {
    return <>Vor- und Nachname des Versicherungsinhabers müssen ausgefüllt werden</>;
  }
  return (
    <>
      <MicroPaymentFrontend
        eventBus={eventBus}
        customProps={{
          businessId: businessId,
          accountOwnerFullName: insuranceOwnerFullName,
          accountOwnerId: insuranceOwner.personId,
        }}
      />
    </>
  );
};
