import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { partial } from 'lodash';
import {
  OfferPackageFieldsIds,
  offerPackagesActions,
  OfferPackagesIds,
  offerPackagesSelectors,
  offerVariantsSelectors,
} from '../../../../store/entities';
import { offerSelectors } from '../../../../store/entities/offer/offer.selectors';
import { RootState } from '../../../../store/store.types';
import {
  WithUpdateAllVariantsDispatchProps,
  WithUpdateAllVariantsMergeProps,
  WithUpdateAllVariantsOwnProps,
  WithUpdateAllVariantsStateProps,
} from './WithUpdateAllVariants.types';

const makeMapStateToProps = () => {
  const getPackageSelected = offerPackagesSelectors.makeGetPackageSelected();

  const mapStateToProps = (
    state: RootState,
    ownProps: WithUpdateAllVariantsOwnProps
  ): WithUpdateAllVariantsStateProps => {
    return {
      hasUpdatePackagesRequestFailed: offerPackagesSelectors.hasUpdatePackagesRequestFailed(state),
      isAnyOfferRequestInProgress: offerSelectors.isAnyOfferRequestInProgress(state),
      selected: getPackageSelected(state, ownProps.variantIds[0], ownProps.packageId),
      variantIds: offerVariantsSelectors.getAllIds(state),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch: Dispatch): WithUpdateAllVariantsDispatchProps => ({
  select: (
    variantIds: string[],
    packageId: OfferPackagesIds,
    personsIds: string[],
    selected: boolean
  ) => {
    let updatePackagesPayload = [];

    if (selected) {
      updatePackagesPayload = variantIds.map((variantId) => {
        return {
          variantId,
          packageId,
          fieldId: OfferPackageFieldsIds.personsIds,
          value: personsIds,
        };
      });
    } else {
      updatePackagesPayload = variantIds.map((variantId) => {
        return {
          variantId,
          packageId,
          fieldId: OfferPackageFieldsIds.selected,
          value: selected,
        };
      });
    }

    return dispatch(offerPackagesActions.updatePackages.trigger(updatePackagesPayload));
  },
});

const mergeProps = (
  stateProps: WithUpdateAllVariantsStateProps,
  dispatchProps: WithUpdateAllVariantsDispatchProps,
  ownProps: WithUpdateAllVariantsOwnProps
): WithUpdateAllVariantsMergeProps => ({
  ...ownProps,
  ...stateProps,
  select: (selected: boolean) =>
    partial(
      dispatchProps.select,
      ownProps.variantIds,
      ownProps.packageId,
      ownProps.initialPersonsIds
    )(selected),
});

const connector = connect(makeMapStateToProps, mapDispatchToProps, mergeProps);

export const WithUpdateAllVariants = (
  Component: React.ComponentType<ConnectedProps<typeof connector>>
) => connector(Component);
