import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import { RootState } from '../../../../store/store.types';
import { OfficialAndPublicLiabilityPerson as OfficialAndPublicLiabilityComponent } from './OfficialAndPublicLiabilityPerson';
import {
  isMaritalStatusValue,
  MaritalStatus,
  OfficialAndPublicLiabilityPersonStateProps,
} from './OfficialAndPublicLiabilityPerson.types';
import { WithPackageInsuredPersonsIds } from '../WithPackageInsuredPersonsIds';
import {
  OfferFieldsIds,
  offerFieldsSelectors,
  offerPackagesSelectors,
  offerVariantsSelectors,
  personsSelectors,
} from '../../../../store/entities';

const mapStateToProps = (state: RootState): OfficialAndPublicLiabilityPersonStateProps => {
  const variantId = offerVariantsSelectors.getSelectedId(state) || '';
  const maritalStatusValue =
    offerFieldsSelectors.getField(state, variantId, OfferFieldsIds.MARITAL_STATUS)?.value ?? '';
  const maritalStatus = isMaritalStatusValue(maritalStatusValue)
    ? maritalStatusValue
    : MaritalStatus.SINGLE;

  return {
    hasUpdatePackagesRequestFailed: offerPackagesSelectors.hasUpdatePackagesRequestFailed(state),
    insuranceOwnerId: personsSelectors.getInsuranceOwnerId(state),
    insuranceOwnerPartnerId: personsSelectors.getInsuranceOwnerPartnerId(state),
    maritalStatus: maritalStatus,
  };
};

export const OfficialAndPublicLiabilityPerson = flowRight(
  WithPackageInsuredPersonsIds,
  connect(mapStateToProps)
)(OfficialAndPublicLiabilityComponent);
