import { ActionsUnion, createAction } from '../../services';

export enum RetryLastActionModalActionsTypes {
  OPEN = '[ui][retry-last-action-modal] OPEN',
  CLOSE = '[ui][retry-last-action-modal] CLOSE',
}

const open = () => createAction(RetryLastActionModalActionsTypes.OPEN);
const close = () => createAction(RetryLastActionModalActionsTypes.CLOSE);

/**
 * combining and exporting of sync & async actions
 */

const actions = {
  open,
  close,
};

const asyncActions = {};

export const retryLastActionModalActions = {
  ...actions,
  ...asyncActions,
};

export type RetryLastActionModalActions = ActionsUnion<typeof actions, typeof asyncActions>;
