import React from 'react';
import { ValidationIconProps } from './ValidationIcon.types';
import RefreshIcon from '@eg/elements/Icons/RefreshIcon';
import CloseBorderIcon from '@eg/elements/Icons/CloseBorderIcon';
import CheckIcon from '@eg/elements/Icons/CheckIcon';
import { ValidationStatus } from '@disorder/personal-data-models';

export const ValidationIcon: React.FC<ValidationIconProps> = ({ validationStatus }) => {
  switch (validationStatus) {
    case ValidationStatus.VALID:
      return <CheckIcon style={{ fill: '#009284' }} />;
    case ValidationStatus.INVALID:
      return <CloseBorderIcon style={{ fill: '#e80c26' }} />;
    default:
      return <RefreshIcon style={{ fill: '#f6cb00' }} />;
  }
};
