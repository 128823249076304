import { ActionsUnion, createAction } from '../../services';

export enum UpdateBirthdateErrorModalActionsTypes {
  OPEN = '[ui][update-birthdate-error-modal] OPEN',
  CLOSE = '[ui][update-birthdate-error-modal] CLOSE',
}

const open = () => createAction(UpdateBirthdateErrorModalActionsTypes.OPEN);
const close = () => createAction(UpdateBirthdateErrorModalActionsTypes.CLOSE);

/**
 * combining and exporting of sync & async actions
 */

const actions = {
  open,
  close,
};

const asyncActions = {};

export const updateBirthdateErrorModalActions = {
  ...actions,
  ...asyncActions,
};

export type UpdateBirthdateErrorModalActions = ActionsUnion<typeof actions, typeof asyncActions>;
