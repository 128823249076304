import React from 'react';
import Button from '@eg/elements/Button';
import Modal from '@eg/elements/Modal';
import { UnauthorizedModalHelper } from './UnauthorizedModal.helper';
import { UnauthorizedModalProps } from './UnauthorizedModal.types';
import styles from './UnauthorizedModal.module.scss';

export const UnauthorizedModalComponent: React.FC<UnauthorizedModalProps> = ({ isOpen }) => {
  const onClickHanlder = () => UnauthorizedModalHelper.redirectToLoginPage();

  return (
    <Modal className={styles.root} open={isOpen} viewport='fixed' dismissible={false}>
      <div className={styles.wrapper}>
        <h1 data-testid='unauthenticated-modal-title'>Nicht berechtigt</h1>
        <p>
          Ihre Sitzung ist abgelaufen oder Sie sind nicht authentifiziert. Drücken Sie den
          Login-Button, um zur Login-Seite weitergeleitet zu werden.
        </p>
        <Button className={styles.button} variant='primary' onClick={onClickHanlder}>
          Einloggen
        </Button>
      </div>
    </Modal>
  );
};
