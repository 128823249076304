import React, { useEffect, useState } from 'react';
import Radio from '@eg/elements/Radio';
import { DeductibleProps } from './Deductible.types';
import { DeductibleTooltip } from './DeductibleTooltip';
import { Field, RadioGroup } from '../../../../components';
import { OfferTable } from '../../../../components/OfferTable';
import styles from './Deductible.module.scss';

export const Deductible: React.FC<DeductibleProps> = ({
  hasUpdatePackagesRequestFailed,
  isAnyOfferRequestInProgress,
  options,
  update,
  value,
}) => {
  const hasOptions = !!options.length;
  const disabledField = isAnyOfferRequestInProgress || !hasOptions;
  const [selectedValue, setSelectedValue] = useState<string>(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [hasUpdatePackagesRequestFailed, value]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSelectedValue(value);
    update(value);
  };

  return (
    <OfferTable.Row className={styles.root} label='Selbstbeteiligung' tooltip={DeductibleTooltip}>
      <Field className={styles.field} disabled={disabledField}>
        <RadioGroup className={styles.radioGroup} value={selectedValue} onChange={onChangeHandler}>
          {!hasOptions && <Radio className={styles.radio} label='-' value='EMPTY' />}
          {options.map((option) => (
            <Radio
              className={styles.radio}
              value={option.value}
              label={option.label}
              key={`deductible.option.${option.value}`}
            />
          ))}
        </RadioGroup>
      </Field>
    </OfferTable.Row>
  );
};
