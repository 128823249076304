import React, { useEffect, useState } from 'react';
import Select from '@eg/elements/Select';
import { Field, OfferTable } from '../../../../components';
import { OfferFieldValue } from '../../../../store/entities';
import { PaymentMethodProps } from './PaymentMethod.types';
import { PaymentMethodTooltip } from './PaymentMethodTooltip';
import styles from './PaymentMethod.module.scss';

export const PaymentMethod: React.FC<PaymentMethodProps> = ({
  hasUpdateFieldsRequestFailed,
  isAnyOfferRequestInProgress,
  options,
  update,
  value,
}) => {
  const hasOptions = !!options.length;
  const disabledField = isAnyOfferRequestInProgress || !hasOptions;
  const [selectedValue, setSelectedValue] = useState<OfferFieldValue>(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [hasUpdateFieldsRequestFailed, value]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;

    setSelectedValue(value);
    update(value);
  };

  return (
    <OfferTable.Row className={styles.root} label='Zahlweise' tooltip={PaymentMethodTooltip}>
      <Field className={styles.field} disabled={disabledField}>
        <Select value={selectedValue} onChange={onChangeHandler}>
          {!selectedValue && <option disabled value='' />}
          {options.map((option) => (
            <option value={option.value} key={`payment.method.option.${option.value}`}>
              {option.label}
            </option>
          ))}
        </Select>
      </Field>
    </OfferTable.Row>
  );
};
